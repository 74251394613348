import { ClerkProvider } from '@clerk/clerk-react'
import { CssBaseline, GlobalStyles, ThemeProvider, Typography } from '@mui/material'
import { useEffect } from 'react'
import { useHotkeys } from 'react-hotkeys-hook'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { DashboardView } from './features/dashboard/components/DashboardView'
import ContentBoardPage from './features/gameOfLife/contentBoard/ContentBoardPage'
import InteractiveScenePage from './features/gameOfLife/interactiveScenePage/InteractiveScenePage'
import MapOfLife from './features/gameOfLife/mapOfLife/MapOfLife'
import { CurrentHabitsListView } from './features/habitTracking/views/currentHabitsListView/currentHabitsListView'
import { DetailsView } from './features/habitTracking/views/detailsView/DetailsView'
import { DojoView } from './features/habitTracking/views/dojoView/DojoView'
import { EditHabitView } from './features/habitTracking/views/edithabitView/EditHabitView'
import { HabitsOverview } from './features/habitTracking/views/habitsOverview/HabitsOverview'
import { TrackingView } from './features/habitTracking/views/trackingView/TrackingView'
import MapOverlay from './features/overlayRework/components/MapOverlay'
import SmartphoneOverlay from './features/overlayRework/components/SmartphoneOverlay'
import TabletOverlay from './features/overlayRework/components/TabletOverlay'
import { useCurrentScene } from './functions/useCurrentScene'
import { DevToolsPage } from './pages/DevToolsPage'
import ErrorPage from './pages/ErrorPage'
import { RoutineConfig } from './pages/RoutineConfig'
import StartPage from './pages/StartPage'
import { useStoreActions } from './store/hooks'
import './theme/global.css'
import { globalStyles } from './theme/globalStyles'
import theme from './theme/theme'
import PrivateRoutes from './util/PrivateRoutes'
import NavigationRoutes from './util/routes'

if (!process.env.REACT_APP_CLERK_PUBLISHABLE_KEY) {
    throw new Error('Missing Publishable Key')
}

const clerkPubKey = process.env.REACT_APP_CLERK_PUBLISHABLE_KEY

function App(): JSX.Element {
    const toggleDebugMode = useStoreActions((state) => state.gameModel.toggleDebugMode)
    const loadTrackingData = useStoreActions((state) => state.trackingModel.loadData)
    const loadDojoData = useStoreActions((state) => state.dojoLogModel.loadData)
    const loadSceneData = useStoreActions((state) => state.gameModel.loadData)

    // if first mount, load the data
    useEffect(() => {
        loadTrackingData()
    }, [loadTrackingData])
    useEffect(() => {
        loadDojoData()
    }, [loadDojoData])
    useEffect(() => {
        loadSceneData()
    }, [loadSceneData])

    useHotkeys('f9', () => {
        toggleDebugMode()
    })

    function ClerkProviderRoutes() {
        useCurrentScene()

        return (
            <ClerkProvider publishableKey={clerkPubKey}>
                <Routes>
                    <Route path="*" element={<ErrorPage />} />
                    <Route path={NavigationRoutes.game.STARTSCREEN} element={<StartPage />} />
                    <Route path={NavigationRoutes.dev.DEV} element={<DevToolsPage />} />
                    <Route
                        path={NavigationRoutes.dev.TEST}
                        element={<Typography>psshh, you shouldnt be here!</Typography>}
                    />

                    <Route element={<PrivateRoutes />}>
                        {/* Tracking */}

                        {/* Show inside tablet "emulator" on big screen */}
                        <Route element={<TabletOverlay />}>
                            <Route path={NavigationRoutes.tracking.DOJOLOG} element={<DojoView />} />
                        </Route>

                        {/* Show inside smartphone "emulator" on big screen */}
                        <Route element={<SmartphoneOverlay />}>
                            <Route path={NavigationRoutes.tracking.DASHBOARD} element={<DashboardView />} />
                            <Route path={NavigationRoutes.tracking.TRACKING} element={<TrackingView />} />
                            <Route path={NavigationRoutes.tracking.DETAILS} element={<DetailsView />} />
                            <Route path={NavigationRoutes.tracking.HABITS_OVERVIEW} element={<HabitsOverview />} />
                            <Route
                                path={NavigationRoutes.tracking.CURRENT_HABITS_LIST}
                                element={<CurrentHabitsListView />}
                            />
                            <Route path={NavigationRoutes.tracking.EDIT_HABIT} element={<EditHabitView />} />
                            <Route path={NavigationRoutes.tracking.ADD_HABIT} element={<EditHabitView />} />
                        </Route>

                        {/* Game */}
                        <Route path={NavigationRoutes.game.SCENE} element={<InteractiveScenePage />} />
                        <Route path={NavigationRoutes.game.SCENE_WITH_VP} element={<InteractiveScenePage />} />
                        <Route path={NavigationRoutes.game.CONTENT_BOARD} element={<ContentBoardPage />} />
                        <Route path={NavigationRoutes.game.CONFIGURE_ROUTINES} element={<RoutineConfig />} />

                        {/* Show inside map "emulator" on big screen */}
                        <Route element={<MapOverlay />}>
                            <Route path={NavigationRoutes.game.MAP} element={<MapOfLife />} />
                        </Route>
                    </Route>
                </Routes>
            </ClerkProvider>
        )
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />
            <BrowserRouter>
                <ClerkProviderRoutes />
            </BrowserRouter>
        </ThemeProvider>
    )
}

export default App
