import { v4 } from 'uuid'
import { AudioPlayerItemStatus } from '../components/dojo/dojoitems/AudioPlayerItem/AudioPlayerItem'
import { JournalItemStatus } from '../components/dojo/dojoitems/JournalItem/JournalItem'
import { QuoteItemStatus } from '../components/dojo/dojoitems/QuoteCardItem/QuoteCardItem'
import { RateDayItemStatus } from '../components/dojo/dojoitems/RateDayItem/RateDayItem'
import { YoutubeVideoItemStatus } from '../components/dojo/dojoitems/YoutubeVideoItem/YoutubeVideoItem'
import { DojoItemRecord, RateDayEmoji } from '../graphql/types'
import { getOffsetDay } from '../util/functions/testDate'
import { testAudio, testQuote, testRating, testTextInput, testVideo } from './dojoItems'

export const testRecordQuote: DojoItemRecord = {
    id: v4(),
    item: testQuote.id,
    date: getOffsetDay(2),
    viewed: false,
    status: JSON.stringify({} as QuoteItemStatus),
}

export const testRecordVideo: DojoItemRecord = {
    id: v4(),
    item: testVideo.id,
    date: getOffsetDay(1),
    viewed: false,
    status: JSON.stringify({} as YoutubeVideoItemStatus),
}

export const testRecordAudio: DojoItemRecord = {
    id: v4(),
    item: testAudio.id,
    date: getOffsetDay(3),
    viewed: false,
    status: JSON.stringify({
        currentTime: 0,
    } as AudioPlayerItemStatus),
}
export const testRecordTextInput: DojoItemRecord = {
    id: v4(),
    item: testTextInput.id,
    date: getOffsetDay(3),
    viewed: false,
    status: JSON.stringify({
        text: 'prefilled journal',
    } as JournalItemStatus),
}
export const testRecordRating: DojoItemRecord = {
    id: v4(),
    item: testRating.id,
    date: getOffsetDay(3),
    viewed: false,
    status: JSON.stringify({
        rating: RateDayEmoji.HAPPY,
    } as RateDayItemStatus),
}

export const testDojoRecords: DojoItemRecord[] = [
    testRecordQuote,
    testRecordVideo,
    testRecordAudio,
    testRecordTextInput,
    testRecordRating,
]
