import { Button, Card, Grid, Stack, Typography } from '@mui/material'
import { DojoItemComponentProps, DojoItemState } from '../../../../features/habitTracking/views/dojoView/DojoView'
import { palette } from '../../../../theme/palette'

export type ChoiceItemProps = DojoItemComponentProps

export interface Choice {
    text: string
    result: string
}
export interface ChoiceItemConfig {
    choices: Choice[]
}

export const defaultChoiceItemConfig: ChoiceItemConfig = {
    choices: [],
}

export interface ChoiceItemStatus {
    result: number // index of the clicked text
}

export const defaultChoiceItemStatus: ChoiceItemStatus = {
    result: -1,
}

const ChoiceItem = (props: ChoiceItemProps): JSX.Element => {
    const { item, record, activeItemID, onTrackDojoItem, onNext } = props
    const config: ChoiceItemConfig = item.config ? JSON.parse(item.config) : defaultChoiceItemConfig
    const status: ChoiceItemStatus = record.status ? JSON.parse(record.status) : defaultChoiceItemStatus
    const { choices } = config
    const state: DojoItemState =
        activeItemID === item.id ? DojoItemState.ACTIVE : record.viewed ? DojoItemState.DONE : DojoItemState.NEW
    return (
        <Card
            sx={{
                margin: '20px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
                padding: '16px',
            }}
        >
            {state !== DojoItemState.ACTIVE && (
                <>
                    <Typography
                        variant="h1"
                        color={state === DojoItemState.DONE ? palette.text?.disabled : palette.text?.primary}
                    >
                        {item.title}
                    </Typography>
                </>
            )}

            {state === DojoItemState.ACTIVE && status.result >= 0 && (
                <Typography variant="h5">{choices[status.result].result}</Typography>
            )}

            {state === DojoItemState.ACTIVE && status.result < 0 && (
                <Grid container spacing={2} padding={2}>
                    {choices.map((choice, index) => (
                        <Grid item xs={6} key={choice.text}>
                            <Typography
                                color={palette.text?.primary}
                                flex={3}
                                variant="h4"
                                sx={{
                                    width: '100%',
                                    height: '100%',
                                    textAlign: 'left',
                                    wordBreak: 'break-word',
                                    paddingY: '40px',
                                    paddingX: '24px',
                                    '&:hover': {
                                        backgroundColor: 'darkseagreen',
                                        color: '#fff',
                                        cursor: 'pointer',
                                    },
                                }}
                                onClick={() => {
                                    onTrackDojoItem(
                                        item,
                                        false,
                                        JSON.stringify({
                                            result: index,
                                        } as ChoiceItemStatus),
                                    )
                                }}
                            >
                                {choice.text}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
            {item.id === activeItemID && (
                <Stack
                    display={'flex'}
                    direction={'row'}
                    justifyContent={'flex-end'}
                    spacing={2}
                    alignItems={'center'}
                    marginBottom={2}
                    marginRight={2}
                >
                    <Button
                        variant={'contained'}
                        onClick={(e) => {
                            e.stopPropagation()
                            onNext()
                        }}
                    >
                        Next
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={(e) => {
                            e.stopPropagation()
                            onTrackDojoItem(
                                item,
                                true,
                                JSON.stringify({
                                    result: status.result,
                                } as ChoiceItemStatus),
                            )
                            onNext()
                        }}
                    >
                        Done
                    </Button>
                </Stack>
            )}
        </Card>
    )
}

export default ChoiceItem
