import { User } from '../graphql/types'

export const testUsers: User[] = [
    {
        id: '42',
        email: 'martin.friedel@qubidu.com',
        name: 'Fried',
        avatar: 'https://toppng.com/public/uploads/preview/donna-picarro-dummy-avatar-115633298255iautrofxa.png',
    },
]
