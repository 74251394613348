import { SignedIn, SignedOut } from '@clerk/clerk-react'
import { Navigate, Outlet } from 'react-router-dom'
import Routes from './routes'

const PrivateRoutes = () => (
    <>
        <SignedIn>
            <Outlet />
        </SignedIn>
        <SignedOut>
            <Navigate to={Routes.game.STARTSCREEN} replace={true} />
        </SignedOut>
    </>
)

export default PrivateRoutes
