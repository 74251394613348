import { IconButton, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'

// ICONS //
import InfoIcon from '@mui/icons-material/Info'
import ReadMoreIcon from '@mui/icons-material/ReadMore'
import { Habit, HabitType, Record } from '../../../../graphql/types'
import { HabitComponentProps } from '../../views/trackingView/TrackingView'
import { BooleanItemStatus } from '../booleanItem/BooleanItem'
import { CounterItemConfig, CounterItemStatus, defaultCounterItemConfig } from '../counterItem/CounterItem'
import StatusProgress from '../statusProgress/StatusProgress'
import { TimerItemConfig, TimerItemStatus, defaultTimerItemConfig } from '../timerItem/TimerItem'
import StackStepper from './components/StackStepper'
export interface StackItemProps extends Omit<HabitComponentProps, 'record'> {
    subHabitRecords: Map<string, Record> // Map of habitID to its respective record
}

export interface StackItemConfig {
    subHabits: Habit[]
}

export const defaultStackConfig: StackItemConfig = {
    subHabits: [],
}

export const isHabitDone = (habit: Habit, subHabitRecords: Map<string, Record>): boolean => {
    const record = subHabitRecords.get(habit.id)

    if (record === undefined) return false

    const status = JSON.parse(record.status)

    switch (habit.type) {
        case HabitType.BOOLEAN:
            return (status as BooleanItemStatus).checked
        case HabitType.COUNTER: {
            const config: CounterItemConfig = habit.config ? JSON.parse(habit.config) : defaultCounterItemConfig
            return (status as CounterItemStatus).value >= config.target
        }
        case HabitType.TIMER: {
            const config: TimerItemConfig = habit.config ? JSON.parse(habit.config) : defaultTimerItemConfig
            return (status as TimerItemStatus).value >= config.target
        }
        default: // Do not allow nested habits
            return false
    }
}

export function StackItem(props: StackItemProps) {
    const { habit, active, onTrackHabit, onInfoClick, subHabitRecords } = props
    const config: StackItemConfig = habit.config ? JSON.parse(habit.config) : defaultStackConfig

    const howManyDone = config.subHabits.filter((current) => isHabitDone(current, subHabitRecords)).length
    const howManyTodo = config.subHabits.length

    if (!props.detailsView) {
        return (
            <Stack direction="row" alignItems={'center'}>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        if (onInfoClick) onInfoClick(habit)
                    }}
                >
                    <InfoIcon htmlColor="white" />
                </IconButton>
                <Paper
                    className={`habitEntry${active ? ' active' : ''}${howManyDone === howManyTodo ? ' done' : ''}`}
                    elevation={1}
                    onClick={(e) => {
                        e.stopPropagation()
                        if (onInfoClick) onInfoClick(habit)
                    }}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: 1,
                        background: active ? '#f8f8f8' : '#eee',
                        backgroundColor: howManyDone === howManyTodo ? '#ded' : '#eee',
                        padding: '4px 4px 4px 16px',
                        userSelect: 'none',

                        '&:hover': {
                            backgroundColor: active ? 'lightgray' : '',
                            userSelect: 'none',
                            transition: 'background 250ms',
                            cursor: active ? 'pointer' : '',
                        },
                    }}
                >
                    <IconButton disabled={true}>
                        <StatusProgress target={howManyTodo} value={howManyDone} size={24} />
                    </IconButton>

                    <Typography flex={1} color={active ? 'black' : 'gray'}>
                        {habit.name}
                    </Typography>
                    <Typography
                        flex={1}
                        textAlign={'center'}
                        color={
                            active && howManyDone != howManyTodo
                                ? 'black'
                                : howManyDone != howManyTodo
                                ? 'gray'
                                : 'green'
                        }
                    >
                        {howManyDone}/{howManyTodo}
                    </Typography>
                    <Stack flex={1} direction="row" justifyContent={'flex-end'} alignItems={'center'}>
                        <IconButton disabled>
                            <ReadMoreIcon htmlColor={active ? 'black' : 'gray'} />
                        </IconButton>
                    </Stack>
                </Paper>
            </Stack>
        )
    } else {
        return (
            <StackStepper
                active={active}
                subHabits={config.subHabits}
                subHabitRecords={subHabitRecords}
                onTrackHabit={onTrackHabit}
            />
        )
    }
}
