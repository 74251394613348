import { v4 } from 'uuid'

import { AudioPlayerItemConfig } from '../../components/dojo/dojoitems/AudioPlayerItem/AudioPlayerItem'
import { JournalItemConfig } from '../../components/dojo/dojoitems/JournalItem/JournalItem'
import { TextCardItemConfig } from '../../components/dojo/dojoitems/TextItem/TextCardItem'
import { DojoDisplayType, DojoItem, DojoPurposeType } from '../../graphql/types'

//TODO: add more items

export const Silence_Audio: DojoItem = {
    id: v4(),
    title: 'Morning Routine: Silence',
    displayType: DojoDisplayType.AUDIO,
    purposeType: DojoPurposeType.ROUTINE_MEDITATION,
    continuous: true,
    config: JSON.stringify({
        mp3URL: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
    } as AudioPlayerItemConfig),
}

export const Affirmations_Audio: DojoItem = {
    id: v4(),
    title: 'Morning Routine: Affirmations',
    displayType: DojoDisplayType.AUDIO,
    purposeType: DojoPurposeType.ROUTINE_AFFIRMATION,
    continuous: true,
    config: JSON.stringify({
        mp3URL: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
    } as AudioPlayerItemConfig),
}

export const Visualization_Audio: DojoItem = {
    id: v4(),
    title: 'Morning Routine: Visualization',
    displayType: DojoDisplayType.AUDIO,
    purposeType: DojoPurposeType.ROUTINE_VISUALISATION,
    continuous: true,
    config: JSON.stringify({
        mp3URL: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
    } as AudioPlayerItemConfig),
}

export const Reading_Text: DojoItem = {
    id: v4(),
    title: 'Morning Routine: Reading',
    displayType: DojoDisplayType.TEXT,
    purposeType: DojoPurposeType.ROUTINE_READING,
    continuous: true,
    config: JSON.stringify({
        title: 'This is a header title',
        body: 'This is the body of the text This is the body of the text This is the body of the text  This is the body of the text  This is the body of the text ',
    } as TextCardItemConfig),
}

export const Writting_Text: DojoItem = {
    id: v4(),
    title: 'Morning Routine: Writing',
    displayType: DojoDisplayType.TEXT_INPUT,
    purposeType: DojoPurposeType.ROUTINE_WRITING,
    continuous: true,
    config: JSON.stringify({
        prompt: 'Write 5 sentences',
    } as JournalItemConfig),
}

export const testDojoRecord = (dojoItem: DojoItem) => ({
    id: v4(),
    item: dojoItem.id,
    date: new Date(),
    viewed: false,
    status: undefined,
})

export const morningRoutine: DojoItem[] = [
    Affirmations_Audio,
    Silence_Audio,
    Visualization_Audio,
    Reading_Text,
    Writting_Text,
]
