import { Box, Button, IconButton, Paper, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { useEffect, useState } from 'react'
import { HabitComponentProps } from '../../views/trackingView/TrackingView'
// ICONS //
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import PauseIcon from '@mui/icons-material/Pause'
import PauseCircleIcon from '@mui/icons-material/PauseCircle'
import PlayArrowIcon from '@mui/icons-material/PlayArrow'
import PlayCircleFilledWhiteIcon from '@mui/icons-material/PlayCircleFilledWhite'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import StatusProgress from '../statusProgress/StatusProgress'

export type TimerItemProps = HabitComponentProps

export interface TimerItemConfig {
    target: number // in seconds
}

export const defaultTimerItemConfig: TimerItemConfig = {
    target: 0,
}

export interface TimerItemStatus {
    value: number // in seconds
}

export const defaultTimerItemStatus: TimerItemStatus = {
    value: 0,
}

export enum TimerItemRunning {
    RUNNING,
    PAUSED,
    DONE,
}

export function TimerItem(props: TimerItemProps) {
    const { habit, record, onTrackHabit, onInfoClick, active } = props

    const config: TimerItemConfig = habit.config ? JSON.parse(habit.config) : defaultTimerItemConfig
    const status: TimerItemStatus = record ? JSON.parse(record.status) : defaultTimerItemStatus

    const [running, setRunning] = useState(false)
    const done = status.value >= config.target

    useEffect(() => {
        if (running) {
            const interval = setInterval(() => {
                onTrackHabit(habit, JSON.stringify({ value: status.value + 1 } as TimerItemStatus))
            }, 1000)
            return () => clearInterval(interval)
        }
    }, [config.target, status, running, habit, onTrackHabit])

    if (!props.detailsView) {
        return (
            <Stack direction="row" alignItems={'center'}>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        if (onInfoClick) onInfoClick(habit)
                    }}
                >
                    <InfoIcon htmlColor="white" />
                </IconButton>
                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: 1,
                        background: active ? '#f8f8f8' : '#eee',
                        backgroundColor: done ? '#ded' : '#eee',
                        padding: '4px 4px 4px 16px',
                        userSelect: 'none',

                        '&:hover': {
                            backgroundColor: active ? 'lightgray' : '',
                            userSelect: 'none',
                            transition: 'background 250ms',
                            cursor: active ? 'pointer' : '',
                        },
                    }}
                    className={`habitEntry${active ? ' active' : ''}${done ? ' done' : ''}`}
                    elevation={1}
                    onClick={() => {
                        if (active) setRunning(!running)
                    }}
                >
                    <IconButton disabled={true}>
                        <StatusProgress target={config.target} value={status.value} size={24} />
                    </IconButton>
                    <Stack direction="row" spacing={2} alignItems={'center'} flex={2}>
                        <Typography color={active ? 'black' : 'gray'}>{habit.name}</Typography>
                    </Stack>

                    <Typography flex={1} color={active && !done ? 'black' : done ? 'green' : 'gray'}>
                        {format(new Date((status.value - 3600) * 1000), 'm:ss')} /{' '}
                        {format(new Date((config.target - 3600) * 1000), 'm:ss')}
                    </Typography>
                    <Stack direction="row" alignItems={'center'} justifyContent={'flex-end'} flex={2}>
                        {active && (
                            <IconButton>
                                {!running && <PlayCircleFilledWhiteIcon htmlColor="black" />}
                                {running && <PauseCircleIcon htmlColor="black" />}
                            </IconButton>
                        )}
                        {!active && (
                            <IconButton disabled={true}>
                                {!running && <PlayCircleFilledWhiteIcon htmlColor="gray" />}
                                {running && <PauseCircleIcon htmlColor="gray" />}
                            </IconButton>
                        )}
                    </Stack>
                </Paper>
            </Stack>
        )
    } else {
        return (
            <Box sx={{ padding: '16px', borderRadius: '16px' }}>
                <Typography fontSize={24} marginBottom={1}>
                    {habit.name}
                </Typography>
                <Paper elevation={1}>
                    <Stack direction="row" justifyContent={'space-between'} alignItems={'center'}>
                        <Box flex={4}>
                            {done && (
                                <IconButton disabled={true}>
                                    <CheckCircleIcon htmlColor="#090" sx={{ fontSize: 40 }} />
                                </IconButton>
                            )}
                            {!done && (
                                <IconButton disabled={true}>
                                    <RadioButtonUncheckedIcon sx={{ fontSize: 40 }} />
                                </IconButton>
                            )}
                        </Box>
                        <Typography flex={5} sx={{ fontSize: 25 }}>
                            {format(new Date((status.value - 3600) * 1000), 'm:ss')} /{' '}
                            {format(new Date((config.target - 3600) * 1000), 'm:ss')}
                        </Typography>
                    </Stack>
                </Paper>
                <Stack
                    direction="column"
                    alignItems={'center'}
                    justifyContent={'center'}
                    flex={2}
                    onClick={() => {
                        if (active) setRunning(!running)
                    }}
                >
                    {active && (
                        <Button variant="outlined" sx={{ marginTop: '16px', height: 40 }}>
                            {!running && <PlayArrowIcon sx={{ fontSize: 35 }} />}
                            {running && <PauseIcon sx={{ fontSize: 35 }} />}
                        </Button>
                    )}

                    {!active && (
                        <Button disabled={true} variant="outlined" sx={{ marginTop: '16px', height: 40 }}>
                            <PlayArrowIcon sx={{ fontSize: 35 }} />
                        </Button>
                    )}
                </Stack>
            </Box>
        )
    }
}
