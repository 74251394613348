import { v4 } from 'uuid'
import { IslandData } from '../../../graphql/types'

export const mapData: IslandData[] = [
    {
        id: v4(),
        x: -750,
        y: -100,
        width: 2000,
        height: 1440,
        name: 'Körperlichen Gesundheit',
        img: '/img/mapoflife/physicalhealth.webp',
        locations: [
            { x: 325, y: 70, id: '293847', name: 'Schlaf' },
            { x: 320, y: 480, id: '191931', name: 'Ernährung' },
        ],
    },
    {
        id: v4(),
        x: 250,
        y: 700,
        width: 2000,
        height: 1440,
        name: 'Geistige Gesundheit',
        img: '/img/mapoflife/mentalhealth.webp',
        locations: [],
    },
    {
        id: v4(),
        x: 4000,
        y: 700,
        width: 2000,
        height: 1440,
        name: 'Finanzen',
        img: '/img/mapoflife/finances.webp',
        locations: [],
    },
    {
        id: v4(),
        x: -150,
        y: -1200,
        width: 2000,
        height: 1440,
        name: 'Insel des Menschseins',
        img: '/img/mapoflife/beinghuman.webp',
        locations: [
            {
                x: 255,
                y: 300,
                id: v4(),
                name: 'Wahrnehmung',
                config: 'Im Dorf der Wahrnehmung geht es darum, dass es zwar eine eindeutige neutrale Wirklichkeit gibt, aber jeder von uns diese anders sieht und wahrnimmt, weil wir ja alle unterschiedliche Menschen sind. Darin liegt eine unglaubliche Fähigkeit des Menschen, eine Situation aus vielen Blickwinkeln zu sehen. Manchmal fehlt einem einfach eine Hilfreiche Ansicht auf ein Thema um damit besser klar zu kommen. Das schauen wir uns genauer an, denn das kommt in allen späteren Bereichen vor - Vorschläge für bessere Ansichten.',
            },
            {
                x: 70,
                y: 260,
                id: v4(),
                name: 'Die innere Vielfalt',
                config: 'In dem Dorf der inneren Vielfalt geht es um etwas ungeheuerliches. Sollen wir es jetzt schon verraten? Wir haben zwar genau einen Körper, aber wir haben verschiedene Facetten unserer Persönlichkeit. Wir haben alleine zwei Hirnhälften, von denen eine Sprache versteht, die andere eher Bilder. Wir haben einen rationalen Verstand, der Argumentieren kann und eine Intuition die lernen kann extrem gut Muster zu erkenne, auch wenn wir diese gar nicht verstehen. Viele Lebensbereiche, in denen wir etwas verbessern wollen, werden aber von einem dieser Teile geblockt, wenn wir darauf keine Rücksicht nehmen. Wir stellen ein Paar Modelle vor, mit denen wir später endlich klar darüber sprechen können, welcher Teil von uns denn vielleicht etwas stärker motiviert werden muss eine Veränderung mitzumachen.',
            },
            {
                x: 80,
                y: 155,
                id: v4(),
                name: 'Gewohnheiten',
                config: 'Das dritte Dorf dreht sich darum, wie wir Menschen etwas gelerntes speichern und als wiederkehrende, automatische Handlungen abspielen können. Wieder eine echte Superkraft - allerdings nur wenn man sich von Anfang an nur die besten Angewohnheiten zulegt. Das macht aber leider niemand, also müssen wir lernen, wie man Angewohnheiten ändert. Das ist nicht leicht, aber man kann es lernen und darin besser werden - besonders wo wir jetzt ja wissen welche Inneren Verbündeten und Gegner es für Veränderungen gibt.',
            },
            {
                x: 390,
                y: 145,
                id: v4(),
                name: 'Systeme',
                config: ' Dieses Dorf dreht sich nicht um den Menschen selbst, sondern um alles andere, womit der Mensch sich konfrontiert sieht: alles was uns umgibt läuft nach Regeln ab und bildet einfache bis unbeschreiblich komplexe Systeme. Hier geht es noch nicht darum alle Systeme zu verstehen, sondern nur grundsätzlich was sie sind und auch wieder wichtige Begriffe und Grundlagen zu legen, damit wir in späteren Bereichen uns die Systeme und die Regeln zu den jeweiligen Themen ansehen können.',
            },
        ],
        config: 'Auf dieser Insel dreht sich alles um Grundlagen, was es heisst ein Mensch zu sein, die helfen alle weiteren Themen der Karte (und viele andere Bereiche im Leben) besser zu meistern. Im Alltag ignorieren wir einige bekannte Grundeigenschaften von uns Menschen, die aber vieles einfacher machen, wenn wir uns darüber klar sind.',
    },
    {
        id: v4(),
        x: 1900,
        y: -400,
        width: 2000,
        height: 1440,
        name: 'Kommunikation',
        img: '/img/mapoflife/communication.webp',
        locations: [],
    },
    {
        id: v4(),
        x: 400,
        y: -1400,
        width: 2000,
        height: 1440,
        name: 'Kultur',
        img: '/img/mapoflife/culture.webp',
        locations: [],
    },
    {
        id: v4(),
        x: 2400,
        y: -1400,
        width: 2000,
        height: 1440,
        name: 'Insel des Lebens',
        img: '/img/mapoflife/life.webp',
        config: 'Auf dieser Insel dreht es sich darum, dein Leben einzuordnen. Du hast ja nun mal eins, sonst würdes du das hier wohl nicht lesen. Du hast Lebzeit vor dir, die aber auch irgendwann vorbei ist. Viele von uns plagen sich damit, was denn nun das richtige ist, was man mit dieser Zeit anfangen soll. Auf dieser Insel werden verschiedene Aspekte davon beleuchtet.',
        locations: [
            {
                x: 230,
                y: 145,
                id: v4(),
                name: 'Tod und Trauer',
                config: '',
            },
            {
                x: 482,
                y: 115,
                id: v4(),
                name: 'Das große Bild',
                config: '',
            },
            {
                x: 680,
                y: 185,
                id: v4(),
                name: 'Der Turm des Sinns',
                config: 'Gibt es einen "Sinn des Lebens"? Vielleicht. Aber hier geht es eher darum ob du einen Sinn für DEIN Leben suchst. Es gibt Menschen, für die das gut ist, wenn sich mit jeder Faser von sich einem meist höheren Sinn verschreiben. Wenn du danach suchst, hier beschäftigen sich alle damit, wie man diese Suche beschreitet und wie man erkennt, wann man ihn gefunden hat.',
            },
            {
                x: 87,
                y: 285,
                id: v4(),
                name: 'Rundgang durchs Leben',
                config: '',
            },
            {
                x: 537,
                y: 285,
                id: v4(),
                name: 'Was im Leben zählt',
                config: 'Die Menschen in diesem Dorf beschäftigen sich mit allem, was es an Ansichten gibt dazu, was wirklich wichtig ist. Menschen auf dem Sterbebett haben erzählt was ihnen wichtig war oder was sie bereuen. Glücksforschung sagt was uns glücklich macht aber auch was nicht. Du findest hier viele Ansätze und Meinungen und kannst für dich mehr Klarheit gewinnen.',
            },
        ],
    },
    {
        id: v4(),
        x: 3800,
        y: -1400,
        width: 2000,
        height: 1440,
        name: 'Systeme',
        img: '/img/mapoflife/systems.webp',
        locations: [],
    },
    {
        id: v4(),
        x: 5000,
        y: 1600,
        width: 2000,
        height: 1440,
        name: 'Karriere',
        img: '/img/mapoflife/workcareer.webp',
        locations: [],
    },
    {
        id: v4(),
        x: 3200,
        y: 1500,
        width: 2000,
        height: 1440,
        name: 'Erziehung',
        img: '/img/mapoflife/parenting.webp',
        locations: [],
    },
    {
        id: v4(),
        x: 1400,
        y: 1200,
        width: 2000,
        height: 1440,
        name: 'Beziehungen',
        img: '/img/mapoflife/relationships.webp',
        locations: [],
    },
    {
        id: v4(),
        x: -500,
        y: -1400,
        width: 2000,
        height: 1440,
        name: 'Ellis Island',
        img: '/img/mapoflife/ellisisland.webp',
        locations: [],
        config: 'Ellis Island ist wo alle anfangen und die Grundlagen bekommen: Diese Karte, einen Zugang zum Dojo und ein Ticket um die erste Insel zu bereisen. Und man lernt natürlich Ellis kennen!',
    },
    {
        id: v4(),
        x: 5000,
        y: 1000,
        width: 2000,
        height: 1440,
        name: 'Wert',
        img: '/img/mapoflife/value.webp',
        locations: [],
    },
]
