const MeetingOverlay = (): JSX.Element => {
    return (
        <div style={{ transform: 'rotateX(6deg) translateY(-15px)' }}>
            <img
                src={'/img/general/tablet_hands.svg'}
                width="100%"
                style={{ transform: 'rotateX(14deg) translateY(10px)' }}
                alt={'map of life overview'}
            />
            <div
                style={{
                    position: 'absolute',
                    width: '1490px',
                    left: '210px',
                    height: '900px',
                    top: 125,
                    fontSize: '1.8rem',
                    backgroundColor: 'white',
                    padding: '32px 64px',
                }}
            >
                <h1>Gruppentreffen</h1>
                <p>Nächstes Gruppentreffen ist am xx.xx.2021</p>
                <h3>Agenda</h3>
                <ul>
                    <li>15 min - Einleitung</li>
                    <li>10 min - Erfolge</li>
                    <li>40 min - Gruppenarbeit oder Gespräch</li>
                    <li>10 min - Ausrutscher &amp; Pannen</li>
                    <li>10 min - Impulsvortrag</li>
                    <li>5 min - Abschluss</li>
                </ul>

                <h3>Link für Zoom</h3>
                <p>Hier kannst du direkt in den Zoom Call einsteigen, allerdings nur wenn dieser offen ist:</p>
                <a
                    target={'_blank'}
                    href={'https://uni-hamburg.zoom.us/j/65487664898?pwd=TEVDek80SGZML0t6cEpPa1Rvdk5qdz09'}
                    rel={'noreferrer'}
                    style={{
                        padding: '16px 48px',
                        backgroundColor: '#983e00',
                        borderRadius: 8,
                        color: 'white',
                        textDecoration: 'none',
                    }}
                >
                    In Zoom öffnen
                </a>
            </div>
        </div>
    )
}

export default MeetingOverlay
