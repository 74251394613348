//TODO: Rewrite this file to make it more generic and reusable for other components as well but for now it's fine

import { useMediaQuery } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { breakpoints } from '../../theme/breakpoints'
import theme from '../../theme/theme'

export const useRootContainer = (
    bottomBar = true,
    paddingX = '16px',
    paddingY = '24px',
    marginTop = '16px',
    heightOffset = 65,
) => {
    const small = useMediaQuery(theme.breakpoints.down('sm'))

    // Use state to keep track of the height.
    const [height, setHeight] = useState(window.innerHeight)

    // Update the height state whenever the window is resized.
    useEffect(() => {
        const handleResize = () => {
            setHeight(window.innerHeight)
        }

        window.addEventListener('resize', handleResize)

        // Cleanup function to remove the event listener.
        return () => {
            window.removeEventListener('resize', handleResize)
        }
    }, [])

    const remainingHeight = `calc(${height}px - ${bottomBar ? (small ? 56 : 64) : 0}px)`

    return {
        height: `calc(${remainingHeight} - ${heightOffset}px)`,
        maxWidth: breakpoints.values?.md,
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: marginTop,
        marginBottom: `${bottomBar ? (small ? 56 : 64) : 0}px`,
        paddingY: paddingY,
        paddingX: paddingX,
        overflowY: 'auto',
        overflowX: 'auto',
    }
}
