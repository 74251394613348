import { Button, Card, Stack, Typography } from '@mui/material'
import { DojoItemComponentProps, DojoItemState } from '../../../../features/habitTracking/views/dojoView/DojoView'
import { palette } from '../../../../theme/palette'

export type TextCardItemProps = DojoItemComponentProps

export interface TextCardItemConfig {
    title: string
    body: string
}

export const defaultTextCardItemConfig: TextCardItemConfig = {
    title: '',
    body: '',
}

export interface TextCardItemStatus {}

export const defaultTextCardItemStatus: TextCardItemStatus = {}

const TextCardItem = (props: TextCardItemProps): JSX.Element => {
    const { item, record, activeItemID, onTrackDojoItem, onNext } = props
    const config: TextCardItemConfig = JSON.parse(item.config)
    // const status: TextCardItemStatus = record.status ? JSON.parse(record.status) : defaultTextCardItemStatus
    const { title, body } = config
    const state: DojoItemState =
        activeItemID === item.id ? DojoItemState.ACTIVE : record.viewed ? DojoItemState.DONE : DojoItemState.NEW

    return (
        <Card
            sx={{
                position: 'relative',
                margin: '20px',
                width: '100%',
            }}
        >
            {state === DojoItemState.NEW && (
                <>
                    <Typography variant="h1" color={palette.text?.primary} paddingX={'24px'} paddingY={'16px'}>
                        {item.title}
                    </Typography>
                </>
            )}

            {state !== DojoItemState.NEW && (
                <>
                    <Stack
                        direction="column"
                        justifyContent="flex-start"
                        alignContent={'flex-start'}
                        alignItems={'flex-start'}
                        paddingY={'16px'}
                        paddingX={'24px'}
                    >
                        <Typography
                            variant="h2"
                            color={state === DojoItemState.DONE ? palette.text?.disabled : palette.text?.primary}
                        >
                            {title}
                        </Typography>

                        <Typography
                            color={state === DojoItemState.DONE ? palette.text?.disabled : palette.text?.primary}
                            flex={3}
                            variant="body1"
                            sx={{ textAlign: 'left', wordBreak: 'break-word' }}
                            paddingY={'20px'}
                        >
                            {body}
                        </Typography>
                    </Stack>

                    {item.id === activeItemID && (
                        <Stack
                            display={'flex'}
                            direction={'row'}
                            justifyContent={'flex-end'}
                            spacing={2}
                            alignItems={'center'}
                            marginBottom={2}
                            marginRight={2}
                        >
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onNext()
                                }}
                                variant={'contained'}
                            >
                                <Typography variant="h5" color={'white'}>
                                    Next
                                </Typography>
                            </Button>
                            <Button
                                variant={'contained'}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onTrackDojoItem(item, true, JSON.stringify({} as TextCardItemStatus))
                                    onNext()
                                }}
                            >
                                <Typography variant="h5" color={'white'}>
                                    Done
                                </Typography>
                            </Button>
                        </Stack>
                    )}
                </>
            )}
        </Card>
    )
}

export default TextCardItem
