import { Box, Button, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { Habit } from '../../../../graphql/types'
import { useStoreState } from '../../../../store/hooks'
import { useRootContainer } from '../../../../util/hooks/useRootStack'
import Routes from '../../../../util/routes'
import { BottomBar } from '../../../dashboard/components/BottomBar'
import { HabitListItem } from '../../components/habitListItem/HabitListItem'

export interface HabitComponentProps {
    habits: Habit[]
    active: boolean
}

export function CurrentHabitsListView() {
    const rootContainer = useRootContainer()
    const habits = useStoreState((state) => state.trackingModel.habits)
    const navigate = useNavigate()

    return (
        <Box className="bonsai">
            <BottomBar />

            <Stack sx={[...(Array.isArray(rootContainer) ? rootContainer : [rootContainer])]} gap={2}>
                {habits.map((habit: Habit) => {
                    return <HabitListItem key={habit.id} habit={habit} />
                })}
            </Stack>
            <Stack
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                marginBottom={2}
                paddingBottom={'56px'}
                paddingX={'24px'}
                width={'100%'}
                position={'absolute'}
                bottom={0}
            >
                <Typography variant="h5" color={'white'}>
                    Edit Current Habits
                </Typography>
                <Button
                    variant={'contained'}
                    onClick={() => {
                        navigate(Routes.tracking.ADD_HABIT)
                    }}
                >
                    New Habit
                </Button>
            </Stack>
        </Box>
    )
}
