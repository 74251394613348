import Box from '@mui/material/Box'
import { useTheme } from '@mui/material/styles'

import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { Card, Container, Grid, IconButton, Stack, useMediaQuery } from '@mui/material'
import Button from '@mui/material/Button'
import { useState } from 'react'
import { Habit, HabitType, Record } from '../../../../../graphql/types'
import { BooleanItem } from '../../booleanItem/BooleanItem'
import { CounterItem } from '../../counterItem/CounterItem'
import { isHabitDone } from '../StackItem'
import { TimerItem } from './../../timerItem/TimerItem'

interface StackStepperProps {
    active: boolean
    subHabits: Habit[]
    subHabitRecords: Map<string, Record>
    onTrackHabit: (habit: Habit, status: string) => void
}
export default function StackStepper(props: StackStepperProps) {
    const { active, subHabits, subHabitRecords, onTrackHabit } = props
    const theme = useTheme()
    const [activeStep, setActiveStep] = useState(0)
    const maxSteps = subHabits.length
    const activeSubHabit = subHabits[activeStep]
    const record = subHabitRecords.get(activeSubHabit.id)

    // Modulo ftw
    const handleNext = () => setActiveStep((previous) => (previous + 1) % maxSteps)
    const handleBack = () => setActiveStep((previous) => (previous + (maxSteps - 1)) % maxSteps)

    const status = new Map<string, boolean>()
    subHabits.forEach((subHabit) => {
        const done = isHabitDone(subHabit, subHabitRecords)
        status.set(subHabit.id, done)
    })

    const isMobile = useMediaQuery(theme.breakpoints.down('sm'))
    return (
        <Container maxWidth={'md'}>
            <Card
                square
                elevation={0}
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    bgcolor: 'background.default',
                    margin: 0,
                    padding: 0,
                }}
            >
                <Stack display={'flex'} flexDirection={'column'} flexGrow={1} height={'100%'}>
                    <Stack
                        p={2}
                        direction="row"
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        sx={{
                            display: isMobile ? 'none' : '',
                        }}
                    >
                        <Button onClick={handleBack} sx={{ height: 40, fontSize: 25 }} variant="contained">
                            <ArrowBackIosNewIcon />
                        </Button>

                        <Stack direction="row" justifyContent={'center'} alignItems={'center'} flex={1}>
                            {subHabits.map((subHabit, index) => {
                                const done = isHabitDone(subHabit, subHabitRecords)
                                return (
                                    <IconButton
                                        key={subHabit.id}
                                        onClick={() => {
                                            setActiveStep(index)
                                        }}
                                        sx={{
                                            boxShadow: activeStep === index ? theme.shadows[5] : 'none',
                                            fontSize: 10,
                                        }}
                                    >
                                        {done ? <CheckCircleIcon htmlColor="green" /> : <RadioButtonUncheckedIcon />}
                                    </IconButton>
                                )
                            })}
                        </Stack>
                        <Button onClick={handleNext} sx={{ height: 40, fontSize: 25 }} variant="contained">
                            <ArrowForwardIosIcon />
                        </Button>
                    </Stack>
                    <Grid
                        container
                        mt={1}
                        sx={{
                            direction: 'column',
                            alignItems: 'center',
                            justifyContent: 'flex-start',
                            spacing: 2,
                            display: !isMobile ? 'none' : '',
                        }}
                    >
                        <Grid item width={'100%'}>
                            <Stack direction="row" justifyContent={'center'} alignItems={'center'} flex={1} pb={2}>
                                {subHabits.map((subHabit, index) => {
                                    const done = isHabitDone(subHabit, subHabitRecords)
                                    return (
                                        <IconButton
                                            key={subHabit.id}
                                            onClick={() => {
                                                setActiveStep(index)
                                            }}
                                            sx={{
                                                boxShadow: activeStep === index ? theme.shadows[5] : 'none',
                                                fontSize: 10,
                                            }}
                                        >
                                            {done ? (
                                                <CheckCircleIcon htmlColor="green" />
                                            ) : (
                                                <RadioButtonUncheckedIcon />
                                            )}
                                        </IconButton>
                                    )
                                })}
                            </Stack>
                        </Grid>
                        <Grid
                            item
                            container
                            sx={{
                                direction: 'row',
                                alignItems: 'center',
                                justifyContent: 'center',
                            }}
                            spacing={1}
                        >
                            <Grid item>
                                <Button onClick={handleBack} sx={{ height: 40, fontSize: 25 }} variant="contained">
                                    <ArrowBackIosNewIcon />
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button onClick={handleNext} sx={{ height: 40, fontSize: 25 }} variant="contained">
                                    <ArrowForwardIosIcon />
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Box>
                        {subHabits[activeStep].type === HabitType.BOOLEAN && (
                            <BooleanItem
                                habit={subHabits[activeStep]}
                                onTrackHabit={onTrackHabit}
                                record={record}
                                onInfoClick={() => undefined}
                                active={active}
                                detailsView={true}
                            />
                        )}
                        {subHabits[activeStep].type === HabitType.COUNTER && (
                            <CounterItem
                                habit={subHabits[activeStep]}
                                onTrackHabit={onTrackHabit}
                                record={record}
                                onInfoClick={() => undefined}
                                active={active}
                                detailsView={true}
                            />
                        )}
                        {subHabits[activeStep].type === HabitType.TIMER && (
                            <TimerItem
                                habit={subHabits[activeStep]}
                                onTrackHabit={onTrackHabit}
                                record={record}
                                onInfoClick={() => undefined}
                                active={active}
                                detailsView={true}
                            />
                        )}
                    </Box>
                </Stack>
            </Card>
        </Container>
    )
}
