import { Habit, Record } from '../../graphql/types'
import { testHabits } from '../../testData/habits'
import { testRecords } from '../../testData/records'

const recordsKey = 'records'
const habitsKey = 'habits'

export const loadRecords = (): Record[] => {
    const records = localStorage.getItem(recordsKey)
    if (records === null) {
        localStorage.setItem(recordsKey, JSON.stringify(testRecords))
        return testRecords
    }
    return JSON.parse(records)
}

export const loadHabits = (): Habit[] => {
    const habits = localStorage.getItem(habitsKey)
    if (habits === null) {
        localStorage.setItem(habitsKey, JSON.stringify(testHabits))
        return testHabits
    }
    return JSON.parse(habits)
}

export const saveHabits = (habits: Habit[]): Habit[] => {
    localStorage.setItem(habitsKey, JSON.stringify(habits))
    return habits
}

export const saveRecords = (records: Record[]): Record[] => {
    localStorage.setItem(recordsKey, JSON.stringify(records))
    return records
}
