import { Button, Card, Stack, Typography } from '@mui/material'
import { useEffect, useRef, useState } from 'react'
import { DojoItemComponentProps, DojoItemState } from '../../../../features/habitTracking/views/dojoView/DojoView'
import { palette } from '../../../../theme/palette'
export type AudioPlayerItemProps = DojoItemComponentProps

export interface AudioPlayerItemConfig {
    mp3URL: string
}

export const defaultAudioPlayerItemConfig: AudioPlayerItemConfig = {
    mp3URL: '',
}

export interface AudioPlayerItemStatus {
    currentTime: number
}

export const defaultAudioPlayerItemStatus: AudioPlayerItemStatus = {
    currentTime: 0,
}
const AudioPlayerItem = (props: AudioPlayerItemProps): JSX.Element => {
    const { item, record, activeItemID, onTrackDojoItem, onNext } = props
    const config: AudioPlayerItemConfig = item.config ? JSON.parse(item.config) : defaultAudioPlayerItemConfig
    const status: AudioPlayerItemStatus = record.status ? JSON.parse(record.status) : defaultAudioPlayerItemStatus
    const { mp3URL } = config
    const state: DojoItemState =
        activeItemID === item.id ? DojoItemState.ACTIVE : record.viewed ? DojoItemState.DONE : DojoItemState.NEW

    const saveCurrentTime = (e: React.SyntheticEvent<HTMLAudioElement, Event>) => {
        if (Math.round(e.currentTarget.currentTime) !== status.currentTime) {
            onTrackDojoItem(
                item,
                false,
                JSON.stringify({
                    currentTime: Math.round(e.currentTarget.currentTime),
                } as AudioPlayerItemStatus),
            )
        }
    }
    const [isDone, setIsDone] = useState<boolean>(false)
    const audioRef = useRef<HTMLAudioElement>(null)
    useEffect(() => {
        if (audioRef.current) {
            if (!isNaN(audioRef.current.duration) && Math.round(audioRef.current.duration) === status.currentTime) {
                setIsDone(true)
            }
        }
    }, [audioRef.current, status.currentTime])
    return (
        <Card
            sx={{
                margin: '20px',
                width: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <Stack
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
                spacing={2}
                alignItems={'center'}
            >
                <Typography
                    variant="h1"
                    color={state === DojoItemState.DONE ? palette.text?.disabled : palette.text?.primary}
                    sx={{
                        paddingY: '16px',
                        paddingX: '24px',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {item.title}
                </Typography>
                {state === DojoItemState.ACTIVE && audioRef && (
                    <audio
                        ref={audioRef}
                        style={{
                            width: '100%',
                            marginBottom: 'auto',
                            marginTop: 'auto',
                            marginRight: '24px',
                        }}
                        src={`${mp3URL}`}
                        controls
                        onLoadedMetadata={() => {
                            if (audioRef.current) {
                                audioRef.current.currentTime = status.currentTime
                            }
                        }}
                        onTimeUpdate={(e) => {
                            saveCurrentTime(e)
                        }}
                        onEnded={(e) => {
                            e.currentTarget.autoplay = false
                        }}
                        onCanPlayThrough={() => {
                            if (audioRef.current) {
                                setIsDone(Math.round(audioRef.current.duration) === status.currentTime)
                            }
                        }}
                    />
                )}
            </Stack>
            {state === DojoItemState.ACTIVE && (
                <Stack
                    display={'flex'}
                    direction={'row'}
                    justifyContent={'flex-end'}
                    spacing={2}
                    alignItems={'center'}
                    marginTop={2}
                    marginBottom={2}
                    marginRight={2}
                >
                    <Button
                        onClick={(e) => {
                            e.stopPropagation()
                            onNext()
                        }}
                        variant={'contained'}
                    >
                        <Typography variant="h5" color={'white'}>
                            Next
                        </Typography>
                    </Button>
                    <Button
                        variant={'contained'}
                        disabled={!isDone}
                        onClick={(e) => {
                            e.stopPropagation()
                            onTrackDojoItem(
                                item,
                                true,
                                JSON.stringify({
                                    currentTime: status.currentTime,
                                } as AudioPlayerItemStatus),
                            )
                            onNext()
                        }}
                    >
                        <Typography variant="h5" color={'white'}>
                            Done
                        </Typography>
                    </Button>
                </Stack>
            )}
        </Card>
    )
}

export default AudioPlayerItem
