import { Button, Dialog, Paper, Stack, Typography } from '@mui/material'
import { useStoreActions } from '../../../store/hooks'
import { HabitListItemProps } from './habitListItem/HabitListItem'
export interface DeleteHabitPopupProps extends HabitListItemProps {
    open: boolean
    onClose?: () => void
}
export function DeleteHabitPopup(props: DeleteHabitPopupProps) {
    const { onClose, habit } = props
    const deleteHabit = useStoreActions((state) => state.trackingModel.deleteHabit)
    return (
        <Dialog {...props}>
            <Paper
                sx={{
                    padding: '16px',
                }}
            >
                <Typography
                    sx={{
                        justifyContent: 'center',
                        display: 'flex',
                        paddingBottom: '16px',
                    }}
                >
                    Are you sure you want to delete this habit?
                </Typography>
                <Typography
                    variant="h5"
                    sx={{
                        paddingBottom: '16px',
                        justifyContent: 'center',
                        display: 'flex',
                    }}
                >
                    {`''${habit.name}''`}
                </Typography>
                <Typography sx={{ paddingBottom: '16px', justifyContent: 'center', display: 'flex', color: 'red' }}>
                    Warning: This action cannot be undone. All data associated with this habit will be lost.
                </Typography>
                <Stack direction="row" justifyContent="space-between">
                    <Button
                        variant="contained"
                        color="error"
                        onClick={() => {
                            deleteHabit(habit)
                            onClose && onClose()
                        }}
                    >
                        Delete
                    </Button>
                    <Button variant="contained" onClick={onClose}>
                        Cancel
                    </Button>
                </Stack>
            </Paper>
        </Dialog>
    )
}
