import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import FormatListNumberedIcon from '@mui/icons-material/FormatListNumbered'
import HomeIcon from '@mui/icons-material/Home'
import SettingsIcon from '@mui/icons-material/Settings'
import { AppBar, IconButton, Toolbar } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useStoreState } from '../../../store/hooks'
import Routes from '../../../util/routes'

export const BottomBar = () => {
    const navigate = useNavigate()
    const currentDay = useStoreState((state) => state.timeModel.currentDay)

    return (
        <AppBar sx={{ position: 'absolute', bottom: 0, top: 'auto' }}>
            <Toolbar
                sx={{
                    justifyContent: 'space-between',
                }}
            >
                <IconButton
                    onClick={() => navigate(Routes.tracking.DASHBOARD)}
                    size="large"
                    color="inherit"
                    aria-label="open drawer"
                >
                    <HomeIcon fontSize="large" />
                </IconButton>

                <IconButton
                    onClick={() => navigate(Routes.tracking.TRACKING.replace(':currentDay', `${currentDay}`))}
                    size="large"
                    color="inherit"
                >
                    <FormatListNumberedIcon fontSize="large" />
                </IconButton>
                <IconButton size="large" color="inherit" onClick={() => navigate(Routes.tracking.HABITS_OVERVIEW)}>
                    <CalendarMonthIcon fontSize="large" />
                </IconButton>

                <IconButton size="large" color="inherit" onClick={() => navigate(Routes.tracking.CURRENT_HABITS_LIST)}>
                    <SettingsIcon fontSize="large" />
                </IconButton>
            </Toolbar>
        </AppBar>
    )
}
