import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton } from '@mui/material'
import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import { generateDojoItems, generateRoutineItems } from '../../../util/functions/dojoGeneration'
import useGameStateVarRepo from '../../../util/hooks/useVariableRepository'
import Routes from '../../../util/routes'
import ContentBoardPanel from '../contentBoard/ContentBoardPanel'
import { boards, contents } from '../data/contentData'
import {
    ContentBoard,
    Dialog,
    DialogScene,
    Link,
    LinkType,
    Modifier,
    OverlayType,
    Scene,
    SceneItem,
    SceneItemType,
    SceneViewpoint,
    VariableChange,
} from '../data/types'
import MeetingOverlay from '../overlays/MeetingOverlay'
import MessagingOverlay from '../overlays/messaging/MessagingOverlay'
import AnswerPanel from './AnswerPanel'
import DialogPanel from './DialogPanel'
import './InteractiveScene.css'

// import { useStoreActions } from "../../store/hooks";

type SceneProps = {
    scene: Scene
    getScene: (id: string) => Scene | undefined
}

interface ParamTypes {
    vpid: string
}

// ================================================================================================================== Render a graphic
const renderGraphic = (item: SceneItem, vp: SceneViewpoint) => {
    const ry = vp.ry !== undefined ? vp.ry : 0
    const styles = {
        left: item.placement.x,
        top: item.placement.y,
        transform: 'none',
        opacity: vp.hide && vp.hide.indexOf(item.id) !== -1 ? 0 : 1,
    }
    let transform = ''
    if (item.placement.z) transform += ` translateZ(${item.placement.z}px)`
    if (ry && item.flat) transform += ` rotateY(${0 - ry}deg)`
    if (transform) styles.transform = transform

    return (
        <Box className={`sceneItem ${item.className ? item.className : ''}`} style={styles} key={item.id}>
            {item.title}
            {item.imageURL && (
                <img
                    src={item.imageURL}
                    alt=""
                    style={{ left: item.placement.x, top: item.placement.y, width: item.placement.w }}
                />
            )}
        </Box>
    )
}

// ================================================================================================================== Render an area
const renderArea = (item: SceneItem, vp: SceneViewpoint, executeLink: (link: Link) => void, debugMode: boolean) => {
    if (vp.hide && vp.hide.indexOf(item.id) !== -1) return null

    // let areaModifier = Modifier.VISIBLE
    // if (area.link) {
    //   const linkedScene = getScene(area.link)
    //   if (linkedScene) {
    //     areaModifier = varRepo.getSceneModifier(linkedScene)
    //   }
    // }

    // if (areaModifier === Modifier.HIDDEN) {
    //   // hidden areas do not get rendered
    //   return null
    // }

    // const showInfo = area.info && area.info.title

    let transform = 'none'
    if (item.placement.z) transform = `translateZ(${item.placement.z}px)`

    return (
        <Box
            key={item.id}
            className={`sceneClickarea ${debugMode ? 'debug' : ''}`}
            onClick={() => {
                if (item.link) executeLink(item.link)
            }}
            style={{
                position: 'absolute',
                left: item.placement.x,
                top: item.placement.y,
                width: item.placement.w,
                height: item.placement.h,
                transform,
            }}
        ></Box>
    )
}

// ================================================================================================================== Render a label
const renderLabel = (item: SceneItem, vp: SceneViewpoint) => {
    if (vp.hide && vp.hide.indexOf(item.id) !== -1) return null
    let transform = 'none'
    if (item.placement.z) transform = `translateZ(${item.placement.z}px)`

    return (
        <Box
            key={item.id}
            className={`sceneItem label ${item.className ? item.className : ''}`}
            style={{
                position: 'absolute',
                left: item.placement.x,
                top: item.placement.y,
                width: item.placement.w,
                height: item.placement.h,
                transform,
            }}
        >
            {item.title}
        </Box>
    )
}

// ================================================================================================================== Render a content board
const renderBoard = (item: SceneItem, vp: SceneViewpoint, boardId: string) => {
    if (vp.hide && vp.hide.indexOf(item.id) !== -1) return null
    if (boardId === undefined) return null

    let transform = ''
    if (item.placement.z) transform = `translateZ(${item.placement.z}px)`
    if (item.placement.w) transform += ` scale(${item.placement.w / 1920})`
    if (item.placement.ry) transform += ` rotateY(${item.placement.ry}deg)`

    const currentBoard = boards.find((b: ContentBoard) => {
        return b.id === boardId
    })
    if (!currentBoard) return undefined

    return (
        <Box
            key={item.id}
            className="contentBoard"
            style={{
                left: item.placement.x,
                top: item.placement.y,
                transform,
            }}
        >
            <ContentBoardPanel board={currentBoard} contents={contents} displayMode={true} />
            <Box className="clickArea"></Box>
        </Box>
    )
}

// ================================================================================================================== The component
const InteractiveScenePanel = (props: SceneProps): JSX.Element => {
    const navigate = useNavigate()
    const { vpid } = useParams<keyof ParamTypes>()
    const varRepo = useGameStateVarRepo()
    const setActiveOverlay = useStoreActions((state) => state.gameModel.setActiveOverlay)
    const setActiveDialog = useStoreActions((state) => state.gameModel.setActiveDialog)
    const exitDialog = useStoreActions((state) => state.gameModel.exitDialog)
    const applyVariableChanges = useStoreActions((state) => state.gameModel.applyVariableChanges)
    const debugMode = useStoreState((state) => state.gameModel.debugMode)
    const activeDialog = useStoreState((state) => state.gameModel.activeDialog)
    const activeDialogSceneId = useStoreState((state) => state.gameModel.activeDialogSceneId)
    const activeOverlay = useStoreState((state) => state.gameModel.activeOverlay)
    const morningRoutine = useStoreState((state) => state.dojoLogModel.morningRoutine)
    const eveningRoutine = useStoreState((state) => state.dojoLogModel.eveningRoutine)

    // ----------------------------------------------------------------------------- Generate routine items

    const addGeneratedRecords = useStoreActions((state) => state.dojoLogModel.addGeneratedRecords)
    const items = useStoreState((state) => state.dojoLogModel.dojoItems)
    const records = useStoreState((state) => state.dojoLogModel.dojoItemRecords)

    // ----------------------------------------------------------------------------- Render the scene

    const { scene } = props
    const sceneModifier = varRepo.getSceneModifier(scene)

    if (sceneModifier !== Modifier.VISIBLE) return <>{'not accessible'}</>

    // find the active viewpoint
    let vp = scene.viewpoints[0]
    if (vpid !== undefined) {
        const newVp = scene.viewpoints.find((svp: SceneViewpoint) => svp.id === vpid)
        if (newVp) vp = newVp
    }

    // find if there is an active dialog and if so which dialog scene is active
    let activeDialogScene = undefined
    //Fixed part: should be properly tested on dialogs (Latest version causes errors while rendering dialog scenes)
    useEffect(() => {
        if (vp.triggerDialog && activeDialog === undefined && !!scene.dialogs) {
            const dialog = scene.dialogs.find((d: Dialog) => d.id === vp.triggerDialog)
            if (dialog !== undefined) {
                setActiveDialog({ dialog, dialogSceneId: varRepo.findStartDialogSceneId(dialog) })
            }
        }
    }, [vp.triggerDialog, activeDialog, scene.dialogs])
    //End of fixed part

    if (vp.closeDialog && activeDialog !== undefined) {
        exitDialog()
    }

    if (activeDialog !== undefined && activeDialogSceneId) {
        activeDialogScene = activeDialog.dialogScenes.find((ds: DialogScene) => ds.id === activeDialogSceneId)
    }

    const executeLink = (link: Link, varChanges?: VariableChange[]) => {
        if (link.type === LinkType.VIEWPOINT) {
            setActiveDialog({ dialog: undefined, dialogSceneId: undefined })
            navigate(Routes.game.SCENE_WITH_VP.replace(':id', scene.id).replace(':vpid', link.id))
        } else if (link.type === LinkType.BOARD) {
            navigate(Routes.game.CONTENT_BOARD.replace(':id', link.id).replace(':scid', scene.id))
        } else if (link.type === LinkType.DIALOG_SCENE && activeDialog) {
            if (link.id.indexOf('/') === -1) {
                setActiveDialog({ dialog: activeDialog, dialogSceneId: link.id })
            } else {
                const parts = link.id.split('/')
                setActiveDialog({ dialog: activeDialog, dialogSceneId: parts[1] })
                navigate(Routes.game.SCENE_WITH_VP.replace(':id', scene.id).replace(':vpid', parts[0]))
            }
            if (varChanges !== undefined) {
                applyVariableChanges(varChanges)
            }
            if (link.id === 'configure-routines' && backlink) {
                // Navigate to configure routines
                executeLink(backlink)
                navigate(Routes.game.CONFIGURE_ROUTINES)
            }
        } else if (link.type === LinkType.SCENE) {
            setActiveDialog({ dialog: undefined, dialogSceneId: undefined })
            if (link.id.indexOf('/') > -1) {
                const parts = link.id.split('/')
                navigate(Routes.game.SCENE_WITH_VP.replace(':id', parts[0]).replace(':vpid', parts[1]))
            } else {
                navigate(Routes.game.SCENE_WITH_VP.replace(':id', link.id).replace(':vpid', ''))
            }
        } else if (link.type === LinkType.OVERLAY) {
            // we check for link.id ( dojoLog-morning-routine , dojoLog-training,  dojoLog-evning-routine, configure-routines)
            if (link.id === 'dojoLog-morning-routine' && backlink) {
                addGeneratedRecords(generateRoutineItems(items, records, morningRoutine))
                // open the dojo overlay
                executeLink(backlink)
                navigate(Routes.tracking.DOJOLOG)
            }

            if (link.id === 'dojoLog-training' && backlink) {
                addGeneratedRecords(generateDojoItems(items, records, 3))
                // open the dojo overlay
                executeLink(backlink)
                navigate(Routes.tracking.DOJOLOG)
            }

            if (link.id === 'dojoLog-evning-routine' && backlink) {
                addGeneratedRecords(generateRoutineItems(items, records, eveningRoutine))
                // open the dojo overlay
                executeLink(backlink)
                navigate(Routes.tracking.DOJOLOG)
            }
        }
    }

    // calculations for the active area
    // const placement: Placement = {}
    // if (activeArea) {
    //   if (activeArea.x > SCENE_WIDTH / 2) {
    //     placement['right'] = SCENE_WIDTH - activeArea.x
    //   } else {
    //     placement['left'] = activeArea.x + activeArea.w
    //   }
    //   if (activeArea.y > SCENE_HEIGHT / 2) {
    //     placement['bottom'] = SCENE_HEIGHT - activeArea.y - activeArea.h
    //   } else {
    //     placement['top'] = activeArea.y
    //   }
    // }

    // let currentBoard: ContentBoard | undefined = undefined
    // if (scene && scene.contentBoard !== undefined) {
    //   currentBoard = boards.find((b: ContentBoard) => {
    //     return b.id === scene.contentBoard!.id
    //   })
    // }

    let backlink = scene.backLink
    if (vp.backLink) backlink = vp.backLink

    const ry = vp.ry !== undefined ? vp.ry : 0
    const scale = vp.scale !== undefined ? vp.scale : 1
    const tx = vp.tx !== undefined ? vp.tx : 0
    const ty = vp.ty !== undefined ? vp.ty : 0
    const transform = `rotateY(${ry}deg) scale(${scale}) translate(${tx}px, ${ty}px)`

    return (
        <Box id={'snapshottable'} className={'scene' + (activeOverlay ? ' overlaid' : '')} key={scene.id}>
            <Box className={'inner'} style={{ transform }}>
                {scene.items && (
                    <>
                        {scene.items.map((item: SceneItem) => {
                            if (item.mods && varRepo.getItemModifier(item) === Modifier.HIDDEN) return null
                            else if (item.type === SceneItemType.GRAPHIC) return renderGraphic(item, vp)
                            else if (item.type === SceneItemType.AREA)
                                return renderArea(item, vp, executeLink, debugMode)
                            else if (item.type === SceneItemType.LABEL) return renderLabel(item, vp)
                            else if (item.type === SceneItemType.BOARD && item.boardId)
                                return renderBoard(item, vp, item.boardId)
                            return null
                        })}
                    </>
                )}

                {activeDialog && activeDialogScene && vp.dialogPlacement && (
                    <DialogPanel placement={vp.dialogPlacement} dialogScene={activeDialogScene} viewPoint={vp} />
                )}
            </Box>
            {activeDialog && activeDialogScene && (
                <AnswerPanel dialogScene={activeDialogScene} executeLink={executeLink} />
            )}

            {!!backlink && activeOverlay === undefined && (
                <Box
                    className="backlink"
                    onClick={() => {
                        exitDialog()
                        if (backlink) executeLink(backlink)
                    }}
                >
                    &lt; {backlink.label ? backlink.label : 'zurück'}
                </Box>
            )}
            {activeOverlay !== undefined && (
                <Box className="overlay">
                    <header>
                        <IconButton color="inherit" onClick={() => setActiveOverlay(undefined)}>
                            <CloseIcon htmlColor={'#fff'} fontSize="large" />
                        </IconButton>
                    </header>
                    {activeOverlay === OverlayType.MEETING && <MeetingOverlay />}
                    {activeOverlay === OverlayType.MESSAGING && <MessagingOverlay />}
                </Box>
            )}
        </Box>
    )
}

export default InteractiveScenePanel
