// Error Page
import { Box, Card, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

//?? I saved this in a pages folder becuase it doesn't make sense to me to have it in feature folder
const ErrorPage = (): JSX.Element => {
    return (
        <Box
            className="bonsai"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '16px 64px',

                    margin: '16px',
                    gap: '16px',
                }}
            >
                <Typography variant="h1">404</Typography>
                <Typography variant="h2">Page not found</Typography>
                <Typography variant="body1">
                    <Link to="/">Go to Home</Link>
                </Typography>
            </Card>
        </Box>
    )
}

export default ErrorPage
