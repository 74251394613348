import { Box, Button, Container, Grid, Stack } from '@mui/material'
import ScrollToTop from '../../../components/ScrollToTop'
import { useStoreActions } from '../../../store/hooks'
import useGameStateVarRepo from '../../../util/hooks/useVariableRepository'
import { boards, contents } from '../data/contentData'
import { BoardItem, ContentType, CourseVideoType, Modifier } from '../data/types'

interface VideoContentPageProps {
    contentBoardID: string // Not quite sure if this is actually the correct naming lol
    contentID: string
}
const VideoContentOverlay = (props: VideoContentPageProps): JSX.Element => {
    const { contentID, contentBoardID } = props
    const setVariable = useStoreActions((state) => state.gameModel.setVariable)
    const applyVariableChanges = useStoreActions((state) => state.gameModel.applyVariableChanges)

    const varRepo = useGameStateVarRepo()

    // const { t } = useTranslation();

    let boardItem: BoardItem | undefined
    const content = contents.find((c) => c.id === contentID)
    const board = boards.find((b) => b.id === contentBoardID)
    if (board) boardItem = board.items.find((bi) => bi.contentId === contentID)

    if (
        contentID === undefined ||
        content === undefined ||
        board === undefined ||
        boardItem === undefined ||
        content.type !== ContentType.VIDEO ||
        !content.videoContent
    )
        return <h1>Error</h1>

    const isFinished = varRepo.isContentFinished(content)

    return (
        <Box sx={{ transform: 'rotateX(6deg) translateY(-15px)' }}>
            <img
                src={'/img/general/tablet_hands.svg'}
                width="100%"
                style={{ transform: 'rotateX(14deg) translateY(10px)' }}
                alt={'map of life overview'}
            />
            <Box
                sx={{
                    position: 'absolute',
                    width: '1300px',
                    left: '170px',
                    height: '10px',
                    top: 120,
                }}
            >
                <Box
                    sx={{
                        display: 'inline-block',
                        background: 'white',
                        position: 'absolute',
                        width: '1490px',
                        left: '40px',
                        height: '950px',
                        boxSizing: 'border-box',
                    }}
                >
                    <Container
                        className={'main'}
                        maxWidth={'xl'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            boxSizing: 'border-box',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ScrollToTop />
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item md={12} xs={12}>
                                <h1>{content.title}</h1>
                                {content.videoContent && content.videoContent.type === CourseVideoType.YOUTUBE && (
                                    <Box
                                        style={{
                                            position: 'relative',
                                            paddingBottom: '56.25%',
                                            height: 0,
                                            overflow: 'hidden',
                                            maxWidth: '100%',
                                        }}
                                    >
                                        <iframe
                                            width="100%"
                                            src={
                                                'https://www.youtube.com/embed/' +
                                                content.videoContent.videoId +
                                                '?rel=0&amp;showinfo=0'
                                            }
                                            title="embedded YouTube video player"
                                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            style={{
                                                border: 'none',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                width: '100%',
                                                height: '100%',
                                            }}
                                        ></iframe>
                                    </Box>
                                )}
                                <Stack
                                    direction={'row'}
                                    justifyContent={'flex-end'}
                                    alignItems={'center'}
                                    marginTop={2}
                                    marginBottom={2}
                                >
                                    {isFinished && <Box pt={2}> ✓ Du hast dieses Video als gesehen markiert.</Box>}
                                    {!isFinished && (
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                if (boardItem?.varChanges) applyVariableChanges(boardItem?.varChanges)
                                                setVariable({ var: contentID, value: Modifier.COMPLETED })
                                            }}
                                        >
                                            Inhalt als gesehen markieren
                                        </Button>
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Box>
    )
}

export default VideoContentOverlay
