import { Box, Container } from '@mui/material'
import { useNavigate, useParams } from 'react-router-dom'

import { ContentBoard } from '../../../store/types'
import useDimensionsForStage from '../../../util/hooks/useDimensionsForStage'
import Routes from '../../../util/routes'
import { boards, contents } from '../data/contentData'
import ContentBoardPanel from './ContentBoardPanel'

interface ParamTypes {
    id: string
    scid: string
    vpid: string
}

const ContentBoardPage = (): JSX.Element => {
    //  const courses = useStoreState((state) => state.gameData.courseData)
    const navigate = useNavigate()
    const { id, scid, vpid } = useParams<keyof ParamTypes>() as ParamTypes
    const currentBoard: ContentBoard | undefined = boards.find((b: ContentBoard) => {
        return b.id === id
    })

    const { observe, currentBreakpoint, factor } = useDimensionsForStage()

    if (currentBoard !== undefined) {
        return (
            <Container maxWidth={'xl'}>
                <Box ref={observe} className={`scenecontainer ${('' + currentBreakpoint).toLowerCase()}`}>
                    <Box className="scenecontainerInner" style={{ width: '100%', transform: 'scale(' + factor + ')' }}>
                        <ContentBoardPanel
                            board={currentBoard}
                            contents={contents}
                            displayMode={false}
                            backClicked={() => {
                                if (vpid)
                                    navigate(Routes.game.SCENE_WITH_VP.replace(':id', scid).replace(':vpid', vpid))
                                else navigate(Routes.game.SCENE.replace(':id', scid))
                            }}
                        />
                    </Box>
                </Box>
            </Container>
        )
    } else {
        return <>no such board</>
    }
}

export default ContentBoardPage
