import { ellisCafe } from './ellisCafe'
import { ellisDev } from './ellisDev'
import { ellisDojo } from './ellisDojo'
import { ellisMapstandDetail } from './ellisMapStandDetail'
import { ellisOffice } from './ellisOffice'
import { ellisStreet } from './ellisStreet'
import { Scene } from './types'

const scenes: Scene[] = [ellisOffice, ellisStreet, ellisDojo, ellisDev, ellisMapstandDetail, ellisCafe]

export default scenes

/* 
- ellis begrüßt mich zum ersten mal über x schritte
  -> conditions die nur beim ersten durchlauf da sind, dann werden variablen gesetzt die das verbieten
- ellis denkt ich bin jetzt weit genug für die reise in den ersten ort
  -> default hidden, visible mit conditions
- ellis begrüßt mich wenn ich wiederkomme
  -> fallback


*/
