import { useParams } from 'react-router-dom'
// import { useStoreActions } from "../../store/hooks";
import { Box, Container } from '@mui/material'
import html2canvas from 'html2canvas'
import { useEffect } from 'react'
import Navigation from '../../../components/Navigation'
import { useStoreActions, useStoreState } from '../../../store/hooks'
import useDimensionsForStage from '../../../util/hooks/useDimensionsForStage'
import sceneData from '../data/sceneData'
import { Scene } from '../data/types'
import './InteractiveScene.css'
import InteractiveScenePanel from './InteractiveScenePanel'

interface ParamTypes {
    id: string
}

const InteractiveScenePage = (): JSX.Element => {
    const activeDialog = useStoreState((state) => state.gameModel.activeDialog)
    const activeOverlay = useStoreState((state) => state.gameModel.activeOverlay)
    const setOverlayBackground = useStoreActions((state) => state.gameModel.setOverlayBackground)
    const currentPosition = useStoreState((state) => state.gameModel.currentPosition)

    // const resetFinishedContentIDs = useStoreActions(
    //   (state) => state.gameData.resetFinishedContentIDs
    // );
    const { id } = useParams<keyof ParamTypes>()
    const currentScene: Scene | undefined = sceneData.find((scene: Scene) => {
        return scene.id === id
    })

    const getScene = (id: string): Scene | undefined => {
        return sceneData.find((scene: Scene) => {
            return scene.id === id
        })
    }
    useEffect(() => {
        const scene = document.getElementById('snapshottable')
        if (scene) {
            html2canvas(scene).then((canvas) => {
                const dataUrl = canvas.toDataURL('image/png')
                setOverlayBackground(dataUrl)
            })
        }
    }, [currentScene, currentPosition])

    const { observe, currentBreakpoint, factor } = useDimensionsForStage()

    if (currentScene !== undefined) {
        return (
            <>
                <Container maxWidth={'xl'}>
                    <Box ref={observe} className={`scenecontainer ${('' + currentBreakpoint).toLowerCase()}`}>
                        {!activeDialog && !activeOverlay && <Navigation />}
                        <Box
                            className="scenecontainerInner"
                            style={{ width: '100%', transform: 'scale(' + factor + ')' }}
                        >
                            <InteractiveScenePanel scene={currentScene} getScene={getScene} />
                        </Box>
                    </Box>
                </Container>
            </>
        )
    } else {
        return <>no such scene</>
    }
}

export default InteractiveScenePage
