import { Button, Card, CardMedia, Stack, Typography } from '@mui/material'

import { DojoItemComponentProps, DojoItemState } from '../../../../features/habitTracking/views/dojoView/DojoView'

import { palette } from '../../../../theme/palette'

export type QuoteItemProps = DojoItemComponentProps

export interface QuoteItemConfig {
    text: string
    author: string
    authorURL: string
}

export const defaultQuoteItemConfig: QuoteItemConfig = {
    text: '',
    author: '',
    authorURL: '',
}

export interface QuoteItemStatus {}

export const defaultQuoteItemStatus: QuoteItemStatus = {}

const QuoteCardItem = (props: QuoteItemProps): JSX.Element => {
    const { item, record, activeItemID, onTrackDojoItem, onNext } = props
    const config: QuoteItemConfig = item.config ? JSON.parse(item.config) : defaultQuoteItemStatus
    // const status: QuoteItemStatus = record.status ? JSON.parse(record.status) : defaultQuoteItemStatus
    const { text, author, authorURL } = config
    const state: DojoItemState =
        activeItemID === item.id ? DojoItemState.ACTIVE : record.viewed ? DojoItemState.DONE : DojoItemState.NEW

    const handleImageError: React.ReactEventHandler<HTMLImageElement> = (e) => {
        e.currentTarget.onerror = null
        e.currentTarget.src = 'https://via.placeholder.com/150'
    }
    return (
        <Card
            sx={{
                position: 'relative',
                margin: '20px',
                width: '100%',
            }}
        >
            {state === DojoItemState.NEW && (
                <>
                    <Typography variant="h1" color={palette.text?.primary} paddingX={'24px'} paddingY={'16px'}>
                        Quote
                    </Typography>
                </>
            )}

            {state !== DojoItemState.NEW && (
                <>
                    <Stack direction="row" justifyContent="center" alignContent={'center'} alignItems={'center'}>
                        <CardMedia
                            sx={{
                                paddingX: '24px',
                                paddingY: '16px',
                                flex: 1,
                                filter: state === DojoItemState.DONE ? 'grayscale(100%)' : 'none',
                            }}
                            component="img"
                            height="256"
                            image={authorURL}
                            alt={author}
                            onError={handleImageError}
                        />

                        <Typography
                            color={state === DojoItemState.DONE ? palette.text?.disabled : palette.text?.primary}
                            flex={3}
                            variant="h4"
                            sx={{ textAlign: 'left', wordBreak: 'break-word' }}
                            paddingY={'40px'}
                            paddingX={'24px'}
                        >
                            {text}
                        </Typography>
                    </Stack>
                    <Typography
                        variant="h4"
                        color={state === DojoItemState.DONE ? palette.text?.disabled : palette.text?.primary}
                        sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            paddingX: '24px',
                            paddingY: '16px',
                        }}
                    >
                        {author}
                    </Typography>
                    {item.id === activeItemID && (
                        <Stack
                            display={'flex'}
                            direction={'row'}
                            justifyContent={'flex-end'}
                            spacing={2}
                            alignItems={'center'}
                            marginTop={2}
                            marginBottom={2}
                            marginRight={2}
                        >
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onNext()
                                }}
                                variant={'contained'}
                            >
                                <Typography variant="h5" color={'white'}>
                                    Next
                                </Typography>
                            </Button>
                            <Button
                                variant={'contained'}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onTrackDojoItem(item, true, JSON.stringify({} as QuoteItemStatus))
                                    onNext()
                                }}
                            >
                                <Typography variant="h5" color={'white'}>
                                    Done
                                </Typography>
                            </Button>
                        </Stack>
                    )}
                </>
            )}
        </Card>
    )
}

export default QuoteCardItem
