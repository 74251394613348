import { action, Action, thunk, Thunk } from 'easy-peasy'
import { OverlayType, VariableRepository } from '../../features/gameOfLife/data/types'
import {
    loadCurrentPosition,
    loadCurrentScene,
    loadVariables,
    saveCurrentLocation,
    saveVariables,
} from '../storage/gameModelStore'
import { Dialog, SceneIdentifier, ScenePosition, VariableChange, VariableChangeType } from '../types'

export interface GameModel {
    variables: VariableRepository
    currentScene: string
    currentPosition: ScenePosition
    activeOverlay?: OverlayType
    activeDialog?: Dialog
    activeDialogSceneId?: string
    debugMode: boolean
    overlayBackground?: string

    // actions
    setVariable: Action<GameModel, { var: string; value: number }>
    removeVariable: Action<GameModel, string>
    setCurrentLocation: Action<GameModel, { scene: SceneIdentifier; position: ScenePosition }>
    setActiveDialog: Action<GameModel, { dialog: Dialog | undefined; dialogSceneId: string | undefined }>
    setActiveOverlay: Action<GameModel, OverlayType | undefined>
    exitDialog: Action<GameModel>
    toggleDebugMode: Action<GameModel>
    applyVariableChanges: Action<GameModel, VariableChange[]>
    setOverlayBackground: Action<GameModel, string>

    //Initialization
    loadData: Thunk<GameModel>
    setCurrentScene: Action<GameModel, { scene: SceneIdentifier; position: ScenePosition }>
}

const gameModel: GameModel = {
    variables: {},
    currentScene: '',
    currentPosition: ScenePosition.VOID,
    debugMode: false,
    overlayBackground: undefined,

    // Initialization
    setCurrentScene: action((state, { scene, position }) => {
        state.currentScene = scene
        state.currentPosition = position
    }),

    loadData: thunk(async (actions) => {
        actions.setCurrentScene({
            scene: loadCurrentScene(),
            position: loadCurrentPosition(),
        })

        // Load variables
        const loadedVariables = loadVariables()
        const changes = Object.keys(loadedVariables).map((varName) => ({
            action: VariableChangeType.SET,
            var: varName,
            value: loadedVariables[varName],
        }))

        actions.applyVariableChanges(changes)
    }),

    // all the current status of the game world is set through variables
    // TODO: save on the persons account on the server too

    setVariable: action((state, varChange) => {
        state.variables[varChange.var] = varChange.value
        saveVariables(state.variables)
    }),

    removeVariable: action((state, varName) => {
        delete state.variables[varName]
    }),

    toggleDebugMode: action((state) => {
        state.debugMode = !state.debugMode
    }),

    setCurrentLocation: action((state, { scene, position }) => {
        state.currentScene = scene
        state.currentPosition = position
        const travels = state.variables['travels'] ? state.variables['travels'] : 0
        state.variables['travels'] = travels + 1
        saveCurrentLocation(scene, position)
    }),

    setActiveDialog: action((state, dialogData) => {
        state.activeDialog = dialogData.dialog
        state.activeDialogSceneId = dialogData.dialogSceneId
    }),

    exitDialog: action((state) => {
        state.activeDialog = undefined
        state.activeDialogSceneId = undefined
    }),

    setActiveOverlay: action((state, overlayType) => {
        state.activeOverlay = overlayType
    }),

    setOverlayBackground: action((state, background) => {
        state.overlayBackground = background
    }),

    // this function applies changes in variables to the set of all existing variables
    // There are types of actions (increase, reset, toggle) and variable names
    applyVariableChanges: action((state, changes) => {
        changes.forEach((change: VariableChange) => {
            if (change.action === VariableChangeType.SET && change.value) {
                state.variables[change.var] = change.value
            } else if (change.action === VariableChangeType.SUBTRACT && change.value) {
                if (state.variables[change.var] === undefined) state.variables[change.var] = 0
                state.variables[change.var] -= change.value
            } else if (change.action === VariableChangeType.ADD && change.value) {
                if (state.variables[change.var] === undefined) state.variables[change.var] = 0
                state.variables[change.var] += change.value
            } else if (change.action === VariableChangeType.INCREASE) {
                if (state.variables[change.var] === undefined) state.variables[change.var] = 0
                state.variables[change.var] += 1
            } else if (change.action === VariableChangeType.DECREASE) {
                if (state.variables[change.var] === undefined) state.variables[change.var] = 0
                state.variables[change.var] -= 1
            } else if (change.action === VariableChangeType.RESET) {
                if (state.variables[change.var] === undefined) state.variables[change.var] = 0
                state.variables[change.var] = 0
            } else if (change.action === VariableChangeType.DELETE) {
                delete state.variables[change.var]
            } else if (change.action === VariableChangeType.TOGGLE) {
                if (state.variables[change.var] === undefined) state.variables[change.var] = 0
                state.variables[change.var] = state.variables[change.var] === 0 ? 1 : 0
            }
        })
        saveVariables(state.variables)
    }),
}

export default gameModel
