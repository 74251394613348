import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton, useMediaQuery } from '@mui/material'
import { Outlet, useNavigate } from 'react-router-dom'
import { useStoreState } from '../../../store/hooks'
import theme from '../../../theme/theme'
import useDimensionsForStage from '../../../util/hooks/useDimensionsForStage'
import Routes from '../../../util/routes'

const bezelsX = 8
const bezelsY = 40
const thumb = 88

// This component works only as a <Route> in App.tsx with the respective View as a subroute (similar to PrivateRoute)
export const MapOverlay = () => {
    const mobile = useMediaQuery(theme.breakpoints.down('lg'))
    const { observe, factor } = useDimensionsForStage()
    const navigate = useNavigate()
    const sceneID = useStoreState((state) => state.gameModel.currentScene)
    const currentPosition = useStoreState((state) => state.gameModel.currentPosition)
    const backgroundImage = useStoreState((state) => state.gameModel.overlayBackground) ?? '/img/dojo/dojo_fg.svg'

    if (mobile) return <Outlet />

    return (
        <Box
            ref={observe}
            style={{
                // Force 16:10 (for some reason)
                position: 'relative',
                width: '100vw',
                maxWidth: 'min(100vw, 160vh)',
                height: '62.5vw',
                maxHeight: '100vh',
                marginLeft: 'auto',
                marginRight: 'auto',

                overflow: 'hidden',
                boxShadow: '#00000030 0px 20px 30px',
            }}
        >
            <img
                src={backgroundImage}
                style={{
                    position: 'absolute',
                    width: '100%',
                    height: '100%',
                    objectFit: 'cover',
                    filter: 'blur(10px)',
                }}
            />
            <Box
                style={{
                    position: 'relative',
                    transformOrigin: 'top left',
                    transform: `scale(${factor})`,
                    width: '1920px',
                    height: '1200px',
                    perspective: '1600px',
                }}
            >
                <IconButton
                    onClick={() => {
                        if (currentPosition !== 'void')
                            navigate(
                                Routes.game.SCENE_WITH_VP.replace(':id', sceneID).replace(':vpid', currentPosition),
                            )
                        else navigate(Routes.game.SCENE.replace(':id', sceneID))
                    }}
                    style={{
                        position: 'absolute',
                        top: 16,
                        right: 16,
                        zIndex: 7,
                    }}
                >
                    <CloseIcon htmlColor={'#fff'} fontSize="large" />
                </IconButton>

                <Box
                    sx={{
                        transform: 'rotateX(6deg) translateY(-15px)',
                    }}
                >
                    <img src={'/img/general/paper_hands.svg'} width="100%" />

                    <Box
                        sx={{
                            width: `${1744 - 2 * (thumb + bezelsX)}px`,
                            height: `${1093 - 2 * bezelsY}px`,

                            position: 'absolute',
                            left: 88 + thumb + bezelsX,
                            top: 40 + bezelsY,

                            background: 'white',
                        }}
                    >
                        <Outlet />
                    </Box>
                </Box>
            </Box>
        </Box>
    )
}

export default MapOverlay
