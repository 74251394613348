import { v4 } from 'uuid'
import { BooleanItemStatus } from '../features/habitTracking/components/booleanItem/BooleanItem'
import { CounterItemStatus } from '../features/habitTracking/components/counterItem/CounterItem'
import { TimerItemStatus } from '../features/habitTracking/components/timerItem/TimerItem'
import { Record } from '../graphql/types'
import { getOffsetDay } from '../util/functions/testDate'
import { testHabitMeditate, testHabitSupplements, testHabitWater } from './habits'

export const testRecordWaterEmpty: Record = {
    id: v4(),
    habit: testHabitWater.id,
    date: getOffsetDay(0),
    status: JSON.stringify({
        value: 0,
    } as CounterItemStatus),
}
export const testRecordWaterHalf: Record = {
    id: v4(),
    habit: testHabitWater.id,
    date: getOffsetDay(1),
    status: JSON.stringify({
        value: 2,
    } as CounterItemStatus),
}

export const testRecordWaterFull: Record = {
    id: v4(),
    habit: testHabitWater.id,
    date: getOffsetDay(2),
    status: JSON.stringify({
        value: 4,
    } as CounterItemStatus),
}

export const testRecordSupplementsNotDone: Record = {
    id: v4(),
    habit: testHabitSupplements.id,
    date: getOffsetDay(0),
    status: JSON.stringify({
        checked: false,
    } as BooleanItemStatus),
}

export const testRecordSupplementsDone: Record = {
    id: v4(),
    habit: testHabitSupplements.id,
    date: getOffsetDay(1),
    status: JSON.stringify({
        checked: true,
    } as BooleanItemStatus),
}

export const testRecordMeditateHalfDone: Record = {
    id: v4(),
    habit: testHabitMeditate.id,
    date: getOffsetDay(0),
    status: JSON.stringify({
        value: 150,
    } as TimerItemStatus),
}
export const testRecordMeditateDone: Record = {
    id: v4(),
    habit: testHabitMeditate.id,
    date: getOffsetDay(1),
    status: JSON.stringify({
        value: 300,
    } as TimerItemStatus),
}

export const testRecords: Record[] = [
    testRecordWaterEmpty,
    testRecordWaterHalf,
    testRecordWaterFull,
    testRecordSupplementsNotDone,
    testRecordSupplementsDone,
    testRecordMeditateHalfDone,
    testRecordMeditateDone,
]
