import { Box, Button, Container, Grid, Paper, Stack } from '@mui/material'
import ScrollToTop from '../../../components/ScrollToTop'
import { useStoreActions } from '../../../store/hooks'
import { BoardItem, ContentType, Modifier } from '../../../store/types'
import { ContentElement } from '../../../util/functions/resolveContents'
import useGameStateVarRepo from '../../../util/hooks/useVariableRepository'
import { boards, contents } from '../data/contentData'
import './TextContent.css'

const renderContent = (content: ContentElement[]) => {
    return content.map((ce: ContentElement) => {
        if (ce.type === 'header') {
            const className = 'content_h' + ce.data.level
            return (
                <Box className={className} key={ce.data.text}>
                    {ce.data.text}
                </Box>
            )
        } else if (ce.type === 'paragraph') {
            return (
                <Box
                    key={ce.data.text}
                    className={'content_p'}
                    dangerouslySetInnerHTML={{ __html: ce.data.text }}
                ></Box>
            )
        } else if (ce.type === 'delimiter') {
            return <hr className={'content_hr'} key={'' + Math.random() * 1000}></hr>
        } else if (ce.type === 'quote') {
            return (
                <figure key={ce.data.text} className={'content_quote'}>
                    <blockquote>{ce.data.text}</blockquote>
                    {ce.data.caption && <figcaption>{ce.data.caption}</figcaption>}
                </figure>
            )
        } else if (ce.type === 'list') {
            const listItems = ce.data.items.map((item) => <li key={item}>{item}</li>)

            if (ce.data.style === 'ordered') return <ol className={'content_ol'}>{listItems}</ol>
            else return <ul className={'content_ul'}>{listItems}</ul>
        } else if (ce.type === 'image') {
            return (
                <figure key={ce.data.file.url} className={'content_figure'}>
                    <img src={ce.data.file.url} alt={'something'} />
                    {ce.data.caption && <figcaption>{ce.data.caption}</figcaption>}
                </figure>
            )
        }
        return undefined
    })
}
interface TextContentPageProps {
    contentBoardID: string // Not quite sure if this is actually the correct naming lol
    contentID: string
}
const TextContentOverlay = (props: TextContentPageProps): JSX.Element => {
    const { contentID, contentBoardID } = props
    const setVariable = useStoreActions((state) => state.gameModel.setVariable)
    const applyVariableChanges = useStoreActions((state) => state.gameModel.applyVariableChanges)
    const varRepo = useGameStateVarRepo()

    let boardItem: BoardItem | undefined
    const content = contents.find((c) => c.id === contentID)
    const board = boards.find((b) => b.id === contentBoardID)
    if (board) boardItem = board.items.find((bi) => bi.contentId === contentID)

    if (
        contentID === undefined ||
        content === undefined ||
        board === undefined ||
        boardItem === undefined ||
        content.type !== ContentType.TEXT ||
        !content.textContent
    )
        return <h1>Error</h1>

    const decoded = JSON.parse(content.textContent) as ContentElement[]

    const isFinished = varRepo.isContentFinished(content)

    return (
        <Box sx={{ transform: 'rotateX(6deg) translateY(-15px)' }}>
            <img
                src={'/img/general/tablet_hands.svg'}
                width="100%"
                style={{ transform: 'rotateX(14deg) translateY(10px)' }}
                alt={'map of life overview'}
            />
            <Box
                sx={{
                    position: 'absolute',
                    width: '1300px',
                    left: '170px',
                    height: '10px',
                    top: 120,
                }}
            >
                <Box
                    sx={{
                        display: 'inline-block',
                        background: 'white',
                        position: 'absolute',
                        width: '1490px',
                        left: '40px',
                        height: '950px',
                        boxSizing: 'border-box',
                        overflow: 'auto',
                        padding: '20px',
                    }}
                >
                    <Container
                        className={'main'}
                        maxWidth={'xl'}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            boxSizing: 'border-box',
                            alignItems: 'center',
                            justifyContent: 'center',
                        }}
                    >
                        <ScrollToTop />
                        <Grid container spacing={2} justifyContent={'center'}>
                            <Grid item md={10} xs={12}>
                                <Paper>
                                    <Box pr={4} pl={4} pt={1} pb={4}>
                                        <h1>{content.title}</h1>
                                        <>{renderContent(decoded)}</>
                                    </Box>
                                </Paper>
                                {isFinished && (
                                    <Stack
                                        direction={'row'}
                                        justifyContent={'flex-end'}
                                        alignItems={'center'}
                                        marginTop={2}
                                    >
                                        <Box pt={2}> ✓ Du hast diesen Inhalt als gelesen markiert.</Box>
                                    </Stack>
                                )}
                                {!isFinished && (
                                    <Stack
                                        direction={'row'}
                                        justifyContent={'flex-end'}
                                        alignItems={'center'}
                                        marginTop={2}
                                    >
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => {
                                                if (boardItem?.varChanges) applyVariableChanges(boardItem?.varChanges)
                                                setVariable({ var: contentID, value: Modifier.COMPLETED })
                                            }}
                                        >
                                            Inhalt als gelesen markieren
                                        </Button>
                                    </Stack>
                                )}
                            </Grid>
                        </Grid>
                    </Container>
                </Box>
            </Box>
        </Box>
    )
}

export default TextContentOverlay
