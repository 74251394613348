import { useEffect, useState } from 'react'
import { Node, NodeTypes, useNodesState, useReactFlow } from 'react-flow-renderer'
import { IslandData, LocationData } from '../../../graphql/types'
import Island from './components/Island'
import { mapData } from './mapData'

export const islandNodeTypes: NodeTypes = {
    island: Island,
}

export interface useMapOFfLifePropsReturn {
    nodes: Node[]
    openDrawer: boolean
    island: IslandData | undefined
    location: LocationData | undefined
    handleDrawerClose: () => void
    handleLocationSelect: (location?: LocationData | undefined) => void
    focusOnIsland: (selectedIsland: string, islandWidth: number, islandHeigth: number) => void
    setOpenDrawer: (open: boolean) => void
    setCenter: (x: number, y: number, { zoom, duration }: { zoom: number; duration: number }) => void
}

export const useMapOfLife = (): useMapOFfLifePropsReturn => {
    const [nodes, setNodes] = useNodesState([])
    const [selectedIsland, setSelectedIsland] = useState<string>('')
    const [selectedLocation, setSelectedLocation] = useState<string>('')
    const { setCenter } = useReactFlow()
    const [openDrawer, setOpenDrawer] = useState(false)
    const [zoomPosition, setZoomPosition] = useState({ x: 0, y: 0, iZoom: 0.2 })

    //Focusing on selected island depending on its heigth and width
    //Zooming in to the island and setting in to the center of the screen
    const focusOnIsland = (selectedIsland: string, islandWidth: number, islandHeigth: number) => {
        setSelectedIsland(selectedIsland)
        const island = mapData.find((isd) => isd.id === selectedIsland)
        const maxDimension = island ? Math.max(islandWidth, islandHeigth) : 0
        const zoom = maxDimension ? Math.min((1 / maxDimension) * 850, 1) : 1

        if (island) {
            const centerX = island.x + islandWidth / 2
            const centerY = island.y + islandHeigth / 2
            setCenter(centerX, centerY, { zoom, duration: 800 })
            setZoomPosition({ x: centerX, y: centerY, iZoom: zoom })
        }
    }

    useEffect(() => {
        const centerX = window.innerWidth / 2
        const centerY = window.innerHeight / 2

        setCenter(centerX + 1700, centerY, { zoom: 0.2, duration: 800 })
    }, [nodes])

    //Closing the drawer
    const handleDrawerClose = () => {
        setOpenDrawer(false)
        setSelectedIsland('')
        setSelectedLocation('')
    }

    //Selecting the location
    const handleLocationSelect = (location?: LocationData | undefined) => {
        if (location !== undefined) {
            setSelectedLocation(location.id)
        } else {
            setSelectedLocation('')
        }
    }

    //Finding the island by id
    const findIslandById = (islands: IslandData[], id: string): IslandData | undefined => {
        return islands.find((island) => island.id === id)
    }

    //Finding the location by id
    const findLocationById = (locations: LocationData[], id: string): LocationData | undefined => {
        return locations.find((location) => location.id === id)
    }

    let island: IslandData | undefined
    let location: LocationData | undefined

    //Setting the island and location
    if (selectedIsland && !selectedLocation) {
        island = findIslandById(mapData, selectedIsland)
    } else if (selectedLocation) {
        island = findIslandById(mapData, selectedIsland)
        if (island) {
            location = findLocationById(island.locations, selectedLocation)
        }
    }

    //Opening the drawer if the location is selected
    useEffect(() => {
        if (selectedLocation) {
            setOpenDrawer(true)
        }
    }, [selectedLocation])

    //Setting the nodes
    useEffect(() => {
        const nodeData = mapData.map((island) => {
            return {
                id: island.id,
                type: 'island',
                position: { x: island.x, y: island.y },
                data: {
                    name: island.name,
                    width: island.width,
                    height: island.height,
                    locations: island.locations,
                    onIslandSelected: () => {
                        setSelectedIsland(island.id)
                        setSelectedLocation('')
                    },
                    onLocationSelected: (id: string) => {
                        setSelectedIsland(island.id)
                        setSelectedLocation(id)
                    },
                },
            }
        })

        setNodes(nodeData)
    }, [setNodes])

    //Zooming in to the selected location
    useEffect(() => {
        const island = mapData.find((isd) => isd.id === selectedIsland)

        if (!island) {
            return
        }

        const location = island.locations.find((loc) => loc.id === selectedLocation)
        const zoom = 4

        if (location !== undefined) {
            const centerX = island.x + location.x - 10
            const centerY = island.y + location.y
            setCenter(centerX, centerY, { zoom, duration: 800 })
        } else if (location !== undefined && selectedLocation === '') {
            setCenter(zoomPosition.x, zoomPosition.y, { zoom: zoomPosition.iZoom, duration: 800 })
        } else {
            setCenter(zoomPosition.x, zoomPosition.y, { zoom: zoomPosition.iZoom, duration: 800 })
        }
    }, [selectedLocation])

    return {
        nodes,
        openDrawer,
        island,
        location,
        handleDrawerClose,
        handleLocationSelect,
        focusOnIsland,
        setOpenDrawer,
        setCenter,
    }
}
