import { Box, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useNavigate } from 'react-router-dom'
import { Habit, HabitType, Record } from '../../../graphql/types'
import { useStoreState } from '../../../store/hooks'
import { useRootContainer } from '../../../util/hooks/useRootStack'
import Routes from '../../../util/routes'
import { StackItem, StackItemConfig, defaultStackConfig } from '../../habitTracking/components/stackItem/StackItem'
import { HabitComponentProps } from '../../habitTracking/views/trackingView/TrackingView'
import { BottomBar } from '../components/BottomBar'
import { DojoCard } from '../components/DojoCard'
export function DashboardView() {
    const rootContainer = useRootContainer()

    const message = useStoreState((state) => state.dashboardModel.message)
    const habits = useStoreState((state) => state.trackingModel.habits)
    const records = useStoreState((state) => state.trackingModel.records)
    const currentDay = useStoreState((state) => state.timeModel.currentDay)

    const navigate = useNavigate()

    const handleTrackingClick = () => navigate(Routes.tracking.TRACKING.replace(':currentDay', `${currentDay}`))

    return (
        <Box className="bonsai">
            <BottomBar />
            {/* https://stackoverflow.com/questions/70154862/how-to-use-multiple-css-classes-with-mui-5-sx-prop */}
            <Stack sx={[...(Array.isArray(rootContainer) ? rootContainer : [rootContainer])]} gap={2}>
                <DojoCard msg={message} />
                <Paper elevation={1} onClick={handleTrackingClick} sx={{ height: 40 }}>
                    <Stack direction="row" justifyContent="center" alignItems="center" sx={{ height: '100%' }}>
                        <Typography variant="h6" color="primary">
                            Tracking Overview
                        </Typography>
                    </Stack>
                </Paper>

                <Stack direction="column" gap={1}>
                    {habits.map((habit: Habit) => {
                        // TODO: Just take the last record here, see date filtering
                        const record = records.filter((record) => record.habit === habit.id).at(-1)

                        const props: HabitComponentProps = {
                            habit,
                            record,
                            active: true,
                            onTrackHabit: () => undefined,
                            onInfoClick: () => undefined,
                        }

                        if (habit.type === HabitType.STACK) {
                            const subHabitRecords: Map<string, Record> = new Map()
                            const config: StackItemConfig = habit.config ? JSON.parse(habit.config) : defaultStackConfig
                            for (const subHabit of config.subHabits) {
                                // TODO: Just take the last record here, see date filtering
                                const subHabitRecord = records.filter((record) => record.habit === subHabit.id).at(-1)
                                if (subHabitRecord) subHabitRecords.set(subHabit.id, subHabitRecord)
                            }
                            return <StackItem key={habit.id} {...props} subHabitRecords={subHabitRecords} />
                        }
                        return null
                    })}
                </Stack>
            </Stack>
        </Box>
    )
}
