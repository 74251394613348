import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DojoItem, DojoItemRecord } from '../../../../graphql/types'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import { dateToDay, dayToDate } from '../../../../util/functions/dayConversion'
import Routes from '../../../../util/routes'

export interface useDetailsViewReturn {
    activeItemID: string
    currentDay: number
    setCurrentDay: (currentDay: number) => void
    listItems: [DojoItem, DojoItemRecord][]
    active: boolean
    today: number
    setActiveItemID: (payload: string) => void
    onNavigate: (day: number) => void
    onTrackDojoItem: (item: DojoItem, viewed: boolean, status: string) => void
    onNext: () => void
}

type DojoViewParams = {
    currentDay: string | undefined
}

export const useDojoView = (): useDetailsViewReturn => {
    const { currentDay: currentParamDay } = useParams<DojoViewParams>()
    const navigate = useNavigate()

    // Store state
    const activeItemID = useStoreState((state) => state.dojoLogModel.activeItemID)
    const dojoItems = useStoreState((state) => state.dojoLogModel.dojoItems)
    const records = useStoreState((state) => state.dojoLogModel.dojoItemRecords)
    const currentStoreDay = useStoreState((state) => state.timeModel.currentDay)
    // Store actions
    const trackDojoItem = useStoreActions((state) => state.dojoLogModel.trackDojoItem)
    const setActiveItemID = useStoreActions((state) => state.dojoLogModel.setActiveItemID)
    const setCurrentDay = useStoreActions((state) => state.timeModel.setCurrentDay)

    const currentDay = parseInt(currentParamDay ?? '') || currentStoreDay
    const today = dateToDay(new Date())
    const active = today - currentDay < 3

    useEffect(() => {
        setActiveItemID(dojoItems.length > 0 && dojoItems[0].id ? dojoItems[0].id : '')
    }, [])

    if (parseInt(currentParamDay ?? '') !== currentDay)
        window.history.replaceState('', '', Routes.tracking.DOJOLOG.replace(':currentDay?', currentDay.toString()))

    const onNavigate = (day: number) => {
        setCurrentDay(day)
        navigate(Routes.tracking.DOJOLOG.replace(':currentDay', day.toString()))
        // when you navigate to a new day you want to reset the active item
        setActiveItemID('')
    }

    const onNext = () => {
        const currentItem = dojoItems.find((item) => item.id === activeItemID)
        if (currentItem) {
            const currentIndex = dojoItems.indexOf(currentItem)
            const nextItem = dojoItems[currentIndex + 1]
            if (nextItem) {
                setActiveItemID(nextItem.id)
            } else {
                // if you are at the end of the list
                // make it emtpy so no item is active
                setActiveItemID('')
            }
        }
    }

    const onTrackDojoItem = (item: DojoItem, viewed: boolean, status: string) =>
        trackDojoItem({ item: item.id, date: dayToDate(currentDay).getTime(), viewed, status })

    const listItems: [DojoItem, DojoItemRecord][] = []
    for (const record of records.filter(
        (record) => new Date(record.date).toDateString() === dayToDate(currentDay).toDateString(),
    )) {
        const item = dojoItems.find((item) => item.id === record.item)
        if (item) listItems.push([item, record])
    }

    return {
        activeItemID,
        setActiveItemID,
        listItems,
        currentDay,
        setCurrentDay,
        active,
        today,
        onNavigate,
        onTrackDojoItem,
        onNext,
    }
}
