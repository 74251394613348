import { useState } from 'react'
import './messagingOverlay.css'

type Chat = {
    id: string
    name: string
    avatarURL?: string
    lastMessage?: string
    lastMessageTimestamp?: number
    unreadMessages?: number
}

type Message = {
    userId: string
    userName: string
    message: string
    timestamp?: number
}

const chats: Chat[] = [
    {
        id: 'xauw1gqzq4bq',
        name: 'Maxine Musterfrau',
        avatarURL: 'https://teachingandlearning.schulich.yorku.ca/wp-content/uploads/2019/10/avatar6.png',
        lastMessage: 'Hey, wie geht es dir? Lange nichts gehört!',
        lastMessageTimestamp: 1636902892,
        unreadMessages: 3,
    },
    {
        id: 'bqtbvy5h9o2q',
        name: 'Hubert Mustertyp',
        avatarURL: 'http://www.clker.com/cliparts/r/l/z/n/r/F/male-avatar-hi.png',
        lastMessage: 'Boah, supergut!! wie hast du das hinbekommen???',
        unreadMessages: 0,
    },
    {
        id: 'slvtclkoo77x',
        name: 'Meine Gruppe',
        avatarURL: 'http://www.clker.com/cliparts/r/l/z/n/r/F/male-avatar-hi.png',
        unreadMessages: 22,
    },
    {
        id: 'n9o00w8ti7kt',
        name: 'Kommunikation innerer Kreis',
    },
]

const messages: { [key: string]: Message[] } = {
    xauw1gqzq4bq: [
        {
            userId: '23480938240',
            userName: 'Maxine Musterfrau',
            message: 'Hey Ich, wie gehts dir?',
            timestamp: 1636902892,
        },
        {
            userId: '23480938240',
            userName: 'Maxine Musterfrau',
            message: 'Wie ist denn das so bei deiner Aufgabe gelaufen? Magst du das mal zeigen?',
            timestamp: 1636902832,
        },
        {
            userId: '1',
            userName: 'Ich Ichsen',
            message:
                'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
            timestamp: 1636902832,
        },
        {
            userId: '23480938240',
            userName: 'Maxine Musterfrau',
            message: 'Wie ist denn das so bei deiner Aufgabe gelaufen? Magst du das mal zeigen?',
            timestamp: 1636902832,
        },
        {
            userId: '1',
            userName: 'Ich Ichsen',
            message:
                'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
            timestamp: 1636902832,
        },
        {
            userId: '23480938240',
            userName: 'Maxine Musterfrau',
            message: 'Wie ist denn das so bei deiner Aufgabe gelaufen? Magst du das mal zeigen?',
            timestamp: 1636902832,
        },
        {
            userId: '1',
            userName: 'Ich Ichsen',
            message:
                'Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?',
            timestamp: 1636902832,
        },
    ],
    bqtbvy5h9o2q: [],
    slvtclkoo77x: [],
    n9o00w8ti7kt: [],
}

const MessagingOverlay = (): JSX.Element => {
    const [activeChat, setActiveChat] = useState<string | undefined>()

    return (
        <div style={{ transform: 'rotateX(6deg) translateY(-15px)' }}>
            <img src={'/img/general/hand_mobile.svg'} width="100%" alt={'map of life overview'} />
            <div
                style={{
                    position: 'absolute',
                    width: '588px',
                    background: '#ddd',
                    left: '355px',
                    height: '1100px',
                    top: 35,
                    borderRadius: 16,
                    boxSizing: 'border-box',
                    overflow: 'hidden',
                }}
            >
                {activeChat === undefined && (
                    <div>
                        {chats.map((chat) => {
                            return (
                                <div
                                    key={chat.id}
                                    className={'chatItem'}
                                    onClick={() => {
                                        setActiveChat(chat.id)
                                    }}
                                >
                                    <div
                                        className={'chatAvatar'}
                                        style={{
                                            backgroundImage:
                                                'url(' + (chat.avatarURL ? chat.avatarURL : 'img/chatph.jpg') + ')',
                                        }}
                                    ></div>
                                    <div className={'chatInfo'}>
                                        <h2>
                                            {chat.name}
                                            {chat.unreadMessages && <small>{chat.unreadMessages}</small>}
                                        </h2>
                                        {chat.lastMessage && <p>{chat.lastMessage}</p>}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                )}
                {activeChat !== undefined && (
                    <div className={'chat'}>
                        <div
                            className={'chatHeader'}
                            onClick={() => {
                                setActiveChat(undefined)
                            }}
                        >
                            &lt; back
                        </div>
                        <div className={'chatBody'}>
                            {messages[activeChat].map((message) => {
                                return (
                                    <div
                                        key={message.timestamp}
                                        className={'chatBubble' + (message.userId === '1' ? ' mine' : '')}
                                    >
                                        <h3>{message.userName}</h3>
                                        {message.message}
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default MessagingOverlay
