import { SceneIdentifier, ScenePosition } from '../../../store/types'
import { LinkType, SceneItemType } from './types'

export const ellisStreet = {
    id: SceneIdentifier.ELLIS_STREET,
    items: [
        {
            id: 'sbg',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 0,
                y: 0,
                w: 3400,
                h: 1200,
            },

            imageURL: '/img/ellis_street.svg',
        },
        {
            id: 'mst',
            type: SceneItemType.LABEL,
            placement: { x: 2472, y: 712 },
            title: 'MORGENSTROM',
        },

        {
            id: 'larr',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 1500,
                y: 550,
                w: 150,
            },

            imageURL: '/img/arrow_left.svg',
        },
        {
            id: 'rarr',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 1760,
                y: 550,
                w: 150,
            },

            imageURL: '/img/arrow_right.svg',
        },
        {
            id: 'mile',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 340,
                y: 690,
                w: 110,
            },

            imageURL: '/img/miles/miles.svg',
        },
        {
            id: 'mvr',
            type: SceneItemType.AREA,
            placement: {
                x: 1760,
                y: 550,
                w: 150,
                h: 105,
            },
            link: { id: ScenePosition.RIGHT, type: LinkType.VIEWPOINT },
        },
        {
            id: 'mvl',
            type: SceneItemType.AREA,
            placement: {
                x: 1500,
                y: 550,
                w: 150,
                h: 105,
            },
            link: { id: ScenePosition.LEFT, type: LinkType.VIEWPOINT },
        },
        {
            id: 'leof',
            type: SceneItemType.AREA,
            placement: {
                x: 2440,
                y: 700,
                w: 170,
                h: 210,
            },
            link: { id: SceneIdentifier.ELLIS_OFFICE, type: LinkType.SCENE },
        },
        {
            id: 'ldev',
            type: SceneItemType.AREA,
            placement: {
                x: 850,
                y: 690,
                w: 170,
                h: 210,
            },
            link: { id: SceneIdentifier.ELLIS_DEV, type: LinkType.SCENE },
        },
        {
            id: 'ldoj',
            type: SceneItemType.AREA,
            placement: {
                x: 3180,
                y: 730,
                w: 160,
                h: 160,
            },
            link: { id: SceneIdentifier.ELLIS_DOJO, type: LinkType.SCENE },
        },
        {
            id: 'lcaf',
            type: SceneItemType.AREA,
            placement: {
                x: 1840,
                y: 680,
                w: 140,
                h: 210,
            },
            link: { id: SceneIdentifier.ELLIS_CAFE, type: LinkType.SCENE },
        },
        {
            id: 'lmap',
            type: SceneItemType.AREA,
            placement: {
                x: 120,
                y: 730,
                w: 360,
                h: 160,
            },
            link: { id: SceneIdentifier.ELLIS_MAP_DETAIL, type: LinkType.SCENE },
        },
    ],
    viewpoints: [
        { id: ScenePosition.RIGHT, tx: -1480, hide: ['mvr', 'rarr'] },
        { id: ScenePosition.LEFT, hide: ['mvl', 'larr'] },
    ],
}
