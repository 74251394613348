import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import CloseIcon from '@mui/icons-material/Close'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import { Box, Button, Drawer, IconButton, Stack } from '@mui/material'
import ReactFlow, { ControlButton, Controls, ReactFlowProvider } from 'react-flow-renderer'
import { useNavigate } from 'react-router-dom'
import IslandDrawer from './components/IslandDrawer'
import './mapstyles.css'
import { islandNodeTypes, useMapOfLife } from './useMapOfLife'

const MapOfLifeComponent = () => {
    const {
        nodes,
        openDrawer,
        island,
        location,
        handleDrawerClose,
        handleLocationSelect,
        focusOnIsland,
        setOpenDrawer,
        setCenter,
    } = useMapOfLife()
    const navigate = useNavigate()

    return (
        <Box sx={{ position: 'relative', height: '100%', width: '100%' }}>
            <IconButton style={{ position: 'absolute', zIndex: 7, left: 16, top: 16 }} onClick={() => navigate(-1)}>
                <ArrowBackIcon sx={{ fontSize: 40 }} color="secondary" />
            </IconButton>
            {openDrawer && (
                <Drawer
                    anchor={'left'}
                    open={openDrawer}
                    onClose={handleDrawerClose}
                    sx={{
                        padding: '0',
                        margin: '0',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'space-between',
                        '.MuiDrawer-paper': {
                            backgroundImage: 'none',
                            width: '500px',
                            height: '100vh',
                            position: 'absolute',
                            top: '0',
                            outline: 0,
                            overflowY: 'auto',
                            overflowX: 'hidden',
                            '&::-webkit-scrollbar': {
                                width: '0.5em',
                            },
                        },
                    }}
                >
                    <Stack direction="row" sx={{ justifyContent: 'flex-end', padding: '1rem' }}>
                        <Button variant="text" onClick={handleDrawerClose}>
                            <CloseIcon />
                        </Button>
                    </Stack>
                    {island && (
                        <IslandDrawer
                            islandData={island}
                            selectLocation={location}
                            onLocationSelect={handleLocationSelect}
                        />
                    )}
                </Drawer>
            )}

            <ReactFlow
                nodes={nodes}
                nodeTypes={islandNodeTypes}
                maxZoom={2.0}
                minZoom={0.1}
                nodesDraggable={false}
                elementsSelectable={false}
                style={{ backgroundColor: '#003761' }}
                defaultZoom={0.2}
                onNodeClick={(event, node) => {
                    if (node.type === 'island') {
                        focusOnIsland(node.id, node.width as number, node.height as number)
                    }
                }}
                onNodeDoubleClick={() => setOpenDrawer(true)}
            >
                <Controls showInteractive={false} showFitView={false} showZoom={true}>
                    <ControlButton
                        onClick={() =>
                            setCenter(window.innerWidth / 2 + 1700, window.innerHeight / 2, {
                                zoom: 0.2,
                                duration: 800,
                            })
                        }
                    >
                        <FullscreenIcon />
                    </ControlButton>
                </Controls>
            </ReactFlow>
        </Box>
    )
}

const MapOfLife = () => (
    <ReactFlowProvider>
        <MapOfLifeComponent />
    </ReactFlowProvider>
)

export default MapOfLife
