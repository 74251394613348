import { createTheme } from '@mui/material'
import { breakpoints } from './breakpoints'
import { palette } from './palette'
import { typography } from './typography'

export const theme = createTheme({
    breakpoints,
    palette,
    typography,

    components: {
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    '&.MuiSvgIcon-fontSizeLarge': {
                        fontSize: '2rem',
                    },
                },
            },
        },
    },
})

export default theme
