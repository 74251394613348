import { PaletteOptions } from '@mui/material'
import { brown, green, grey } from '@mui/material/colors'
import red from '@mui/material/colors/red'

// Quick n dirty generated via https://www.materialpalette.com/brown/green
// Dont judge!

export const palette: PaletteOptions = {
    primary: {
        light: brown[100],
        main: brown[500],
        dark: brown[700],
        contrastText: '#fff',
    },
    secondary: {
        light: green[100],
        main: green[500],
        dark: green[700],
        contrastText: '#000',
    },

    divider: grey[400],
    error: {
        main: red[400],
    },
    text: {
        primary: grey[900],
        secondary: grey[600],
        disabled: grey[700],
    },
}
