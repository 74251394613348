import { createStore } from 'easy-peasy'
import dashboardModel, { DashboardModel } from './models/dashboardModel'
import dojoLogModel, { DojoLogModel } from './models/dojoLogModel'
import gameModel, { GameModel } from './models/gameModel'
import timeModel, { TimeModel } from './models/timeModel'
import trackingModel, { TrackingModel } from './models/trackingModel'
import userModel, { UserModel } from './models/userModel'

export interface StoreModel {
    userModel: UserModel
    trackingModel: TrackingModel
    dashboardModel: DashboardModel
    dojoLogModel: DojoLogModel
    gameModel: GameModel
    timeModel: TimeModel
}

const store = createStore<StoreModel>({
    userModel,
    trackingModel,
    dashboardModel,
    dojoLogModel,
    gameModel,
    timeModel,
})

export default store
