import { Button, Card, IconButton, Stack, Typography } from '@mui/material'

import { DojoItemComponentProps, DojoItemState } from '../../../../features/habitTracking/views/dojoView/DojoView'

import { RateDayEmoji } from '../../../../graphql/types'
import { palette } from '../../../../theme/palette'

export type RateDayItemProps = DojoItemComponentProps

export interface RateDayItemConfig {}

export const defaultRateDayItemConfig: RateDayItemConfig = {}

export interface RateDayItemStatus {
    rating: RateDayEmoji
}

export const defaultJournaltemStatus: RateDayItemStatus = {
    rating: RateDayEmoji.NEUTRAL,
}

const EmojiURLsMapping = {
    [RateDayEmoji.ANGRY]: '/assets/rateDayEmojis/angry-face-emoji.svg',
    [RateDayEmoji.SAD]: '/assets/rateDayEmojis/sad-face-emoji.svg',
    [RateDayEmoji.NEUTRAL]: '/assets/rateDayEmojis/neutral-face-emoji.svg',
    [RateDayEmoji.HAPPY]: '/assets/rateDayEmojis/smiling-face-emoji.svg',
    [RateDayEmoji.VERY_HAPPY]: '/assets/rateDayEmojis/smiling-eyes-face-emoji.svg',
}
const RateDayItem = (props: RateDayItemProps): JSX.Element => {
    const { item, record, activeItemID, onTrackDojoItem, onNext } = props
    const status: RateDayItemStatus = record.status ? JSON.parse(record.status) : defaultJournaltemStatus
    const { rating } = status
    const state: DojoItemState =
        activeItemID === item.id ? DojoItemState.ACTIVE : record.viewed ? DojoItemState.DONE : DojoItemState.NEW

    const handleRatingChange = (RateDayEmoji: RateDayEmoji) => {
        onTrackDojoItem(
            item,
            true,
            JSON.stringify({
                rating: RateDayEmoji,
            } as RateDayItemStatus),
        )
        onNext()
    }

    const getEmojiUrl = (rating: RateDayEmoji) => EmojiURLsMapping[rating]
    return (
        <Card
            sx={{
                position: 'relative',
                margin: '20px',
                width: '100%',
            }}
        >
            <Stack direction="row" justifyContent="space-between" alignItems={'center'} paddingY={'16px'}>
                {state !== DojoItemState.ACTIVE && (
                    <Typography
                        variant="h1"
                        color={state === DojoItemState.DONE ? palette.text?.disabled : palette.text?.primary}
                        paddingX={'24px'}
                    >
                        {item.title}
                    </Typography>
                )}
                {state === DojoItemState.DONE && rating && (
                    <img src={getEmojiUrl(rating)} width={70} height={50} alt={'map of life overview'} />
                )}
            </Stack>

            {state === DojoItemState.ACTIVE && (
                <>
                    <Typography variant="h2" color={palette.text?.primary} paddingX={'24px'}>
                        Rate your Day:
                    </Typography>
                    <Stack direction="row" justifyContent="center" paddingY={'16px'}>
                        {Object.keys(EmojiURLsMapping).map((emoji) => (
                            <IconButton
                                key={emoji}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    handleRatingChange(emoji as RateDayEmoji)
                                }}
                            >
                                <img
                                    src={getEmojiUrl(emoji as RateDayEmoji)}
                                    width={70}
                                    height={70}
                                    alt={'map of life overview'}
                                />
                            </IconButton>
                        ))}
                    </Stack>

                    {item.id === activeItemID && (
                        <Stack
                            display={'flex'}
                            direction={'row'}
                            justifyContent={'flex-end'}
                            spacing={2}
                            alignItems={'center'}
                            marginTop={2}
                            marginBottom={2}
                            marginRight={2}
                        >
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onNext()
                                }}
                                variant={'contained'}
                            >
                                <Typography variant="h5" color={'white'}>
                                    Next
                                </Typography>
                            </Button>
                            <Button
                                variant={'contained'}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onTrackDojoItem(
                                        item,
                                        true,
                                        JSON.stringify({
                                            rating: rating,
                                        } as RateDayItemStatus),
                                    )
                                    onNext()
                                }}
                            >
                                <Typography variant="h5" color={'white'}>
                                    Done
                                </Typography>
                            </Button>
                        </Stack>
                    )}
                </>
            )}
        </Card>
    )
}

export default RateDayItem
