import useDimensions from 'react-cool-dimensions'

export const SCENE_WIDTH = 1920
export const SCENE_HEIGHT = 1200

export default function useDimensionsForStage() {
    const { width, observe, currentBreakpoint } = useDimensions({
        // The "currentBreakpoint" will be the object key based on the target's width
        // for instance, 0px - 319px (currentBreakpoint = XS), 320px - 479px (currentBreakpoint = SM) and so on
        breakpoints: {
            PHONE_ONLY: 0,
            TABLET_PORTRAIT_UP: 600,
            TABLET_LANDSCAPE_UP: 900,
            DESKTOP_UP: 1200,
            LARGE_DESKTOP_UP: 1800,
        },
        // Will only update the state on breakpoint changed, default is false
        // updateOnBreakpointChange: true,
        // onResize: ({ currentBreakpoint }) => {
        //   // Now the event callback will be triggered when breakpoint is changed
        //   // we can also access the "currentBreakpoint" here
        // },
    })
    let factor = 1
    if (width) factor = width / SCENE_WIDTH

    return { observe, currentBreakpoint, factor }
}
