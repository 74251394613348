import { Box, Button, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { Habit, HabitType, Record } from '../../../../graphql/types'
import { dateToDay, dayToDate } from '../../../../util/functions/dayConversion'
import { BottomBar } from '../../../dashboard/components/BottomBar'
import { BooleanItem } from '../../components/booleanItem/BooleanItem'
import { CounterItem } from '../../components/counterItem/CounterItem'
import { StackItem, StackItemConfig, defaultStackConfig } from '../../components/stackItem/StackItem'
import { TimerItem } from '../../components/timerItem/TimerItem'
import { useTrackingView } from './useTrackingView'

// Icons
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { useSwipeable } from 'react-swipeable'
import { compareHabitActiveDays } from '../../../../functions/habitFunctions'
import { useRootContainer } from '../../../../util/hooks/useRootStack'
import { DojoCard } from '../../../dashboard/components/DojoCard'

export interface HabitComponentProps {
    habit: Habit
    record?: Record | undefined
    active: boolean
    onTrackHabit: (habit: Habit, status: string) => void
    onInfoClick?: (item: Habit) => void
    detailsView?: boolean
}

export function TrackingView() {
    const rootContainer = useRootContainer()

    const useTrackingViewReturn = useTrackingView()
    const { currentDay, habits, records, today, onTrackHabit, onInfoClick, onNavigate } = useTrackingViewReturn
    const swipeHandlers = useSwipeable({
        onSwipedLeft: () => {
            if (currentDay !== today) {
                onNavigate(currentDay + 1)
            }
        },
        onSwipedRight: () => onNavigate(currentDay - 1),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        trackMouse: true,
    })

    const currentHabits = habits.filter(
        (h: Habit) =>
            // start needs to be undefined or lower than currentDay
            (!h.start || dateToDay(new Date(h.start)) < currentDay) &&
            // end needs to be undefined or higher than currentDay
            (!h.end || dateToDay(new Date(h.end)) > currentDay),
    )

    return (
        <Box className="bonsai">
            <BottomBar />
            <Stack
                sx={[...(Array.isArray(rootContainer) ? rootContainer : [rootContainer])]}
                gap={2}
                {...swipeHandlers}
            >
                {(() => {
                    const renderedHabits = currentHabits
                        .map((habit: Habit) => {
                            const record = records
                                .filter(
                                    (record) =>
                                        record.habit === habit.id &&
                                        new Date(record.date).toDateString() === dayToDate(currentDay).toDateString(),
                                )
                                .at(-1)

                            const isActiveDaysMatch = compareHabitActiveDays(currentDay, habit)

                            const active = today - currentDay < 3 && isActiveDaysMatch

                            const props: HabitComponentProps = {
                                habit,
                                record,
                                active,
                                onTrackHabit,
                                onInfoClick,
                            }

                            if (isActiveDaysMatch) {
                                switch (habit.type) {
                                    case HabitType.BOOLEAN:
                                        return <BooleanItem key={habit.id} {...props} />
                                    case HabitType.COUNTER:
                                        return <CounterItem key={habit.id} {...props} />
                                    case HabitType.TIMER:
                                        return <TimerItem key={habit.id} {...props} />
                                    case HabitType.STACK:
                                        const subHabitRecords: Map<string, Record> = new Map()
                                        const config: StackItemConfig = habit.config
                                            ? JSON.parse(habit.config)
                                            : defaultStackConfig
                                        for (const subHabit of config.subHabits) {
                                            const subHabitRecord = records
                                                .filter(
                                                    (record) =>
                                                        record.habit === subHabit.id &&
                                                        new Date(record.date).toDateString() ===
                                                            dayToDate(currentDay).toDateString(),
                                                )
                                                .at(-1)
                                            if (subHabitRecord) subHabitRecords.set(subHabit.id, subHabitRecord)
                                        }
                                        return <StackItem key={habit.id} {...props} subHabitRecords={subHabitRecords} />
                                    default:
                                        return undefined
                                }
                            }

                            return undefined
                        })
                        .filter((item) => item !== undefined)

                    if (renderedHabits.length === 0) {
                        return <DojoCard msg={'No habits to track today'} />
                    }

                    return renderedHabits
                })()}
            </Stack>
            <Stack
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                marginBottom={2}
                paddingBottom={'56px'}
                paddingX={'24px'}
                width={'100%'}
                position={'absolute'}
                bottom={0}
            >
                <Button disabled={currentDay < 1000} onClick={() => onNavigate(currentDay - 1)} variant={'contained'}>
                    <NavigateBeforeIcon />
                </Button>

                <Typography variant="h5" color={'white'}>
                    {format(dayToDate(currentDay), 'dd LLL yyyy')}
                </Typography>

                <Button
                    disabled={currentDay === today}
                    onClick={() => onNavigate(currentDay + 1)}
                    variant={'contained'}
                >
                    <NavigateNextIcon />
                </Button>
            </Stack>
        </Box>
    )
}
