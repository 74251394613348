import { add, differenceInCalendarDays } from 'date-fns'

// in tracking we use the days since 1st jan 2020 to identify the day (=4 chars)
export const dateToDay = (date: Date) => {
    return differenceInCalendarDays(date, new Date(2020, 0, 1, 0, 0))
}

export const dayToDate = (day: number) => {
    return add(new Date(2020, 0, 1, 0, 0, 0), { days: day })
}
