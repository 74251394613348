import { v4 } from 'uuid'

import { AudioPlayerItemConfig } from '../../components/dojo/dojoitems/AudioPlayerItem/AudioPlayerItem'
import { YoutubeVideoItemConfig } from '../../components/dojo/dojoitems/YoutubeVideoItem/YoutubeVideoItem'
import { DojoDisplayType, DojoItem, DojoPurposeType } from '../../graphql/types'

//TODO: add more items
export const Medititional_Video: DojoItem = {
    id: v4(),
    title: 'Eveninig Routine: Video',
    displayType: DojoDisplayType.VIDEO,
    purposeType: DojoPurposeType.ROUTINE_MEDITATION,
    continuous: true,
    config: JSON.stringify({
        youtubeID: 'jp4xdxcc7WU',
    } as YoutubeVideoItemConfig),
}

export const Medititional_Audio: DojoItem = {
    id: v4(),
    title: 'Eveninig Routine: Meditate Audio',
    displayType: DojoDisplayType.AUDIO,
    purposeType: DojoPurposeType.ROUTINE_MEDITATION,
    continuous: true,
    config: JSON.stringify({
        mp3URL: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
    } as AudioPlayerItemConfig),
}

export const testDojoRecord = (dojoItem: DojoItem) => ({
    id: v4(),
    item: dojoItem.id,
    date: new Date(),
    viewed: false,
    status: undefined,
})

export const eveningRoutine: DojoItem[] = [Medititional_Video, Medititional_Audio]
