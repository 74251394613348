import { v4 } from 'uuid'

import { AudioPlayerItemConfig } from '../components/dojo/dojoitems/AudioPlayerItem/AudioPlayerItem'
import { ChoiceItemConfig } from '../components/dojo/dojoitems/ChoiceItem/ChoiceItem'
import { JournalItemConfig } from '../components/dojo/dojoitems/JournalItem/JournalItem'
import { QuoteItemConfig } from '../components/dojo/dojoitems/QuoteCardItem/QuoteCardItem'
import { TextCardItemConfig } from '../components/dojo/dojoitems/TextItem/TextCardItem'
import { YoutubeVideoItemConfig } from '../components/dojo/dojoitems/YoutubeVideoItem/YoutubeVideoItem'
import { DojoDisplayType, DojoItem, DojoPurposeType } from '../graphql/types'
import { eveningRoutine } from './dojoItemsPool/eveningRoutine'
import { morningRoutine } from './dojoItemsPool/morningRoutine'
import { trainingRoutine } from './dojoItemsPool/trainingRoutine'

export const testQuote: DojoItem = {
    id: v4(),
    title: 'Quote',
    purposeType: DojoPurposeType.DOJO_ACTIVE_LISTENING,
    displayType: DojoDisplayType.QUOTE,
    continuous: false,
    config: JSON.stringify({
        text: 'Two things are infinite: the universe and human stupidity; and Im not sure about the universe.',
        author: 'Albert Einstein',
        authorURL:
            'https://images.unsplash.com/photo-1603349206295-dde20617cb6a?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80',
    } as QuoteItemConfig),
}

export const testVideo: DojoItem = {
    id: v4(),
    title: 'Video',
    displayType: DojoDisplayType.VIDEO,
    purposeType: DojoPurposeType.ROUTINE_AFFIRMATION,
    continuous: false,
    config: JSON.stringify({
        youtubeID: 'jp4xdxcc7WU',
    } as YoutubeVideoItemConfig),
}

export const testAudio: DojoItem = {
    id: v4(),
    title: 'Meditate Audio',
    displayType: DojoDisplayType.AUDIO,
    purposeType: DojoPurposeType.ROUTINE_MOOD_CHECK,
    continuous: false,
    config: JSON.stringify({
        mp3URL: 'https://www.soundhelix.com/examples/mp3/SoundHelix-Song-1.mp3',
    } as AudioPlayerItemConfig),
}

export const testTextInput: DojoItem = {
    id: v4(),
    title: 'Daily Journal',
    displayType: DojoDisplayType.TEXT_INPUT,
    purposeType: DojoPurposeType.DOJO_ACTIVE_LISTENING,
    continuous: true,
    config: JSON.stringify({
        prompt: 'Write 5 sentences',
    } as JournalItemConfig),
}

export const testRating: DojoItem = {
    id: v4(),
    title: 'How was your day?',
    displayType: DojoDisplayType.RATING,
    purposeType: DojoPurposeType.ROUTINE_MOOD_CHECK,
    continuous: false,
    config: '',
}
export const testText: DojoItem = {
    id: v4(),
    title: 'Text Card',
    displayType: DojoDisplayType.TEXT,
    purposeType: DojoPurposeType.ROUTINE_READING,
    continuous: false,
    config: JSON.stringify({
        title: 'This is a header title',
        body: 'This is the body of the text This is the body of the text This is the body of the text  This is the body of the text  This is the body of the text ',
    } as TextCardItemConfig),
}

export const testChoice: DojoItem = {
    id: v4(),
    title: 'Alternative Choice Card',
    displayType: DojoDisplayType.CHOICE,
    purposeType: DojoPurposeType.DOJO_ACTIVE_LISTENING,
    continuous: false,
    config: JSON.stringify({
        choices: [
            {
                text: 'Do you prefer pasta or bread? Both are delicious sources of carbs that can provide energy for your body. Carbs are an essential macronutrient that can also be found in fruits, vegetables, and grains. They are important for maintaining healthy brain function and can help regulate your blood sugar levels. So, which carb do you enjoy more, pasta or bread?',
                result: "Did you know that eating carbs can improve your athletic performance? Carbohydrates are your body's primary source of energy and are especially important for high-intensity exercise. They also help your muscles recover after a workout and can reduce the risk of injury. Eating a balanced diet that includes carbs can help you perform better and feel better during physical activity.",
            },
            {
                text: 'Are you a fan of avocado or cheese? Both are sources of fat that can add flavor and texture to your meals. Fat is another essential macronutrient that helps your body absorb vitamins and minerals, maintain healthy skin and hair, and regulate your hormones. While some types of fat can be harmful in excess, incorporating healthy fats into your diet can have numerous health benefits.',
                result: "Incorporating healthy fats into your diet can have numerous benefits for your overall health. Consuming unsaturated fats, like those found in nuts, fish, and olive oil, can help lower your cholesterol levels and reduce your risk of heart disease. Fats can also help you feel full and satisfied after a meal, which can aid in weight management. So, don't be afraid to add some healthy fats to your plate!",
            },
        ],
    } as ChoiceItemConfig),
}

export const testDojoRecord = (dojoItem: DojoItem) => ({
    id: v4(),
    item: dojoItem.id,
    date: new Date().getTime(),
    viewed: false,
    status: undefined,
})

export const testDojoItems: DojoItem[] = [
    // testAudio,
    // testChoice,
    // testTextInput,
    // testQuote,
    // testRating,
    // testText,
    // testVideo,
    ...trainingRoutine,
    ...morningRoutine,
    ...eveningRoutine,
]
