import { Button, Card, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useNavigate } from 'react-router-dom'

export function ErrorItem() {
    const navigate = useNavigate()

    return (
        <Card>
            <Stack direction={'column'} justifyContent={'center'} alignItems={'center'} p={2}>
                <Typography variant={'h5'}>Habit not found</Typography>
                <Button onClick={() => navigate(-1)}>Go back</Button>
            </Stack>
        </Card>
    )
}
