import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import { compareHabitActiveDays } from '../../../../functions/habitFunctions'
import { Habit } from '../../../../graphql/types'
import { dateToDay, dayToDate } from '../../../../util/functions/dayConversion'
import { useRootContainer } from '../../../../util/hooks/useRootStack'
import { BottomBar } from '../../../dashboard/components/BottomBar'
import { OverviewItem } from './OverviewItem'
import { useHabitsOverview } from './useHabitsOverview'

export function HabitsOverview() {
    const {
        monthDates,
        monthName,
        currentDay,
        onDateClick,
        handleNextClick,
        handlePrevClick,
        handleRecordClick,
        habitTableItems,
    } = useHabitsOverview()

    const rootContainer = useRootContainer(true, '0px', '0px', '0px', 65)

    return (
        <Box className="bonsai">
            <BottomBar />
            <Stack sx={[...(Array.isArray(rootContainer) ? rootContainer : [rootContainer])]}>
                <Stack
                    id="scroll-container"
                    sx={{
                        overflowX: 'scroll',
                        scrollSnapType: 'x mandatory',
                        display: 'flex',
                        flexDirection: 'column',
                    }}
                >
                    <Stack
                        sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            position: 'sticky',
                            top: 0,
                            zIndex: 99,
                            opacity: 1,
                        }}
                    >
                        {monthDates.map(({ date }) => (
                            <Stack
                                key={date.toDateString()}
                                sx={{
                                    width: 40,
                                    height: 40,
                                    fontSize: '1rem',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    scrollSnapAlign: 'center',
                                    flexShrink: 0,
                                    opacity: 0.9,
                                    backgroundColor:
                                        date.toDateString() === dayToDate(currentDay).toDateString()
                                            ? 'khaki'
                                            : date.getDay() === 0 || date.getDay() === 6
                                            ? '#f2f2f2'
                                            : 'white',
                                }}
                                onClick={() => onDateClick(date)}
                            >
                                <Typography>{date.toLocaleString('default', { weekday: 'short' })}</Typography>
                                <Typography>{date.getDate()}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                    {habitTableItems.map((item) => {
                        return (
                            <React.Fragment key={item.id}>
                                <Stack
                                    sx={{
                                        direction: 'row',
                                        fontSize: '1.25rem',
                                        backgroundColor: 'gainsboro',
                                        height: 30,
                                        position: 'sticky',
                                        left: 0,
                                        overflow: 'hidden',
                                        paddingLeft: '16px',
                                        paddingTop: '6px',
                                        flexShrink: 0,
                                        opacity: 0.9,
                                    }}
                                >
                                    {item.name}
                                </Stack>
                                <Stack direction={'row'}>
                                    {item.records.map((record, index) => (
                                        <OverviewItem
                                            color={item.color}
                                            active={compareHabitActiveDays(dateToDay(monthDates[index].date), item)}
                                            key={record.id}
                                            record={record}
                                            onClick={() => handleRecordClick(item.id, monthDates[index].date)}
                                            currentDay={currentDay}
                                            itemDate={monthDates[index].date}
                                        />
                                    ))}
                                </Stack>
                            </React.Fragment>
                        )
                    })}
                </Stack>
            </Stack>
            <Stack
                display={'flex'}
                direction={'row'}
                justifyContent={'space-between'}
                alignItems={'center'}
                marginBottom={2}
                paddingBottom={'56px'}
                paddingX={'24px'}
                width={'100%'}
                position={'absolute'}
                bottom={0}
            >
                <Button
                    variant="contained"
                    onClick={() => {
                        handlePrevClick()
                    }}
                >
                    <NavigateBeforeIcon />
                </Button>
                <Typography variant="h5" color={'white'}>
                    {monthName}
                </Typography>
                <Button variant="contained" onClick={handleNextClick}>
                    <NavigateNextIcon />
                </Button>
            </Stack>
        </Box>
    )
}
