import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { useStoreActions } from '../store/hooks'
import { SceneIdentifier, ScenePosition } from '../store/types'

export const useCurrentScene = () => {
    const location = useLocation()
    const setCurrentLocation = useStoreActions((actions) => actions.gameModel.setCurrentLocation)

    useEffect(() => {
        if (location.pathname.includes('/scene/')) {
            const pathParts = location.pathname.split('/scene/')[1].split('/')
            const sceneIdentifier = pathParts[0] as SceneIdentifier
            let scenePosition = pathParts[1] as ScenePosition

            if (!scenePosition || !Object.values(ScenePosition).includes(scenePosition)) {
                scenePosition = ScenePosition.VOID
            }

            if (Object.values(SceneIdentifier).includes(sceneIdentifier)) {
                setCurrentLocation({ scene: sceneIdentifier, position: scenePosition })
            }
        }
    }, [location, setCurrentLocation])
}
