import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import {
    Button,
    Divider,
    FormControl,
    IconButton,
    InputLabel,
    MenuItem,
    Paper,
    Select,
    TextField,
    Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import { CirclePicker, Color } from 'react-color'
import { useNavigate } from 'react-router'
import { DeleteHabitPopup } from '../../../../features/habitTracking/components/DeleteHabitPopup'
import { HabitType } from '../../../../graphql/types'
import { useRootContainer } from '../../../../util/hooks/useRootStack'
import { BottomBar } from '../../../dashboard/components/BottomBar'
import { useEditHabitView } from './useEditHabitView'

export function EditHabitView() {
    const {
        habit,
        addHabit,
        colors,
        habitTypes,
        open,
        habitType,
        disabled,
        deleteHabitPopup,
        handleClosePopup,
        handleCheckboxChange,
        handleColorChange,
        handleTypeChange,
        resetValues,
        setConfig,
        handleDays,
        renderSaveButton,
        selectAllDays,
        setHabitName,
        removeAllDays,
    } = useEditHabitView()
    const rootContainer = useRootContainer()
    const navigate = useNavigate()

    return (
        <Box className="bonsai">
            <BottomBar />
            <Box sx={[...(Array.isArray(rootContainer) ? rootContainer : [rootContainer])]}>
                <Paper elevation={1} sx={{ padding: '16px' }}>
                    <Stack display="flex" direction="row" justifyContent="space-between" sx={{ marginBottom: '16px' }}>
                        <IconButton
                            onClick={() => {
                                resetValues()
                                navigate(-1)
                            }}
                        >
                            <ArrowBackIcon sx={{ fontSize: 40 }} color="primary" />
                        </IconButton>
                        <Typography variant="h5" color={'black'} textAlign={'center'} flex={1} paddingTop={'16px'}>
                            {addHabit ? 'Add Habit' : 'Edit Habit'}
                        </Typography>
                        {!addHabit ? (
                            <IconButton
                                onClick={() => {
                                    deleteHabitPopup()
                                }}
                            >
                                <DeleteForeverIcon sx={{ fontSize: 40 }} color="error" />
                            </IconButton>
                        ) : (
                            <Box sx={{ width: '56px' }} />
                        )}
                    </Stack>
                    <Stack direction="column" justifyContent="center" paddingBottom="16px">
                        <TextField
                            sx={{
                                width: '100%',
                            }}
                            label="Name"
                            defaultValue={habit.name}
                            variant="outlined"
                            onChange={(event) => setHabitName(event.target.value)}
                        />
                    </Stack>
                    <Divider />
                    <Typography variant="h6">Active Days</Typography>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginTop: '10px' }}
                    >
                        {handleDays().map((day) => (
                            <Box
                                key={day.dayOfWeek}
                                sx={{
                                    width: '40px',
                                    height: '40px',
                                    border: '1px solid #ccc',
                                    borderRadius: '5px',
                                    display: 'inline-flex',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginRight: '5px',
                                    backgroundColor: day.isActive ? '#d7ccc8' : 'white',
                                    cursor: 'pointer',
                                }}
                                onClick={() => handleCheckboxChange(day.dayOfWeek + 1)}
                            >
                                {day.formattedDay}
                            </Box>
                        ))}
                    </Stack>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        sx={{ marginTop: '10px' }}
                    >
                        <Button variant="contained" onClick={removeAllDays}>
                            Remove All
                        </Button>
                        <Button variant="contained" onClick={selectAllDays}>
                            Select All
                        </Button>
                    </Stack>
                    <Divider sx={{ marginTop: '16px' }} />
                    <Stack sx={{ display: 'flex', justifyContent: 'space-between', direction: 'column' }}>
                        <Typography variant="h6" sx={{ marginBottom: '10px' }}>
                            Color
                        </Typography>
                        <CirclePicker
                            width="100%"
                            color={habit.color as Color}
                            colors={colors}
                            onChange={handleColorChange}
                        />
                    </Stack>
                    <Divider sx={{ marginTop: '16px' }} />
                    <FormControl fullWidth sx={{ marginY: '16px' }}>
                        <InputLabel id="habit-type-label">Habit Type</InputLabel>
                        <Select
                            labelId="habit-type-label"
                            value={habitType}
                            onChange={handleTypeChange}
                            label="Habit Type"
                        >
                            {habitTypes.map((type) => (
                                <MenuItem key={type} value={type}>
                                    {type}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                    <Stack direction="column" justifyContent="center">
                        {habitType === HabitType.COUNTER && (
                            <TextField
                                sx={{
                                    width: '100%',
                                }}
                                inputProps={{
                                    type: 'number',
                                }}
                                label="Day Target"
                                defaultValue={JSON.parse(habit?.config || '{}').target}
                                variant="outlined"
                                onChange={(event) => setConfig(event.target.value)}
                            />
                        )}

                        {habitType === HabitType.TIMER && (
                            <TextField
                                sx={{
                                    width: '100%',
                                }}
                                label="Target Duration"
                                defaultValue={JSON.parse(habit?.config || '{}').target}
                                variant="outlined"
                                inputProps={{
                                    type: 'number',
                                }}
                                onChange={(event) => setConfig(event.target.value)}
                            />
                        )}
                    </Stack>
                    <Stack direction="row" justifyContent="center">
                        <Button
                            disabled={disabled}
                            variant="contained"
                            sx={{
                                width: '100%',
                                marginTop: '16px',
                            }}
                            onClick={renderSaveButton().onClick}
                        >
                            {renderSaveButton().label}
                        </Button>
                    </Stack>
                </Paper>
                {habit && <DeleteHabitPopup habit={habit} open={open} onClose={handleClosePopup} />}
            </Box>
        </Box>
    )
}
