// Icons

import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore'
import NavigateNextIcon from '@mui/icons-material/NavigateNext'
import { Box, Button, Container, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import AudioPlayerItem from '../../../../components/dojo/dojoitems/AudioPlayerItem/AudioPlayerItem'
import ChoiceItem from '../../../../components/dojo/dojoitems/ChoiceItem/ChoiceItem'
import JournalItem from '../../../../components/dojo/dojoitems/JournalItem/JournalItem'
import QuoteCardItem from '../../../../components/dojo/dojoitems/QuoteCardItem/QuoteCardItem'
import RateDayItem from '../../../../components/dojo/dojoitems/RateDayItem/RateDayItem'
import TextCardItem from '../../../../components/dojo/dojoitems/TextItem/TextCardItem'
import YoutubeVideoItem from '../../../../components/dojo/dojoitems/YoutubeVideoItem/YoutubeVideoItem'
import { DojoDisplayType, DojoItem, DojoItemRecord } from '../../../../graphql/types'
import { dayToDate } from '../../../../util/functions/dayConversion'
import { useDojoView } from './useDojoView'
export interface DojoItemComponentProps {
    item: DojoItem
    record: DojoItemRecord
    activeItemID: string
    onTrackDojoItem: (item: DojoItem, viewed: boolean, status: string) => void
    onNext: () => void
}

export enum DojoItemState {
    NEW,
    ACTIVE,
    DONE,
}

export function DojoView() {
    const useDojoViewReturn = useDojoView()
    const { setActiveItemID, listItems, today, onNavigate, currentDay } = useDojoViewReturn

    return (
        <Box
            className="bonsai"
            sx={{
                overflow: 'auto',
            }}
        >
            <Container maxWidth={'md'} sx={{ paddingLeft: 0, paddingRight: 0 }}>
                <Stack
                    display={'flex'}
                    direction={'row'}
                    justifyContent={'flex-start'}
                    alignItems={'center'}
                    spacing={2}
                    paddingY={2}
                >
                    <Typography variant="h5" color={'white'}>
                        {format(dayToDate(currentDay), 'dd LLL yyyy')}
                    </Typography>
                    <Button
                        disabled={currentDay < 1000}
                        onClick={() => onNavigate(currentDay - 1)}
                        variant={'contained'}
                    >
                        <NavigateBeforeIcon />
                    </Button>

                    <Button
                        disabled={currentDay === today}
                        onClick={() => onNavigate(currentDay + 1)}
                        variant={'contained'}
                    >
                        <NavigateNextIcon />
                    </Button>
                </Stack>

                {listItems.map(([item, record]) => (
                    <Box
                        key={record.id}
                        onClick={(e) => {
                            e.preventDefault()
                            setActiveItemID(item.id)
                        }}
                    >
                        {item.displayType === DojoDisplayType.QUOTE && (
                            <QuoteCardItem {...useDojoViewReturn} item={item} record={record} />
                        )}
                        {item.displayType === DojoDisplayType.VIDEO && (
                            <YoutubeVideoItem {...useDojoViewReturn} item={item} record={record} />
                        )}
                        {item.displayType === DojoDisplayType.AUDIO && (
                            <AudioPlayerItem {...useDojoViewReturn} item={item} record={record} />
                        )}
                        {item.displayType === DojoDisplayType.TEXT_INPUT && (
                            <JournalItem {...useDojoViewReturn} item={item} record={record} />
                        )}
                        {item.displayType === DojoDisplayType.RATING && (
                            <RateDayItem {...useDojoViewReturn} item={item} record={record} />
                        )}
                        {item.displayType === DojoDisplayType.TEXT && (
                            <TextCardItem {...useDojoViewReturn} item={item} record={record} />
                        )}
                        {item.displayType === DojoDisplayType.CHOICE && (
                            <ChoiceItem {...useDojoViewReturn} item={item} record={record} />
                        )}
                    </Box>
                ))}
            </Container>
        </Box>
    )
}
