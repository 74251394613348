import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import { IconButton, Paper, Stack, Typography } from '@mui/material'
import { useState } from 'react'
import { useNavigate } from 'react-router'
import { Habit } from '../../../../graphql/types'
import Routes from '../../../../util/routes'
import { DeleteHabitPopup } from '../DeleteHabitPopup'

export type HabitListItemProps = {
    habit: Habit
}

export function HabitListItem({ habit }: HabitListItemProps) {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false)

    const onClick = (habit: Habit) => {
        navigate(Routes.tracking.EDIT_HABIT.replace(':habitID', habit.id))
    }

    return (
        <Stack direction="row" alignItems={'center'}>
            <Paper
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    height: '40px',
                    flexGrow: 1,
                    background: '#f8f8f8',
                    backgroundColor: '#eee',
                    padding: '4px 4px 4px 16px',
                    userSelect: 'none',
                    '&:hover': {
                        backgroundColor: 'lightgray',
                        userSelect: 'none',
                        transition: 'background 250ms',
                        cursor: 'pointer',
                    },
                }}
                elevation={1}
            >
                <Stack
                    direction="row"
                    alignItems={'center'}
                    onClick={() => onClick(habit)}
                    sx={{ flexGrow: 1, height: '100%' }}
                >
                    <Typography color={'black'}>{habit.name}</Typography>
                </Stack>
                <IconButton onClick={() => setOpen(true)}>
                    <DeleteForeverIcon />
                </IconButton>
            </Paper>

            <DeleteHabitPopup habit={habit} open={open} onClose={() => setOpen(false)} />
        </Stack>
    )
}
