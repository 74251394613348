// ----------------------------------------------------- content

import { SceneIdentifier } from '../../../store/types'

export enum ContentType {
    TEXT = 'TEXT',
    VIDEO = 'VIDEO',
    SLIDES = 'SLIDES',
}

export enum CourseVideoType {
    BUNNY = 'BUNNY',
    YOUTUBE = 'YOUTUBE',
    EXTERNAL = 'EXTERNAL',
}

export type VideoData = {
    type: CourseVideoType
    url?: string
    videoId?: string
    thumbnailUrl?: string
}

export type Content = {
    id: string
    title: string
    type: ContentType
    textContent?: string
    videoContent?: VideoData
    // lengthSeconds: number
    // slide data
    // significantChange: string // a timestamp for something that could/should be looked at again
}

export type ContentBoard = {
    id: string
    sceneItems?: SceneItem[] // we can have decoration too
    items: BoardItem[]
}

export type BoardItem = {
    contentId: string
    description?: string
    x: number
    y: number
    varChanges?: VariableChange[] // what changes should be made if this dialog choice is selected
    mods?: ConditionalModifier[]
}

// ----------------------------------------------------- variables

export enum VariableChangeType {
    SET = 'SET', // set the variable to the value, so value is required
    INCREASE = 'INC', // simpyl increases the variable by 1
    ADD = 'ADD', // adds the value to the variable
    SUBTRACT = 'SUB', // subtract the value from the variable
    RESET = 'RST', // reset the variable to 0
    DECREASE = 'DEC', // decrease the variable by 1
    DELETE = 'DEL', // delete the variable as if it had never existed
    TOGGLE = 'TOG', // toggle the variable between 0 and 1
}

export type VariableChange = {
    var: string
    action: VariableChangeType
    value?: number
}

// ----------------------------------------------------- modifiers / conditions

export enum ConditionType {
    EXISTS = 'EX', // true if not undefined
    NOT_EXISTS = 'NX', // true if undefined, any value is false
    GREATER_THAN = 'GT',
    LESS_THAN = 'LT',
    GREATER_OR_EQUAL = 'GE',
    LESS_OR_EQUAL = 'LE',
    EQUALS = 'EQ',
    NOT_EQUALS = 'NE',
}

export enum Modifier {
    VISIBLE = 1,
    HIDDEN = 2,
    DISABLED = 3,
    COMPLETED = 4,
}

export type ConditionalModifier = {
    var: string
    type: ConditionType
    value?: number
    modifier: Modifier
}

// ----------------------------------------------------- dialogs

export type Dialog = {
    id: string
    fallback: string // the id of the fallback dialog entry
    preferences: DialogPreference[]
    dialogScenes: DialogScene[]
}

export type DialogChoice = {
    text: string
    link: Link
    varChanges?: VariableChange[] // what changes should be made to variables if this dialog choice is selected
    className?: string
    dmod?: Modifier
    mods?: ConditionalModifier[]
}

export type DialogScene = {
    id: string
    text: string
    choices?: DialogChoice[]
    dmod?: Modifier
    mods?: ConditionalModifier[]
    viewpointId?: string
}

export type DialogPreference = {
    dialogId: string
    mods: ConditionalModifier[]
}

// ----------------------------------------------------- scene

export type Placement = {
    x: number // x,y,z position
    y: number
    z?: number
    w?: number // width and height
    h?: number
    ry?: number // rotation around y axis
    scale?: number
}

export type Scene = {
    id: SceneIdentifier // id comes from an enum of scenes
    title?: string
    backLink?: Link // if this is specified, there will be a "back" link and this defines where that goes
    viewpoints: SceneViewpoint[] // an array of all the viewpoints that exist in this scene
    dialogs?: Dialog[] // all the dialog definitions
    items?: SceneItem[] // all the items, which can be graphics
    mods?: ConditionalModifier[]
    dmod?: Modifier
}

export type ItemInfo = {
    title: string
    description?: string
    imageURL?: string
    buttonLabel: string
}

export enum SceneItemType {
    BOARD = 'b',
    GRAPHIC = 'g',
    LABEL = 'l',
    AREA = 'a',
    DIALOG = 'd',
}

export enum LinkType {
    SCENE = 's',
    VIEWPOINT = 'v',
    BOARD = 'b',
    DIALOG_SCENE = 'd',
    OVERLAY = 'o',
    // DIALOG_SCENE_AND_VIEWPOINT = 'dv',
}

export type Link = {
    id: string
    type: LinkType
    label?: string
}

export type SceneItem = {
    id: string
    type: SceneItemType
    placement: Placement
    title?: string
    clickAway?: boolean
    flat?: boolean // defaults to false - flat means the viewpoint rotation is counterbalanced for this item
    imageURL?: string
    link?: Link
    info?: ItemInfo
    boardId?: string
    className?: string // optional class name to style the item (blur, size etc)
    mods?: ConditionalModifier[] // modifiers that determine whether something is hidden or not
    varChanges?: VariableChange[] // what changes should be made if this area is clicked
}

export type SceneViewpoint = {
    id: string
    hide?: string[] // an array of all the ids of items to hide
    ry?: number
    scale?: number
    tx?: number
    ty?: number
    backLink?: Link
    dialogPlacement?: Placement
    triggerDialog?: string
    closeDialog?: boolean
}

export enum DojoItemType {
    CARD,
    VIDEO,
    ALT_CARDS,
    AUDIO,
    QUOTE,
    ENTER_AND_COMPARE,
}

export interface DojoItemBase {
    id: string
    estimatedTime: number
    layout?: string
    style?: string
    headerTitle?: string
    backgroundImageURL?: string
}

export interface CardDojoItem extends DojoItemBase {
    type: DojoItemType.CARD
    data: {
        title: string
        body: string
        imgURL?: string
    }
}

export interface AltCardsDojoItem extends DojoItemBase {
    type: DojoItemType.ALT_CARDS
    data: {
        text1: string
        text2: string
        result1: string
        result2: string
    }
}

export interface EnterAndCompareDojoItem extends DojoItemBase {
    type: DojoItemType.ENTER_AND_COMPARE
    data: {
        title: string
        introText: string
        inputTitle: string
        comparison: string
    }
}

export interface VideoDojoItem extends DojoItemBase {
    type: DojoItemType.VIDEO
    data: {
        ytID: string
    }
}

export type DojoItem = CardDojoItem | AltCardsDojoItem | EnterAndCompareDojoItem

/*

type GroupAppointment = {
  date: string
  type: CourseSectionType
}

type GroupMember = {
  id: string
  name: string
  avatar: string
}

type Group = {
  id: String
  name: String
  appointments: GroupAppointment[]
  members: GroupMember[]
}
*/

export enum OverlayType {
    MESSAGING = 'chat',
    MEETING = 'meet',
    PROFILE = 'profile',
}

export type VariableRepository = {
    [key: string]: number
}
