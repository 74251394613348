import { VariableRepository } from '../../features/gameOfLife/data/types'
import { useStoreState } from '../../store/hooks'
import {
    BoardItem,
    ConditionType,
    ConditionalModifier,
    Content,
    Dialog,
    DialogChoice,
    Modifier,
    Scene,
    SceneItem,
} from '../../store/types'

export default function useGameStateVarRepo() {
    const vars = useStoreState((state) => state.gameModel.variables)
    return {
        getItemModifier: (item: SceneItem) => getModifiers(item.mods ? item.mods : [], vars),
        getDialogChoiceModifier: (choice: DialogChoice) =>
            getModifiers(choice.mods ? choice.mods : [], vars, choice.dmod),
        getBoardItemModifier: (item: BoardItem) => getModifiers(item.mods ? item.mods : [], vars),
        getSceneModifier: (scene: Scene) => getSceneModifier(scene, vars),
        findStartDialogSceneId: (dialog: Dialog) => findStartDialogSceneId(dialog, vars),
        isContentFinished: (content: Content) => {
            const contentModifier = getContentModifier(content, vars)
            return contentModifier === Modifier.COMPLETED
        },
    }
}

// getSceneModifier -> normal, hidden
export const findStartDialogSceneId = (dialog: Dialog, variableRepository: VariableRepository): string => {
    let ret = dialog.fallback
    dialog.preferences.find((pref) => {
        // the default is that a scene is hidden, unless it is explicitly visible through conditions
        const mod = getModifiers(pref.mods, variableRepository, Modifier.HIDDEN)
        if (mod === Modifier.VISIBLE) {
            ret = pref.dialogId
            return true
        }
    })
    return ret
}

// getSceneModifier -> normal, hidden
export const getSceneModifier = (scene: Scene, variableRepository: VariableRepository): Modifier => {
    let modifier: Modifier = Modifier.VISIBLE
    if (scene.dmod) modifier = scene.dmod
    const val = variableRepository[scene.id]
    if (val !== undefined) modifier = val
    return modifier
}

export const getModifiers = (mods: ConditionalModifier[], vr: VariableRepository, dmod?: Modifier): Modifier => {
    let modifier: Modifier = dmod ? dmod : Modifier.VISIBLE
    if (mods) {
        mods.forEach((mod) => {
            if (
                (mod.type === ConditionType.EQUALS && mod.value !== undefined && vr[mod.var] === mod.value) ||
                (mod.type === ConditionType.EXISTS && vr[mod.var] !== undefined) ||
                (mod.type === ConditionType.GREATER_OR_EQUAL && mod.value !== undefined && vr[mod.var] >= mod.value) ||
                (mod.type === ConditionType.GREATER_THAN && mod.value !== undefined && vr[mod.var] > mod.value) ||
                (mod.type === ConditionType.LESS_OR_EQUAL && mod.value !== undefined && mod.value >= vr[mod.var]) ||
                (mod.type === ConditionType.LESS_THAN && mod.value !== undefined && mod.value > vr[mod.var]) ||
                (mod.type === ConditionType.NOT_EXISTS && vr[mod.var] === undefined) ||
                (mod.type === ConditionType.NOT_EQUALS && mod.value !== undefined && vr[mod.var] !== mod.value)
            ) {
                modifier = mod.modifier
            }
        })
    }
    return modifier
}

// getContentModifier -> normal, completed
export const getContentModifier = (content: Content, variableRepository: VariableRepository): Modifier => {
    let modifier: Modifier = Modifier.VISIBLE
    const val = variableRepository[content.id]
    if (val !== undefined) modifier = val
    return modifier
}
