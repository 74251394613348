import useGameStateVarRepo from '../../../util/hooks/useVariableRepository'
import { DialogChoice, DialogScene, Link, LinkType, Modifier, VariableChange } from '../data/types'
import './InteractiveScene.css'

type AnswerProps = {
    dialogScene: DialogScene
    //  viewPoint: SceneViewpoint
    executeLink: (link: Link, varChanges?: VariableChange[]) => void
}

const AnswerPanel = (props: AnswerProps): JSX.Element | null => {
    const varRepo = useGameStateVarRepo()

    const { dialogScene, executeLink } = props

    return (
        <div className="answerPanel">
            {dialogScene.choices?.map((choice: DialogChoice) => {
                let modifier: Modifier = Modifier.VISIBLE
                if (choice.dmod) modifier = choice.dmod
                modifier = varRepo.getDialogChoiceModifier(choice)
                if (modifier === Modifier.HIDDEN) return null
                let modifierClass = ''
                if (modifier === Modifier.DISABLED) modifierClass = 'disabled'
                if (modifier === Modifier.COMPLETED) modifierClass = 'completed'
                return (
                    <div
                        key={choice.text}
                        className={`${
                            choice.link.type === LinkType.SCENE ? 'choiceScene' : 'choice'
                        } ${modifierClass} ${choice.className}`}
                        onClick={() => {
                            if (modifier === Modifier.DISABLED) return
                            executeLink(choice.link, choice.varChanges)
                            // TODO: move  history.push(NavigationRoutes.INTERACTIVE_SCENE.replace(':id', choice.link))
                        }}
                    >
                        {choice.text}
                    </div>
                )
            })}
        </div>
    )
}

export default AnswerPanel
