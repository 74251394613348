import { HabitTableItem } from '../features/habitTracking/views/habitsOverview/useHabitsOverview'
import { Habit } from '../graphql/types'
import { dayToDate } from '../util/functions/dayConversion'

export const compareHabitActiveDays = (currentDay: number, item: Habit | HabitTableItem): boolean => {
    const habitActiveDays = item?.activeDays || []
    let currentDayOfWeek = dayToDate(currentDay).getDay()
    currentDayOfWeek = currentDayOfWeek === 0 ? 7 : currentDayOfWeek
    return habitActiveDays.includes(currentDayOfWeek)
}
