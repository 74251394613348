import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import AssignmentTurnedInIcon from '@mui/icons-material/AssignmentTurnedIn'
import BorderAllIcon from '@mui/icons-material/BorderAll'
import ChatIcon from '@mui/icons-material/Chat'
import MapIcon from '@mui/icons-material/Map'
import MenuIcon from '@mui/icons-material/Menu'
import TodayIcon from '@mui/icons-material/Today'
import { Box, IconButton } from '@mui/material'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import { OverlayType } from '../features/gameOfLife/data/types'
import { useStoreActions } from '../store/hooks'
import { default as Routes } from '../util/routes'

const Navigation = (): JSX.Element => {
    const setActiveOverlay = useStoreActions((actions) => actions.gameModel.setActiveOverlay)
    const [open, setOpen] = React.useState(false)

    const navigate = useNavigate()

    return (
        <>
            {open && (
                <Box
                    className={'overlayBg'}
                    onClick={() => {
                        setOpen(false)
                    }}
                ></Box>
            )}
            <Box className={'mainNavigation'}>
                <Box className={'iconContainer'}>
                    <IconButton
                        onClick={() => {
                            setOpen(!open)
                        }}
                    >
                        <MenuIcon />
                    </IconButton>
                    {open && (
                        <Box className={'drawer'}>
                            <ul>
                                <li
                                    onClick={() => {
                                        setOpen(false)
                                        navigate(Routes.tracking.DOJOLOG)
                                    }}
                                >
                                    <BorderAllIcon />
                                    Dojo
                                </li>
                                <li
                                    onClick={() => {
                                        setOpen(false)
                                        navigate(Routes.tracking.TRACKING)
                                    }}
                                >
                                    <AssignmentTurnedInIcon />
                                    Tracking
                                </li>{' '}
                                <li
                                    onClick={() => {
                                        setOpen(false)
                                        navigate(Routes.game.MAP)
                                    }}
                                >
                                    <MapIcon />
                                    Karte
                                </li>{' '}
                                <li
                                    onClick={() => {
                                        setOpen(false)
                                        setActiveOverlay(OverlayType.MESSAGING)
                                    }}
                                >
                                    <ChatIcon />
                                    Messaging
                                </li>{' '}
                                <li
                                    onClick={() => {
                                        setOpen(false)
                                        setActiveOverlay(OverlayType.MEETING)
                                    }}
                                >
                                    <TodayIcon />
                                    Treffen
                                </li>{' '}
                                <li
                                    onClick={() => {
                                        setOpen(false)
                                        setActiveOverlay(OverlayType.PROFILE)
                                    }}
                                >
                                    <AccountCircleIcon />
                                    Profil
                                </li>
                                {/* <li
                                    onClick={() => {
                                        setOpen(false)
                                        navigate(NavigationRoutes.game.STARTSCREEN)
                                    }}
                                >
                                    <WebIcon />
                                    StartPage
                                </li> */}
                            </ul>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    )
}

export default Navigation
