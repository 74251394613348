import { action, Action, Thunk, thunk } from 'easy-peasy'
import { User } from '../../graphql/types'
import { testUsers } from '../../testData/users'

export type UserModel = {
    user?: User
    setUser: Action<UserModel, User>
    loadUser: Thunk<UserModel>
}

const userModel: UserModel = {
    user: undefined,

    setUser: action((state, newUser) => {
        state.user = newUser
    }),

    loadUser: thunk(async (actions) => {
        actions.setUser(testUsers[0])
    }),
}

export default userModel
