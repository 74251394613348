import { SelectChangeEvent } from '@mui/material'
import { useEffect, useState } from 'react'
import { ColorChangeHandler, ColorResult } from 'react-color'
import { useNavigate, useParams } from 'react-router-dom'
import { v4 } from 'uuid'
import { Habit, HabitType } from '../../../../graphql/types'
import { useStoreActions, useStoreState } from '../../../../store/hooks'

export type EditHabitViewParams = {
    habitID: string
}

export interface EditHabitViewReturn {
    habit: Habit
    habitTypes: HabitType[]
    open: boolean
    addHabit: boolean
    disabled: boolean
    habitType: HabitType
    activeDays: Habit['activeDays']
    colors: string[]
    handleCheckboxChange: (day: number) => void
    handleTypeChange: (event: SelectChangeEvent<HabitType>) => void
    resetValues: () => void
    setConfig: (config: string) => void
    setHabitName: (name: string) => void
    deleteHabitPopup: () => void
    handleClosePopup: () => void
    handleDays: () => { dayOfWeek: number; formattedDay: string; isActive: boolean }[]
    renderSaveButton: () => { label: string; onClick: () => void }
    selectAllDays: () => void
    removeAllDays: () => void
    handleColorChange: ColorChangeHandler
}

export const useEditHabitView = (): EditHabitViewReturn => {
    const habitID = useParams<EditHabitViewParams>().habitID
    const habit = useStoreState((state) => state.trackingModel.habits).find((h) => h.id === habitID)
    const updateHabit = useStoreActions((state) => state.trackingModel.updateHabit)
    const addHabitAction = useStoreActions((state) => state.trackingModel.addHabit)
    const [habitName, setHabitName] = useState<string>(habit?.name || '')
    const [open, setOpen] = useState(false)
    const [addHabit, setAddHabit] = useState<boolean>(false)
    const [newHabitID, setNewHabitID] = useState<string>('')
    const [activeDays, setActiveDays] = useState<Habit['activeDays']>(habit?.activeDays || [])
    const [config, setConfig] = useState<string>(habit?.config || '')
    const [habitType, setHabitType] = useState<HabitType>(habit?.type || HabitType.BOOLEAN)
    const [color, setColor] = useState<string>(habit?.color || '#8BC34A')
    const habitTypes = (() => {
        const h = Object.values(HabitType)
        return [...h.slice(0, 2), ...h.slice(3)]
    })()
    const navigate = useNavigate()

    const renderSaveButton = () => {
        if (addHabit) {
            return {
                label: 'Add Habit',
                onClick: () => {
                    handleAdd()
                    resetValues()
                    navigate(-1)
                },
            }
        } else {
            return {
                label: 'Update Habit',
                onClick: () => {
                    handleUpdate()
                    resetValues()
                    navigate(-1)
                },
            }
        }
    }

    const colors = [
        '#e91e63',
        '#9c27b0',
        '#673ab7',
        '#2196f3',
        '#00bcd4',
        '#009688',
        '#4caf50',
        '#8bc34a',
        '#cddc39',
        '#ffeb3b',
        '#ffc107',
        '#ff5722',
        '#795548',
        '#607d8b',
    ]

    const handleCheckboxChange = (day: number) => {
        if (activeDays?.includes(day)) {
            setActiveDays(activeDays.filter((activeDay) => activeDay !== day))
        } else {
            setActiveDays([...(activeDays ?? []), day].sort((a, b) => (a ?? 0) - (b ?? 0)))
        }
    }

    const selectAllDays = () => {
        setActiveDays([1, 2, 3, 4, 5, 6, 7])
    }

    const removeAllDays = () => {
        setActiveDays([])
    }

    const handleColorChange: ColorChangeHandler = (color: ColorResult) => {
        setColor(color.hex)
    }

    const getDayByOffset = (d: Date, offset: number) => {
        const date = new Date(d)
        const day = date.getDay()
        const diff = date.getDate() - day + (day === 0 ? offset - 6 : offset + 1)
        return new Date(date.setDate(diff))
    }

    const handleDays = () => {
        return [0, 1, 2, 3, 4, 5, 6].map((dayOfWeek) => {
            const date = getDayByOffset(new Date(), dayOfWeek)
            const formattedDay = date.toLocaleString('default', { weekday: 'short' })

            const isActive = activeDays?.includes(dayOfWeek + 1) || false

            return {
                dayOfWeek,
                formattedDay,
                isActive,
            }
        })
    }

    useEffect(() => {
        if (!habitID && !addHabit) {
            setAddHabit(true)
        }
    }, [])

    useEffect(() => {
        if (addHabit) {
            setNewHabitID(v4())
            setHabitName('')
            setActiveDays([])
            setHabitType(HabitType.BOOLEAN)
            setConfig('')
        }
    }, [addHabit])

    const handleTypeChange = (event: SelectChangeEvent<HabitType>) => {
        setHabitType(event.target.value as HabitType)
    }

    const deleteHabitPopup = () => {
        setOpen(true)
    }
    const handleClosePopup = () => {
        if (habitID === habit?.id) {
            navigate(-1)
        }
        setOpen(false)
    }

    const resetValues = () => {
        setHabitName('')
        setActiveDays([])
        setHabitType(HabitType.BOOLEAN)
        setConfig('')
    }

    const handleUpdate = () => {
        const currentConfig = habit?.config || ''
        let updatedConfig = config

        if (config !== currentConfig && habitType !== HabitType.BOOLEAN) {
            updatedConfig = JSON.stringify({ target: config })
        } else if (habitType === HabitType.BOOLEAN) {
            updatedConfig = ''
        }

        const newHabit = {
            id: habitID || '',
            name: habitName,
            type: habitType,
            config: updatedConfig,
            obstacles: habit?.obstacles || [],
            activeDays: activeDays,
            owner: '',
            color: color,
        }

        updateHabit(newHabit)
    }

    const handleAdd = () => {
        const newHabit = {
            id: newHabitID,
            name: habitName,
            type: habitType,
            config: JSON.stringify({ target: config }),
            obstacles: [],
            activeDays: activeDays,
            owner: '',
            color: color,
        }
        addHabitAction(newHabit)
    }

    const disabled =
        habitName === '' || !activeDays || activeDays.length === 0 || (habitType !== HabitType.BOOLEAN && config === '')

    return {
        habit: habit
            ? habit
            : {
                  id: v4(),
                  name: '',
                  description: '',
                  type: HabitType.BOOLEAN,
                  owner: '',
                  obstacles: [],
                  color: '#8BC34A',
              },

        addHabit,
        colors,
        habitTypes,
        open,
        habitType,
        activeDays,
        disabled,
        deleteHabitPopup,
        handleClosePopup,
        handleCheckboxChange,
        handleTypeChange,
        resetValues,
        setConfig,
        handleDays,
        renderSaveButton,
        selectAllDays,
        setHabitName,
        removeAllDays,
        handleColorChange,
    }
}
