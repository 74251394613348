import { Box, Button, Card, Stack, Typography } from '@mui/material'
import { DojoItemComponentProps, DojoItemState } from '../../../../features/habitTracking/views/dojoView/DojoView'
import { palette } from '../../../../theme/palette'

export type YoutubeVideoItemProps = DojoItemComponentProps

export interface YoutubeVideoItemConfig {
    youtubeID: string
}

export const defaultYoutubeVideoItemConfig: YoutubeVideoItemConfig = {
    youtubeID: '',
}

export interface YoutubeVideoItemStatus {}

export const defaultYoutubeVideoItemStatus: YoutubeVideoItemStatus = {}

const YoutubeVideoItem = (props: YoutubeVideoItemProps): JSX.Element => {
    const { item, record, activeItemID, onTrackDojoItem, onNext } = props
    const config: YoutubeVideoItemConfig = JSON.parse(item.config)
    // const status: YoutubeVideoItemStatus = record.status ? JSON.parse(record.status) : defaultYoutubeVideoItemStatus
    const { youtubeID } = config
    const state: DojoItemState =
        activeItemID === item.id ? DojoItemState.ACTIVE : record.viewed ? DojoItemState.DONE : DojoItemState.NEW

    return (
        <Card
            sx={{
                position: 'relative',
                margin: '20px',
                width: '100%',
            }}
        >
            {state !== DojoItemState.ACTIVE && (
                <Typography
                    variant="h1"
                    color={state === DojoItemState.DONE ? palette.text?.disabled : palette.text?.primary}
                    paddingX={'24px'}
                    paddingY={'16px'}
                >
                    {item.title}
                </Typography>
            )}
            {state === DojoItemState.ACTIVE && (
                <>
                    <Box
                        sx={{
                            overflow: 'hidden',
                            paddingBottom: '56.25%', // https://www.w3schools.com/howto/howto_css_responsive_iframes.asp
                            position: 'relative',
                            height: 0,
                        }}
                    >
                        <iframe
                            width="853"
                            height="480"
                            src={`https://www.youtube.com/embed/${youtubeID}`}
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                            title="Embedded youtube"
                            style={{
                                left: 0,
                                top: 0,
                                height: '100%',
                                width: '100%',
                                position: 'absolute',
                            }}
                        />
                    </Box>
                    <Stack
                        display={'flex'}
                        direction={'row'}
                        justifyContent={'flex-end'}
                        spacing={2}
                        alignItems={'center'}
                        marginTop={2}
                        marginBottom={2}
                        marginRight={2}
                    >
                        <Button
                            onClick={(e) => {
                                e.stopPropagation()
                                onNext()
                            }}
                            variant={'contained'}
                        >
                            <Typography variant="h5" color={'white'}>
                                Next
                            </Typography>
                        </Button>
                        <Button
                            variant={'contained'}
                            onClick={(e) => {
                                e.stopPropagation()
                                onTrackDojoItem(item, true, JSON.stringify({} as YoutubeVideoItemStatus))
                                onNext()
                            }}
                        >
                            <Typography variant="h5" color={'white'}>
                                Done
                            </Typography>
                        </Button>
                    </Stack>
                </>
            )}
        </Card>
    )
}

export default YoutubeVideoItem
