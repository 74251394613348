import AddIcon from '@mui/icons-material/Add'
import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import ControlPointIcon from '@mui/icons-material/ControlPoint'
import InfoIcon from '@mui/icons-material/Info'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import RemoveIcon from '@mui/icons-material/Remove'
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline'
import { Box, Button, IconButton, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useReward } from 'react-rewards'
import { useLongPress } from 'use-long-press'
import { HabitComponentProps } from '../../views/trackingView/TrackingView'
import StatusProgress from '../statusProgress/StatusProgress'

export type CounterItemProps = HabitComponentProps

export interface CounterItemConfig {
    target: number
}

export const defaultCounterItemConfig: CounterItemConfig = {
    target: 0,
}

export interface CounterItemStatus {
    value: number
}

export const defaultCounterItemStatus: CounterItemStatus = {
    value: 0,
}

export function CounterItem(props: CounterItemProps) {
    const { habit, record, onTrackHabit, onInfoClick, active } = props
    const config: CounterItemConfig = habit.config ? JSON.parse(habit.config) : defaultCounterItemConfig
    const status: CounterItemStatus = record ? JSON.parse(record.status) : defaultCounterItemStatus

    const checked = status.value >= config.target
    const { reward } = useReward('rewardId', 'confetti')

    const changeValue = (change: number) => {
        if (active) {
            onTrackHabit(habit, JSON.stringify({ value: Math.max(status.value + change, 0) } as CounterItemStatus))
            if (change > 0 && status.value >= config.target - 1 && status.value - change < config.target - 1) reward()
        }
    }

    // I was just fiddling around with adding while being pressed, didnt quite work
    // const [longPressing, setLongPressing] = useState(false)
    // const timedAdd = useCallback(() => {
    //     console.log('timedAdd', longPressing, status)
    //     onUpdate(habit, JSON.stringify({ value: status.value + 1 } as CounterItemStatus))
    //     if (status.value === config.target - 1) {
    //         reward()
    //     }

    //     if (longPressing) setTimeout(timedAdd, 500)
    // }, [status.value, longPressing])

    // useEffect(() => {
    //     if (longPressing) setTimeout(timedAdd, 500)
    // }, [longPressing, status.value])

    const onAddClick = useLongPress(() => undefined, {
        onFinish: () => changeValue(10),
        onCancel: () => changeValue(1),
    })
    const onSubstractClick = useLongPress(() => undefined, {
        onFinish: () => changeValue(-10),
        onCancel: () => changeValue(-1),
    })

    if (!props.detailsView) {
        return (
            <Stack direction="row" alignItems={'center'}>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        if (onInfoClick) onInfoClick(habit)
                    }}
                >
                    <InfoIcon htmlColor="white" />
                </IconButton>

                <Paper
                    elevation={1}
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flex: 1,
                        background: active ? '#f8f8f8' : '#eee',
                        backgroundColor: checked ? '#ded' : '#eee',
                        padding: '4px 4px 4px 16px',
                        userSelect: 'none',

                        '&:hover': {
                            backgroundColor: active ? 'lightgray' : '',
                            userSelect: 'none',
                            transition: 'background 250ms',
                            cursor: active ? 'pointer' : '',
                        },
                    }}
                    onClick={() => changeValue(1)}
                >
                    <span id="rewardId" />
                    <IconButton disabled={true}>
                        <StatusProgress target={config.target} value={status.value} size={24} />
                    </IconButton>

                    <Typography flex={1} color={active ? 'black' : 'gray'}>
                        {habit.name}
                    </Typography>
                    <Typography
                        flex={1}
                        textAlign={'center'}
                        color={active && !checked ? 'black' : !checked ? 'gray' : 'green'}
                    >
                        {status.value}/{config.target}
                    </Typography>
                    <Stack flex={1} direction="row" justifyContent={'flex-end'} alignItems={'center'}>
                        {active && (
                            <IconButton
                                {...onSubstractClick()}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                <RemoveCircleOutlineIcon htmlColor="black" />
                            </IconButton>
                        )}

                        {!active && (
                            <IconButton disabled>
                                <RemoveCircleOutlineIcon htmlColor="gray" />
                            </IconButton>
                        )}
                        {active && (
                            <IconButton
                                {...onAddClick()}
                                disabled={!active}
                                onClick={(e) => {
                                    e.stopPropagation()
                                }}
                            >
                                <ControlPointIcon htmlColor="black" />
                            </IconButton>
                        )}

                        {!active && (
                            <IconButton disabled>
                                <ControlPointIcon htmlColor="gray" />
                            </IconButton>
                        )}
                    </Stack>
                </Paper>
            </Stack>
        )
    } else {
        return (
            <Box sx={{ padding: '16px', borderRadius: '16px' }}>
                <Typography fontSize={24} marginBottom={1}>
                    {habit.name}
                </Typography>
                <Paper elevation={1}>
                    <Stack
                        flex={1}
                        direction="row"
                        justifyContent={'center'}
                        alignItems={'center'}
                        sx={{ paddingRight: '16px' }}
                    >
                        <span id="rewardId" />
                        {checked && (
                            <IconButton disabled>
                                <CheckCircleIcon htmlColor="#090" sx={{ fontSize: 40 }} />
                            </IconButton>
                        )}
                        {!checked && (
                            <IconButton disabled>
                                <RadioButtonUncheckedIcon sx={{ fontSize: 40 }} />
                            </IconButton>
                        )}

                        <Typography flex={1} textAlign={'center'} sx={{ fontSize: 40 }}>
                            {status.value}/{config.target}
                        </Typography>
                    </Stack>
                </Paper>
                <Stack
                    flex={1}
                    direction="row"
                    justifyContent={'center'}
                    alignItems={'center'}
                    paddingTop={'16px'}
                    spacing={1}
                >
                    <Button
                        disabled={!active}
                        variant="contained"
                        sx={{ fontSize: 25, height: 40 }}
                        {...onSubstractClick()}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <RemoveIcon />
                    </Button>
                    <Button
                        disabled={!active}
                        variant="contained"
                        sx={{ fontSize: 25, height: 40 }}
                        {...onAddClick()}
                        onClick={(e) => {
                            e.stopPropagation()
                        }}
                    >
                        <AddIcon />
                    </Button>
                </Stack>
            </Box>
        )
    }
}
