import { SignIn, SignUp, UserProfile, useAuth, useClerk } from '@clerk/clerk-react'
import CloseIcon from '@mui/icons-material/Close'
import InfoIcon from '@mui/icons-material/Info'
import {
    Box,
    Button,
    CssBaseline,
    FormControlLabel,
    GlobalStyles,
    IconButton,
    Switch,
    ThemeProvider,
    Typography,
    useMediaQuery,
} from '@mui/material'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { useStoreState } from '../store/hooks'
import { SceneIdentifier } from '../store/types'
import { globalStyles } from '../theme/globalStyles'
import theme from '../theme/theme'
import Routes from '../util/routes'

const StartPage = () => {
    const [isFirstTime, setFirstTime] = useState(false)
    const [isLoggedIn, setLoggedIn] = useState(false)
    const [login, setLogin] = useState(false)
    const [signUp, setSignUp] = useState(false)
    const [accountSettings, setAccountSettings] = useState(false)
    const { isLoaded, userId, sessionId, getToken, isSignedIn } = useAuth()
    const isMobile = useMediaQuery('(max-width: 600px)')
    const { signOut } = useClerk()
    const navigate = useNavigate()
    const [isFullScreen, setFullScreen] = useState(false)
    const currentPosition = useStoreState((state) => state.gameModel.currentPosition)
    const currentScene = useStoreState((state) => state.gameModel.currentScene)

    const toggleFullscreen = () => {
        const documentElement = document.documentElement as HTMLElement & {
            requestFullscreen?: () => Promise<void>
            webkitRequestFullscreen?: () => Promise<void>
            msRequestFullscreen?: () => Promise<void>
        }

        const documentExitFullscreen = document as Document & {
            exitFullscreen?: () => Promise<void>
            webkitExitFullscreen?: () => Promise<void>
            msExitFullscreen?: () => Promise<void>
        }

        if (!isFullScreen) {
            if (documentElement.requestFullscreen) {
                documentElement.requestFullscreen()
            } else if (documentElement.webkitRequestFullscreen) {
                documentElement.webkitRequestFullscreen()
            } else if (documentElement.msRequestFullscreen) {
                documentElement.msRequestFullscreen()
            }
        } else {
            if (documentExitFullscreen.exitFullscreen) {
                documentExitFullscreen.exitFullscreen()
            } else if (documentExitFullscreen.webkitExitFullscreen) {
                documentExitFullscreen.webkitExitFullscreen()
            } else if (documentExitFullscreen.msExitFullscreen) {
                documentExitFullscreen.msExitFullscreen()
            }
        }
    }

    const handleFullscreenChange = () => {
        const isCurrentlyFullscreen =
            document.fullscreenElement ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (document as any).webkitFullscreenElement ||
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            (document as any).msFullscreenElement ||
            window.innerHeight === screen.height

        setFullScreen(!!isCurrentlyFullscreen)
    }

    useEffect(() => {
        document.addEventListener('fullscreenchange', handleFullscreenChange)
        document.addEventListener('webkitfullscreenchange', handleFullscreenChange)
        document.addEventListener('msfullscreenchange', handleFullscreenChange)
        window.addEventListener('resize', handleFullscreenChange)

        return () => {
            document.removeEventListener('fullscreenchange', handleFullscreenChange)
            document.removeEventListener('webkitfullscreenchange', handleFullscreenChange)
            document.removeEventListener('msfullscreenchange', handleFullscreenChange)
            window.removeEventListener('resize', handleFullscreenChange)
        }
    }, [])

    useEffect(() => {
        const hasStartedJourney = localStorage.getItem('hasStartedJourney')

        if (!hasStartedJourney) {
            setFirstTime(true)
        }
    }, [])

    const startJourney = () => {
        localStorage.setItem('hasStartedJourney', 'true')
        if (isFirstTime) {
            navigate(Routes.game.SCENE.replace(':id', SceneIdentifier.ELLIS_OFFICE))
        } else {
            if (currentPosition !== 'void')
                navigate(Routes.game.SCENE_WITH_VP.replace(':id', currentScene).replace(':vpid', currentPosition))
            else navigate(Routes.game.SCENE.replace(':id', currentScene))
        }
    }

    useEffect(() => {
        if (isLoaded && isSignedIn) {
            setLoggedIn(true)
        }
    }, [isLoaded, isSignedIn, userId, sessionId, getToken])

    const handleSignIn = () => {
        setLogin(true)
    }

    const handleSignUp = () => {
        setSignUp(true)
    }

    const handleAccountSettings = () => {
        setAccountSettings(true)
    }

    const handleLogout = () => {
        if (isSignedIn) {
            setLoggedIn(false)
            setLogin(false)
            setAccountSettings(false)
            signOut()
        }
    }

    const renderButtons = () => {
        if (isLoggedIn) {
            return (
                <>
                    <Button className="startMenuItem" onClick={startJourney}>
                        {isFirstTime ? 'Start journey' : 'Continue journey'}
                    </Button>
                    <Button className="startMenuItem" onClick={handleAccountSettings}>
                        Account
                    </Button>
                </>
            )
        } else {
            return (
                <>
                    <Button className="startMenuItem" onClick={handleSignUp}>
                        Sign up
                    </Button>
                    <Button className="startMenuItem" onClick={handleSignIn}>
                        Login
                    </Button>
                </>
            )
        }
    }

    const renderWarningBox = () => {
        if (isMobile) {
            return (
                <Box className="startPageWarningBox">
                    <InfoIcon className="startWarningInfoIcon" />
                    <Typography variant="body2" className="startWarningText">
                        This website is not yet optimized for smartphones. Please use a larger screen or landscape mode
                        for the best experience.
                    </Typography>
                </Box>
            )
        } else {
            return null
        }
    }

    function renderStartMenu() {
        if (login && !isLoggedIn) {
            return (
                <>
                    <IconButton onClick={() => setLogin(false)} className="startMenuIconButton">
                        <CloseIcon className="startMenuCloseButton" />
                    </IconButton>
                    <SignIn />
                </>
            )
        } else if (accountSettings && isLoggedIn) {
            return (
                <>
                    <IconButton onClick={() => setAccountSettings(false)} className="startMenuIconButton">
                        <CloseIcon className="startMenuCloseButton" />
                    </IconButton>
                    <UserProfile />
                </>
            )
        } else if (signUp && !isLoggedIn) {
            return (
                <>
                    <IconButton onClick={() => setSignUp(false)} className="startMenuIconButton">
                        <CloseIcon className="startMenuCloseButton" />
                    </IconButton>
                    <SignUp />
                </>
            )
        } else {
            return (
                <>
                    <Typography variant="h1" className="startLogo">
                        MORGENSTROM
                    </Typography>
                    {renderButtons()}
                    <Button className="startMenuItem">About</Button>
                    {isLoggedIn && (
                        <Button className="startMenuItem" onClick={handleLogout}>
                            Logout
                        </Button>
                    )}
                    <FormControlLabel
                        className="toggleButtonFullscreen"
                        label={<Typography className="toggleButtonFullscreen">Fullscreen</Typography>}
                        labelPlacement="start"
                        control={<Switch checked={isFullScreen} onChange={toggleFullscreen} />}
                    />
                </>
            )
        }
    }

    return (
        <ThemeProvider theme={theme}>
            <CssBaseline />
            <GlobalStyles styles={globalStyles} />

            <Box className="startPage">
                <Box className="startBackground" />
                <Box className="startMenu">
                    {renderStartMenu()}
                    {/* {process.env.NODE_ENV === 'development' && (
                        <Button className="startMenuItem" onClick={() => navigate('/devtools')}>
                            Dev Tools (Page Tree)
                        </Button>
                    )} */}
                </Box>
                {renderWarningBox()}
            </Box>
        </ThemeProvider>
    )
}

export default StartPage
