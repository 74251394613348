import { action, Action, Thunk, thunk } from 'easy-peasy'
import { v4 } from 'uuid'
import { DojoItem, DojoItemRecord, DojoPurposeType } from '../../graphql/types'
import {
    loadDojoItemRecords,
    loadDojoItems,
    loadEveningRoutine,
    loadMorningRoutine,
    saveDojoRecords,
    saveMorningRoutine,
} from '../storage/dojoLog'

// ======================================================== Type definition
export type DojoLogModel = {
    // Data
    activeItemID: string
    dojoItems: DojoItem[]
    dojoItemRecords: DojoItemRecord[]
    morningRoutine: DojoPurposeType[]
    eveningRoutine: DojoPurposeType[]

    // Initialization and resetting
    loadData: Thunk<DojoLogModel>
    setDojoItems: Action<DojoLogModel, DojoItem[]>
    setDojoItemRecords: Action<DojoLogModel, DojoItemRecord[]>
    setMorningRoutine: Action<DojoLogModel, DojoPurposeType[]>
    setEveningRoutine: Action<DojoLogModel, DojoPurposeType[]>

    addGeneratedRecords: Action<DojoLogModel, DojoItemRecord[]>
    // Record actions
    setActiveItemID: Action<DojoLogModel, string>
    trackDojoItem: Action<
        DojoLogModel,
        {
            item: string
            date: number
            viewed: boolean
            status: string
        }
    >
}

const dojoLogModel: DojoLogModel = {
    // Data
    activeItemID: '',
    dojoItems: [],
    dojoItemRecords: [],
    morningRoutine: [],
    eveningRoutine: [],

    // Initialization and resetting
    setDojoItems: action((state, payload) => {
        state.dojoItems = payload
    }),
    loadData: thunk(async (actions) => {
        actions.setDojoItems(loadDojoItems())
        actions.setDojoItemRecords(loadDojoItemRecords())
        actions.setMorningRoutine(loadMorningRoutine())
        actions.setEveningRoutine(loadEveningRoutine())
    }),
    setActiveItemID: action((state, payload) => {
        state.activeItemID = payload
    }),
    setDojoItemRecords: action((state, payload) => {
        state.dojoItemRecords = payload
    }),
    setMorningRoutine: action((state, payload) => {
        state.morningRoutine = payload
        saveMorningRoutine(state.morningRoutine)
    }),
    setEveningRoutine: action((state, payload) => {
        state.eveningRoutine = payload
        saveMorningRoutine(state.eveningRoutine)
    }),
    addGeneratedRecords: action((state, payload) => {
        state.dojoItemRecords.push(...payload)
        saveDojoRecords(state.dojoItemRecords)
    }),

    // Record actions
    trackDojoItem: action((state, payload) => {
        const index = state.dojoItemRecords.findIndex(
            (r) => r.item === payload.item && new Date(r.date).toDateString() === new Date(payload.date).toDateString(),
        )
        // Create new id if new item
        const record: DojoItemRecord = {
            ...payload,
            id: index !== -1 ? state.dojoItemRecords[index].id : v4(),
            date: payload.date,
        }

        if (index !== -1) state.dojoItemRecords.splice(index, 1, record)
        else state.dojoItemRecords.push(record)
        saveDojoRecords(state.dojoItemRecords)
    }),
}

export default dojoLogModel
