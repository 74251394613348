import { SceneIdentifier } from '../../../store/types'
import { LinkType, SceneItemType } from './types'

export const ellisCafe = {
    id: SceneIdentifier.ELLIS_CAFE,
    items: [
        {
            id: 'cafbg',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: -2000,
                y: -500,
                w: 7200,
                h: 1200,
                z: -1000,
            },
            imageURL: '/img/cafe/cafe_bg.svg',
        },
        {
            id: 'cafbmg',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: -370,
                y: -150,
                w: 4300,
                h: 1200,
                z: -200,
            },
            imageURL: '/img/cafe/cafe_mg.svg',
        },
        {
            id: 'caffg',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 0,
                y: 0,
                w: 3600,
                h: 1200,
            },
            imageURL: '/img/cafe/cafe_fg.svg',
        },
        {
            id: 'd1',
            type: SceneItemType.AREA,
            placement: {
                x: 2760,
                y: 470,
                w: 310,
                h: 450,
                z: 261,
            },
            link: { id: '1ppl', type: LinkType.VIEWPOINT },
        },
        {
            id: 'd2',
            type: SceneItemType.AREA,
            placement: {
                x: 1500,
                y: 470,
                w: 1000,
                h: 450,
                z: 261,
            },
            link: { id: '2ppl', type: LinkType.VIEWPOINT },
        },
    ],
    backLink: { id: SceneIdentifier.ELLIS_STREET, label: 'auf die Straße', type: LinkType.SCENE },
    viewpoints: [
        { id: 'all', tx: -1310 },
        {
            id: '1ppl',
            ry: -6,
            scale: 1.8,
            tx: -2220,
            ty: -220,
            hide: ['d1', 'd2'],
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            triggerDialog: '1ppl',
            dialogPlacement: { x: 2550, y: 190, w: 1000, h: 650, scale: 0.5, z: 250 },
        },
        {
            id: '2ppl1',
            ry: 3,
            scale: 1.8,
            tx: -1220,
            ty: -320,
            hide: ['d1', 'd2'],
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            triggerDialog: 'ellis_intro',
            dialogPlacement: { x: 1500, y: 260, w: 1000, h: 650, scale: 0.5, z: 250 },
        },
        {
            id: '2ppl2',
            ry: -3,
            scale: 1.8,
            tx: -680,
            ty: -360,
            hide: ['d1', 'd2'],
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            triggerDialog: 'ellis_intro',
            dialogPlacement: { x: 1350, y: 300, w: 900, h: 650, scale: 0.5, z: 250 },
        },
        {
            id: '2ppl',
            ry: -3,
            scale: 1.2,
            tx: -950,
            ty: -160,
            hide: ['d1', 'd2'],
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            triggerDialog: '2ppl',
            dialogPlacement: { x: 1450, y: 700, w: 1000, h: 650, scale: 0.5, z: 250 },
        },
    ],
    dialogs: [
        {
            id: '2ppl',
            fallback: 'wpc2zx1ukvx3',
            preferences: [],
            dialogScenes: [
                {
                    id: 'wpc2zx1ukvx3',
                    text: 'Oh hallo bist du neu hier? ',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: '2ppl1/pop6w2qgg1wc' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: 'pop6w2qgg1wc',
                    text: 'Hallo ich bin Person 1.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: '2ppl2/ztgu4wlbz16z' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: 'ztgu4wlbz16z',
                    text: 'Hi ich bin Person 2.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.VIEWPOINT, id: 'all' },
                            className: 'next',
                        },
                    ],
                },
            ],
        },
        {
            id: '1ppl',
            fallback: 'jq4jvcudc3v4',
            preferences: [],
            dialogScenes: [
                {
                    id: 'jq4jvcudc3v4',
                    text: 'Oh hallo, bist du neu hier? Ich bin Alex.',
                    choices: [
                        {
                            text: 'Hi Alex',
                            link: { type: LinkType.DIALOG_SCENE, id: 'i1bzfc8mcoxa' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: 'i1bzfc8mcoxa',
                    text: 'Du versuchst immer noch zu verstehen, was das hier alles ist, richtig? Wenn du willst, kann ich dir ein bisschen erzählen, was ich so bei Morgenstrom erlebt habe.',
                    choices: [
                        { text: 'Oh ja gerne.', link: { type: LinkType.DIALOG_SCENE, id: '3qlaa0aptykr' } },
                        { text: 'Danke, vielleicht später.', link: { type: LinkType.VIEWPOINT, id: 'all' } },
                    ],
                },
                {
                    id: '3qlaa0aptykr',
                    text: 'Okay, klar. Also, genau wie du, habe ich hier auf Ellis Island angefangen und meine Karte des Lebens bekommen. Das war schon toll, ich hatte einige Einsichten, was mir gefehlt hatte und was ich ändern wollte.',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: '4z7ydkfymnsd' } }],
                },
                {
                    id: '4z7ydkfymnsd',
                    text: 'Ich verbrachte also nur einige Zeit mit den Themen, die ich ausgewählt hatte, was mir schon mal kleine Fortschritte in diesen Bereichen gab. Aber der große Push für mein Leben kam anders.',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: 'nl2js86ubswc' } }],
                },
                {
                    id: 'nl2js86ubswc',
                    text: 'Ich ging immer mal wieder zu den offenen Treffen und traf immer interessante Leute. Da waren einige dabei, die mich wirklich inspiriert haben, also blieb ich dabei.',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: 'tkyg3qwtsmsh' } }],
                },
                {
                    id: 'tkyg3qwtsmsh',
                    text: 'Das ging ein paar Monate lang so. Irgendwann lernte ich Anna kennen, die mich als Person total inspirierte. Ihre ganze Art, wie sie mit Menschen umging, war etwas Besonderes. Allein die Art, wie sie jemanden begrüßt und mit einem spricht, gibt einem sofort das Gefühl, gesehen und geschätzt zu werden. Immer freundlich und aufbauend. Das war etwas ganz Besonderes.',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: 'e3rmyofnx05v' } }],
                },
                {
                    id: 'e3rmyofnx05v',
                    text: 'Anna erzählte mir, sie sei nicht immer so gewesen, aber irgendwann sei ihr klar geworden, dass sich *so viele Dinge* in ihrem Leben ändern würden, wenn sie lernen würde, jeden wertschätzend und aufmerksam zu  behandeln. Also hat sie einfach jeden Tag daran gearbeitet.',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: '6e79yqviiuij' } }],
                },
                {
                    id: '6e79yqviiuij',
                    text: 'Sie versuchte, viele Momente in ihrem Tag zu bemerken, in denen sie üben konnte, die neue Anna zu sein. Sie war drain echt konsequent und das hat man gemerkt. Ich war überwältigt. Sie hat sich einfach zu einer neuen Person gemacht?',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: '6b269ozos7pp' } }],
                },
                {
                    id: '6b269ozos7pp',
                    text: 'Ich hab einfach beschlossen, das Gleiche zu tun. Ich suchte mir in den Themen an denen ich arbeiten wollte Aspekte von mir selbst aus, die ich verbessern wollte, und nahm mir jeden Tag Zeit, um zu üben und mehr die Art von Alex zu werden, die ich gern sein möchte.',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: '55z57279tsii' } }],
                },
                {
                    id: '55z57279tsii',
                    text: 'Das fühlte sich schon wirklich gut an. Aber nach einiger Zeit fing es dann an, dass die ersten Dinge passierten, wo etwas in einer alltäglichen Situation plötzlich anders lief als es das vorher je getan hätte.',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: 'ydqlpi5m686o' } }],
                },
                {
                    id: 'ydqlpi5m686o',
                    text: 'Eine Freundin meiner Mutter hatte eine erfolgreiche Firma in einem Bereich, der mich damals schon interessiert hat. Das wusste ich, aber ich hatte nie das Gefühl, dass *ich* da arbeiten könnte. Aber irgendwann war sie bei uns zu Besuch und es kam in mir hoch: Moment mal - warum denn nicht?',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: 'paz2dmluynoy' } }],
                },
                {
                    id: 'paz2dmluynoy',
                    text: 'Eine Freundin meiner Mutter hatte eine erfolgreiche Firma in einem Bereich, der mich damals schon interessiert hat. Das wusste ich, aber ich hatte nie das Gefühl, dass *ich* da arbeiten könnte. Aber irgendwann war sie bei uns zu Besuch und es kam in mir hoch: Moment mal - warum denn nicht?',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: '3qlaa0aptykr' } }],
                },
                {
                    id: 'ydqlpi5m686o',
                    text: 'Ich erinnere mich daran, dass meine Mutter eine wirklich erfolgreiche Freundin hatte, die diese erfolgreiche Firma hatte in einem Bereich, der mich interessiert hat. Das wusste ich, aber ich hatte nie das Gefühl, dass ich da arbeiten könnte. Aber irgendwann war sie bei uns zu Besuch und ich dachte: Moment mal - warum denn nicht?',
                    choices: [{ text: 'Weiter >', link: { type: LinkType.DIALOG_SCENE, id: '3qlaa0aptykr' } }],
                },
            ],
        },
    ],
}
