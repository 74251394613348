import { SceneIdentifier, ScenePosition } from '../../../store/types'
import { ConditionType, LinkType, Modifier, SceneItemType, VariableChangeType } from './types'

export const ellisMapstandDetail = {
    id: SceneIdentifier.ELLIS_MAP_DETAIL,
    items: [
        {
            id: 'ms_bg',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 0,
                y: 0,
                w: 1920,
            },

            imageURL: '/img/map_stand_detail/map_stand.svg',
        },
    ],
    backLink: {
        id: SceneIdentifier.ELLIS_STREET + '/' + ScenePosition.LEFT,
        label: 'auf die Straße',
        type: LinkType.SCENE,
    },
    viewpoints: [
        {
            id: 'miles',
            ry: 0,
            scale: 1.9,
            tx: 130,
            ty: -340,
            backLink: {
                id: SceneIdentifier.ELLIS_STREET + '/' + ScenePosition.LEFT,
                label: 'auf die Straße',
                type: LinkType.SCENE,
            },
            triggerDialog: 'miles_explains',
            dialogPlacement: { x: 260, y: 280, w: 800, h: 650, scale: 0.5, z: 250 },
        },
    ],
    dialogs: [
        {
            id: 'miles_explains',
            fallback: 'qqz1qu5vhhag',
            preferences: [
                {
                    dialogId: 'q3qti8lpbydj',
                    mods: [
                        {
                            var: 'miles_introduced',
                            type: ConditionType.NOT_EQUALS,
                            value: Modifier.COMPLETED,
                            modifier: Modifier.VISIBLE,
                        },
                    ],
                },
            ],
            dialogScenes: [
                // ------------------------------------------- intro
                {
                    id: 'q3qti8lpbydj',
                    text: 'Hallo! Mein Name ist Miles. \n\nIch bin hier, um jedem eine kostenlose Karte dieser wunderschönen Inseln zu geben und zu erklären, wie man sie benutzt!',
                    choices: [
                        {
                            text: 'Hallo Miles',
                            link: { type: LinkType.DIALOG_SCENE, id: 'djcwff8jj5ep' },
                        },
                    ],
                },
                {
                    id: 'djcwff8jj5ep',
                    text: 'Hier ist erstmal deine ganz eigene 32-Zoll-Tablet-Taschenausgabe! Wenn du es verlierst, komm wieder zu mir und ich gebe dir ein neues. Achte darauf, dass du es nicht aus Versehen wäschst, es schrumpft nämlich! Dann wird es etwas schwer zu lesen.\n\n*Du kannst jetzt über das Hauptmenü auf deine eigene Karte zugreifen.*',
                    choices: [
                        {
                            text: 'Ok, danke. Und was ist das?',
                            link: { type: LinkType.DIALOG_SCENE, id: '475znsi1dx0u' },
                        },
                    ],
                },
                {
                    id: '475znsi1dx0u',
                    text: 'Oh, es gibt so viele tolle Dinge über die Karte zu sagen! Ich liebe diese Karte! Und das nicht nur, weil ich darauf programmiert bin. Wirklich!',
                    choices: [
                        {
                            text: 'Ganz bestimmt, ja!',
                            link: { type: LinkType.DIALOG_SCENE, id: 'aeigkvp7zo0x' },
                        },
                    ],
                },
                {
                    id: 'aeigkvp7zo0x',
                    text: 'Sie gibt so viel Überblick und Klarheit über das Leben, wie ein Bereich vom anderen abhängt. Man kann reflektieren, was man schon gut kann und wo es noch viel Verbesserungspotenzial gibt. Und darauf aufbauend kann man seine Reise planen! So viel Einsicht!',
                    choices: [
                        {
                            text: 'Hmm, wenn du es sagst?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'qqz1qu5vhhag' },
                            varChanges: [
                                { var: 'miles_introduced', action: VariableChangeType.SET, value: Modifier.COMPLETED },
                            ],
                        },
                    ],
                },
                // ------------------------------------------- main
                {
                    id: 'qqz1qu5vhhag',
                    text: 'Hast du vielleicht eine Frage, mit der ich dir helfen kann?',
                    choices: [
                        {
                            text: 'Ist das nun eine Karte von Inseln oder des Lebens?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'c1ywekskhzha' },
                        },
                        {
                            text: 'Wie können Bereiche von einander abhängen?',
                            link: { type: LinkType.DIALOG_SCENE, id: '6cgjxkq145wb' },
                        },
                        {
                            text: 'Wie reise ich denn zwischen Inseln?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'uj582oyncy9e' },
                        },
                        {
                            text: 'Gibt es eine Reiseroute die ich nehmen sollte?',
                            link: { type: LinkType.DIALOG_SCENE, id: '9x3jqu29wy9j' },
                        },
                        {
                            text: 'Welche Bereiche des Lebens werden denn abgedeckt?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'hdf4batwwitb' },
                        },
                    ],
                },
                // ------------------------------------------- Ist das nun eine Karte von Inseln oder des Lebens?
                {
                    id: 'c1ywekskhzha',
                    text: 'Beides. Es gibt so viel wertvolles Wissen für ein gutes Leben da draußen in der Welt. Die Bewohner der Morgenstrom Inseln haben die Welt bereist und alles Wesentliche zu ihrem Thema mitgebracht. Hier ist dann alles miteinander verbunden und hat seinen Platz. Es ist also eine "Karte der Inseln des Lebens", wenn du so möchtest.',
                    choices: [
                        {
                            text: 'weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'ggp9z2uvf6p0' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: 'ggp9z2uvf6p0',
                    text: 'Das Leben ist ja weder ein Ort noch ein Gegenstand - es ist schwierig, alle Aspekte zu veranschaulichen. Es gibt dazu viele Modelle, wie zum Beisplel das "Rad des Lebens". Wir sind der Meinung, eine Karte ist einfacher zu verstehen und damit deinen Weg zu finden. Und Inseln sind schön.',
                    choices: [
                        {
                            text: 'Ok, eine Karte macht irgendwie Sinn',
                            link: { type: LinkType.DIALOG_SCENE, id: 'qqz1qu5vhhag' },
                        },
                    ],
                },
                // ------------------------------------------- Wie können Bereiche von einander abhängen?
                {
                    id: '6cgjxkq145wb',
                    text: 'Nun, nehmen wir ein Beispiel. Du bist in einer Langzeitbeziehung und möchtest ein liebevollerer und fürsorglicherer Partner werden. Das ist ein Thema auf unserer Landkarte. Ist doch ein sehr wichtiges Thema, nicht wahr?',
                    choices: [
                        {
                            text: 'Sollte helfen, wenn mehr Menschen darin gut wären.',
                            link: { type: LinkType.DIALOG_SCENE, id: 'ydecpv1q5zcm' },
                        },
                    ],
                },
                {
                    id: 'ydecpv1q5zcm',
                    text: 'In diesem Bereich kannst du also lernen, wie du mehr Liebe und Fürsorge in deine Beziehung bringen kannst. Aber das hängt zu einem großen Teil von deiner Kommunikationsfähigkeit ab. Wenn es etwas gibt, mit dem du nicht so glücklich bist, wie kannst du das deinem Partner oder deiner Partnerin gegenüber konstruktiv zum Ausdruck bringen?',
                    choices: [
                        {
                            text: 'weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'p6x4gegj6xzx' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: 'p6x4gegj6xzx',
                    text: 'Da die Pflege einer Beziehung von Kommunikation abhängt, reist du also zu der Insel dafür und arbeitest daran, Kommunikationsfähigkeiten zu verstehen und zu üben. Das ist an sich natürlich schon nützlich für dich! ',
                    choices: [
                        {
                            text: 'weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: '9ssr10akb5ub' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: '9ssr10akb5ub',
                    text: 'Aber deine Resultate in der Kommunikation hängen auch davon ab, dass du genug Mut und Selbstwertgefühl hast, um überhaupt für deine eigenen Bedürfnisse und Wünsche einzustehen. Auch dazu gibt es bei uns einen Ort an den du reisen kannst um das zu verbessern.',
                    choices: [
                        {
                            text: 'Ok',
                            link: { type: LinkType.DIALOG_SCENE, id: '2l3a2ox10avm' },
                        },
                    ],
                },
                {
                    id: '2l3a2ox10avm',
                    text: 'Das ist ein Ziel von Morgenstrom: dir einen umfassenden Überblick zu verschaffen und zu ermöglichen, dass du für dich verstehst, wie alle Punkte zusammenhängen.\n\nZu oft haben Menschen nur ein vages Gefühl, dass irgendetwas nicht stimmt, greifen ein Thema auf, das plausibel klingt, bekommen es aber nicht umgesetzt oder sehen nicht die Resultate die sie sich gewünscht haben und haben keine Ahnung warum.',
                    choices: [
                        {
                            text: 'Ok',
                            link: { type: LinkType.DIALOG_SCENE, id: 'qqz1qu5vhhag' },
                        },
                    ],
                },

                // ------------------------------------------- Wie reise ich denn zwischen Inseln?

                {
                    id: 'uj582oyncy9e',
                    text: 'Wie der Transport genau stattfindet, ist ein bisschen kompliziert, hat mit Ruderbooten und Raum-Zeit-portalen zu tun. Am besten nicht zu viel darüber nachdenken, alle sind bisher heil angekommen.\n\n Wichtiger ist, dass du schaust, wo du jeweils als nächstes hin willst und dir dann die Zeit an einem Ort nimmst.',
                    choices: [
                        {
                            text: 'Ok',
                            link: { type: LinkType.DIALOG_SCENE, id: '15ewuzbnysfw' },
                        },
                    ],
                },
                {
                    id: '15ewuzbnysfw',
                    text: 'Verwende deine Karte im Hauptmenü um dich umzusehen, und wenn du weisst wohin du als nächstes möchtest, dann wählst du zu gegebener Zeit einfach das gewünschte Ziel aus und kannst die Reise dorthin beginnen.',
                    choices: [
                        {
                            text: 'Ok',
                            link: { type: LinkType.DIALOG_SCENE, id: 'qqz1qu5vhhag' },
                        },
                    ],
                },
                // ------------------------------------------- Gibt es eine Reiseroute die ich nehmen sollte?
                {
                    id: '9x3jqu29wy9j',
                    text: 'Jede Insel hat einige Dörfer oder Städte, die man besuchen kann. Die Bewohner dort konzentrieren sich auf ein bestimmtes Thema des Lebens und man kann durch Gespräche mit ihnen oder durch lesen oder hören von Artikeln oder anschauen von Videos ein Verständnis für das jeweilige Thema bekommen.',
                    choices: [
                        {
                            text: 'Ok',
                            link: { type: LinkType.DIALOG_SCENE, id: 'q272aubzr9dw' },
                        },
                    ],
                },
                {
                    id: 'q272aubzr9dw',
                    text: 'Etwas zu verstehen, geht in der Regel recht schnell. Viel schwieriger ist es, diese Dinge tatsächlich dauerhaft, jeden Tag in den Alltag zu integrieren. Es macht deswegen Sinn je zwei Monate in einem Bereich zu verbringen und diesen Aspekt des Lebens jeden Tag ein wenig zu üben. Oder halt so viel, wie du schaffst. So kannst du wirkliche Veränderungen bewirken. Viele kleine Schritte führen zum Erfolg. ',
                    choices: [
                        {
                            text: 'Ok',
                            link: { type: LinkType.DIALOG_SCENE, id: '8nhxddgxcx87' },
                        },
                    ],
                },
                {
                    id: '8nhxddgxcx87',
                    text: 'Wenn du also etwas in deinem Leben ändern oder verbessern möchtest, kannst du auf der Karte prüfen, wo es dazu etwas gibt, dir die Abhängigkeiten dazu einmal anschauen und entscheiden, wohin deine Reise als Nächstes gehen soll.',
                    choices: [
                        {
                            text: 'Ok',
                            link: { type: LinkType.DIALOG_SCENE, id: 'g9n7mjumykug' },
                        },
                    ],
                },
                {
                    id: 'g9n7mjumykug',
                    text: 'Du kannst eine Route planen, wie du die Inseln bereisen möchtest, aber du musst nicht. Zu weit in der Zukunft können sich deine Prioritäten verschoben haben, also ist es auch völlig in Ordnung, Schritt für Schritt vorzugehen.',
                    choices: [
                        {
                            text: 'Ok',
                            link: { type: LinkType.DIALOG_SCENE, id: 'byl1n32aca2c' },
                        },
                    ],
                },
                {
                    id: 'byl1n32aca2c',
                    text: 'Denk daran, die Gebiete, die du bereits besucht hast, von Zeit zu Zeit erneut aufzusuchen, um zu prüfen, ob sich neue Dinge ergeben haben, die du wissen solltest. Oder einfach, um das Thema wieder aufzufrischen und die Auswirkungen auf dein Leben zu verstärken.',
                    choices: [
                        {
                            text: 'Ok',
                            link: { type: LinkType.DIALOG_SCENE, id: 'qqz1qu5vhhag' },
                        },
                    ],
                },

                // ------------------------------------------- Welche Bereiche des Lebens werden denn abgedeckt?
                {
                    id: 'hdf4batwwitb',
                    text: 'Unser Ziel ist, dass du zu *allen* wichtigen Bereichen eines guten Lebens etwas bei uns findest. Am besten schaust du auf deine eigene Version der Karte, die du über das Menü erreichst. Das ist besser als wenn ich es versuche zu erzählen. Ich bin schon mal überhitzt, als ich von all den wichtigen Themen erzählt habe, die es hier gibt!',
                    choices: [
                        {
                            text: 'Ok',
                            link: { type: LinkType.DIALOG_SCENE, id: 'qqz1qu5vhhag' },
                        },
                    ],
                },
            ],
        },
    ],
}
