import { Box, Button, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useRootContainer } from '../util/hooks/useRootStack'
import Routes from '../util/routes'

export const DevToolsPage = () => {
    const rootContainer = useRootContainer()
    const navigate = useNavigate()

    const routes = Routes as { [key: string]: { [key: string]: string } }

    return (
        <Box className="bonsai">
            <Stack sx={[...(Array.isArray(rootContainer) ? rootContainer : [rootContainer])]} gap={2}>
                {Object.keys(Routes).map((categoryKey) => (
                    <Box
                        key={categoryKey}
                        sx={{
                            justifyContent: 'center',
                            display: 'flex',
                            flexDirection: 'column',
                            gap: 2,
                            paddingX: '16px',
                        }}
                    >
                        <Typography variant={'h1'}>{categoryKey}</Typography>
                        {Object.keys(routes[categoryKey]).map((pageKey) => (
                            <Button
                                key={pageKey}
                                variant={'contained'}
                                onClick={() => navigate(routes[categoryKey][pageKey])}
                            >
                                {pageKey}
                            </Button>
                        ))}
                    </Box>
                ))}
            </Stack>
        </Box>
    )
}
