import { VariableRepository } from '../../features/gameOfLife/data/types'
import { SceneIdentifier, ScenePosition } from '../types'

const currentSceneKey = 'current-scene'
const currentPositionKey = 'current-position'
const variablesKey = 'game-variables'

//Scene
export const loadCurrentScene = (): SceneIdentifier => {
    const currentScene = localStorage.getItem(currentSceneKey)
    if (currentScene === null) {
        localStorage.setItem(currentSceneKey, SceneIdentifier.ELLIS_OFFICE)
        return SceneIdentifier.ELLIS_OFFICE
    }
    return currentScene as SceneIdentifier
}

export const loadCurrentPosition = (): ScenePosition => {
    const currentPosition = localStorage.getItem(currentPositionKey)
    if (currentPosition === null) {
        localStorage.setItem(currentPositionKey, ScenePosition.VOID)
        return ScenePosition.VOID
    }
    return currentPosition as ScenePosition
}

export const saveCurrentLocation = (scene: SceneIdentifier, position: ScenePosition) => {
    localStorage.setItem(currentSceneKey, scene)
    localStorage.setItem(currentPositionKey, position)
}

//Vars
export const loadVariables = (): VariableRepository => {
    const variables = localStorage.getItem(variablesKey)
    if (variables === null) {
        return {}
    }
    return JSON.parse(variables)
}

export const saveVariables = (variables: VariableRepository) => {
    localStorage.setItem(variablesKey, JSON.stringify(variables))
}
