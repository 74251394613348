import { DialogScene, Placement, SceneViewpoint } from '../data/types'
import './InteractiveScene.css'

type DialogProps = {
    placement: Placement
    dialogScene: DialogScene
    viewPoint: SceneViewpoint
}

const DialogPanel = (props: DialogProps): JSX.Element | null => {
    const { placement, dialogScene, viewPoint } = props

    let transform = ''
    if (placement.scale !== undefined) transform += ` scale(${placement.scale})`
    if (placement.z !== undefined) transform += ` translateZ(${placement.z}px)`
    if (viewPoint.ry !== undefined) transform += ` rotateY(${viewPoint.ry !== undefined ? 0 - viewPoint.ry : 0}deg)`
    //TODO: don't display routine config dialog if the user has already configured the routine
    return (
        <div
            className={'dialogWrapper'}
            style={{
                left: placement.x,
                top: placement.y,
                width: placement.w,
                height: placement.h,
                transform,
            }}
        >
            <div className={'dialog'}>
                <span
                    dangerouslySetInnerHTML={{
                        __html: dialogScene.text
                            .replaceAll('\n', '<br/>')
                            .replaceAll(/\*{1,2}(.*?)\*{1,2}/g, '<strong>$1</strong>'),
                    }}
                />
            </div>
        </div>
    )
}

export default DialogPanel
