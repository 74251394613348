import { action, Action } from 'easy-peasy'

// ======================================================== Type definition
export type DashboardModel = {
    message: string
    setMessage: Action<DashboardModel, string>
}

const dashboardModel: DashboardModel = {
    message: 'Welcome to the Dojo! Its been a while since we last saw you. Do you want to train today?',
    setMessage: action((state, payload) => {
        state.message = payload
    }),
}

export default dashboardModel
