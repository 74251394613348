import { SceneIdentifier } from '../../../store/types'
import { LinkType, SceneItemType, VariableChangeType } from './types'

export const ellisDojo = {
    id: SceneIdentifier.ELLIS_DOJO,
    items: [
        {
            id: 'dojo_bg',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: -3700,
                y: -2350,
                w: 12667,
                h: 1200,
                z: -5000,
            },
            imageURL: '/img/dojo/dojo_bg.svg',
        },
        {
            id: 'dojo_fg',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 0,
                y: 0,
                w: 2667,
                h: 1200,
            },
            imageURL: '/img/dojo/dojo_fg.svg',
        },
        {
            id: 'sensei',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 650,
                y: 400,
                w: 190,
                h: 600,
            },
            imageURL: '/img/dojo/sensei.svg',
        },
        {
            id: 'lsen',
            type: SceneItemType.AREA,
            placement: {
                x: 650,
                y: 400,
                w: 190,
                h: 600,
            },
            link: { id: 'sens', type: LinkType.VIEWPOINT },
        },
    ],
    backLink: { id: SceneIdentifier.ELLIS_STREET, label: 'auf die Straße', type: LinkType.SCENE },
    viewpoints: [
        { id: 'all' },
        {
            id: 'sens',
            ry: 4,
            scale: 1.8,
            tx: -50,
            ty: -230,
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            hide: ['lsen'],
            triggerDialog: 'dojo_intro',
            dialogPlacement: { x: 650, y: 400, w: 900, h: 650, scale: 0.5, z: 250 },
        },
        {
            id: 'train',
            ry: 4,
            scale: 1.8,
            tx: 250,
            ty: -230,
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            hide: ['lsen'],
            closeDialog: true,
            dialogPlacement: { x: 650, y: 400, w: 900, h: 650, scale: 0.5, z: 250 },
        },
    ],
    dialogs: [
        {
            id: 'dojo_intro',
            fallback: 'h15g82zh4vow',
            preferences: [],
            dialogScenes: [
                // ------------------------------------------- intro
                {
                    id: 'h15g82zh4vow',
                    text: 'Schön dass du hier bist. Was möchtest du denn heute machen?',
                    choices: [
                        {
                            text: 'Morning Routine',

                            link: { type: LinkType.OVERLAY, id: 'dojoLog-morning-routine' },
                            //?? do we need this?
                            varChanges: [{ var: 'dojoTime', action: VariableChangeType.SET, value: 5 }],
                        },
                        {
                            text: 'Training',
                            link: { type: LinkType.OVERLAY, id: 'dojoLog-training' },
                            varChanges: [{ var: 'dojoTime', action: VariableChangeType.SET, value: 10 }],
                        },
                        {
                            text: 'Evening Routine',
                            link: { type: LinkType.OVERLAY, id: 'dojoLog-evning-routine' },
                            varChanges: [{ var: 'dojoTime', action: VariableChangeType.SET, value: 15 }],
                        },
                        {
                            //TODO: This should only be visible if the user has not configured routines?
                            text: 'Configure routines',
                            link: { type: LinkType.DIALOG_SCENE, id: 'configure-routines' },
                            varChanges: [{ var: 'dojoTime', action: VariableChangeType.SET, value: 20 }],
                        },
                    ],
                },
                {
                    id: 'h15g8s4vow',
                    text: 'Schön dass du hier bist. Was möchtest du denn heute machen?',
                    choices: [
                        {
                            text: 'Morning Routine',

                            link: { type: LinkType.OVERLAY, id: 'dojoLog-morning-routine' },
                            //?? do we need this?
                            varChanges: [{ var: 'dojoTime', action: VariableChangeType.SET, value: 5 }],
                        },
                        {
                            text: 'Training',
                            link: { type: LinkType.OVERLAY, id: 'dojoLog-training' },
                            varChanges: [{ var: 'dojoTime', action: VariableChangeType.SET, value: 10 }],
                        },
                        {
                            text: 'Evning Routine',
                            link: { type: LinkType.OVERLAY, id: 'dojoLog-evning-routine' },
                            varChanges: [{ var: 'dojoTime', action: VariableChangeType.SET, value: 15 }],
                        },
                        {
                            //TODO: This should only be visible if the user has not configured routines?
                            text: 'Configure routines',
                            link: { type: LinkType.DIALOG_SCENE, id: 'configure-routines' },
                            varChanges: [{ var: 'dojoTime', action: VariableChangeType.RESET, value: 20 }],
                        },
                    ],
                },
            ],
        },
    ],
}
