import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { v4 } from 'uuid'
import { Habit, HabitType, Record } from '../../../../graphql/types'
import { useStoreState } from '../../../../store/hooks'
import { dateToDay, dayToDate } from '../../../../util/functions/dayConversion'
import Routes from '../../../../util/routes'
import { BooleanItemStatus } from '../../components/booleanItem/BooleanItem'
import {
    CounterItemConfig,
    CounterItemStatus,
    defaultCounterItemConfig,
} from '../../components/counterItem/CounterItem'
import { StackItemConfig, defaultStackConfig } from '../../components/stackItem/StackItem'
import { TimerItemConfig, TimerItemStatus, defaultTimerItemConfig } from '../../components/timerItem/TimerItem'

export interface RecordTableItem {
    id: string
    status: number // From 0 to target for the circle thingy
    target: number
    streak: Streak
}

export interface Streak {
    leftStreak: boolean
    rightStreak: boolean
    actualStreak: boolean
    streakCount: number
}
export interface HabitTableItem {
    id: string
    name: string
    activeDays?: []
    records: RecordTableItem[]
    color?: string
}

export interface useHabitsOverviewReturn {
    monthDates: { date: Date; dayName: string }[]
    monthName: string
    handleNextClick: () => void
    handlePrevClick: () => void
    onDateClick: (date: Date) => void
    handleRecordClick: (habitID: string, date: Date) => void
    habitTableItems: HabitTableItem[]
    currentDay: number
}

//Getting the Date Range for the view we are showing (20 days before and 7 days after)
const getDateRange = (date: Date): { date: Date; dayName: string }[] => {
    const rangeDates = []
    const currentDate = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))

    // Get the 20 days before the current date
    for (let i = 20; i > 0; i--) {
        const prevDate = new Date(currentDate.getTime() - i * 24 * 60 * 60 * 1000)
        const dayName = prevDate.toLocaleDateString('default', { weekday: 'short' })
        rangeDates.push({ date: prevDate, dayName })
    }

    // Get the current date
    const dayName = currentDate.toLocaleDateString('default', { weekday: 'short' })
    rangeDates.push({ date: currentDate, dayName })

    // Get the 7 days after the current date
    for (let i = 1; i <= 7; i++) {
        const nextDate = new Date(currentDate.getTime() + i * 24 * 60 * 60 * 1000)
        const dayName = nextDate.toLocaleDateString('default', { weekday: 'short' })
        rangeDates.push({ date: nextDate, dayName })
    }

    return rangeDates
}

export const useHabitsOverview = (): useHabitsOverviewReturn => {
    const currentDay = dateToDay(new Date())
    const [currentDate, setCurrentDate] = useState(dayToDate(currentDay))
    const monthDates = useMemo(() => getDateRange(currentDate), [currentDate])
    const navigate = useNavigate()
    const habits = useStoreState((state) => state.trackingModel.habits)
    const records = useStoreState((state) => state.trackingModel.records)

    //Updating the month name when the current date changes
    const monthName = useMemo(() => {
        const firstDate = monthDates[0].date
        const lastDate = monthDates[monthDates.length - 1].date
        const isSameMonth =
            firstDate.getMonth() === lastDate.getMonth() && firstDate.getFullYear() === lastDate.getFullYear()
        if (isSameMonth) {
            return `${firstDate.toLocaleDateString('default', {
                month: 'long',
            })} ${firstDate.getFullYear()}`
        } else {
            return `${firstDate.toLocaleDateString('default', {
                month: 'short',
            })} ${firstDate.getFullYear()} - ${lastDate.toLocaleDateString('default', {
                month: 'short',
            })} ${lastDate.getFullYear()}`
        }
    }, [monthDates])

    //Updating scroll position when the current day, habits or records change
    useEffect(() => {
        handleScroll()
    }, [habits, records, currentDate])

    //navigation to the clicked date in tracking overview
    const onDateClick = (date: Date) => {
        if (dateToDay(date) > currentDay) {
            return
        }
        navigate(Routes.tracking.TRACKING.replace(':currentDay', dateToDay(date).toString()))
    }

    //navigation to the clicked habit in details view
    const handleRecordClick = (habitID: string, date: Date) => {
        if (dateToDay(date) > currentDay) {
            return
        }
        navigate(
            Routes.tracking.DETAILS.replace(':habitID', habitID).replace(':currentDay', dateToDay(date).toString()),
        )
    }

    //scroll handler that changes position depending on the actual screen we are on
    const handleScroll = () => {
        const table = document.getElementById('scroll-container')
        if (table) {
            const today = new Date()
            const actualDay = monthDates.findIndex(({ date }) => date.toDateString() === today.toDateString())
            const maxScrollPosition = table.scrollWidth - table.clientWidth

            //If the current date is in the date range we are showing, scroll to the current day
            if (actualDay >= 0 && actualDay < monthDates.length) {
                table.scrollLeft = (actualDay - 5) * 40
            }
            //  If the current date is before the date range we are showing, scroll to the right side of screen
            else if (currentDate < today) {
                table.scrollLeft = maxScrollPosition
            }
            //  If the current date is after the date range we are showing, scroll to the left side of screen
            else {
                table.scrollLeft = 0
            }
        }
    }

    //Function for handling streaks in the table. (Right now streakCount is not used, but it could be used to show the streak count in the table)
    const handleStreakRecords = (records: RecordTableItem[]): RecordTableItem[] => {
        for (let i = 1; i < records.length; i++) {
            const prevRecord = records[i - 1]
            const currentRecord = records[i]
            if (prevRecord?.status >= prevRecord.target && currentRecord?.status >= currentRecord.target) {
                currentRecord.streak.leftStreak = true
                currentRecord.streak.actualStreak = true
                prevRecord.streak.actualStreak = true
                prevRecord.streak.rightStreak = true
            }
        }

        let streakCount = 0
        for (let i = 0; i < records.length; i++) {
            const currentRecord = records[i]
            if (currentRecord?.status === currentRecord.target) {
                streakCount++
                currentRecord.streak.streakCount = streakCount
            } else {
                streakCount = 0
            }
        }

        return records
    }

    //handling click on previous date range button
    const handlePrevClick = () => {
        const lastDate = monthDates[0].date
        const newDate = new Date(lastDate.getTime() - 8 * 24 * 60 * 60 * 1000)
        setCurrentDate(newDate)
    }

    //handling click on next date range button
    const handleNextClick = () => {
        const lastDate = monthDates[monthDates.length - 1].date
        const newDate = new Date(lastDate.getTime() + 21 * 24 * 60 * 60 * 1000)
        setCurrentDate(newDate)
    }

    //Function for getting the records for a specific habit
    const getRecordTableItem = (habit: Habit, record: Record | undefined): RecordTableItem => {
        const streak: Streak = { actualStreak: false, leftStreak: false, rightStreak: false, streakCount: 0 }

        if (!record || habit.type === HabitType.STACK)
            return {
                id: v4(),
                status: 0,
                target: 1,
                streak,
            }

        if (habit.type === HabitType.BOOLEAN)
            return {
                id: record.id,
                status: (JSON.parse(record.status) as BooleanItemStatus).checked ? 1 : 0,
                target: 1,
                streak,
            }
        else if (habit.type === HabitType.COUNTER)
            return {
                id: record.id,
                status: (JSON.parse(record.status) as CounterItemStatus).value,
                target: (habit.config ? (JSON.parse(habit.config) as CounterItemConfig) : defaultCounterItemConfig)
                    .target,
                streak,
            }
        else
            return {
                id: record.id,
                status: (JSON.parse(record.status) as TimerItemStatus).value,
                target: (habit.config ? (JSON.parse(habit.config) as TimerItemConfig) : defaultTimerItemConfig).target,
                streak,
            }
    }

    //array of the items that will be displayed in the table, with id, status and target
    const habitTableItems: HabitTableItem[] = habits.map((habit) => {
        return {
            id: habit.id,
            name: habit.name,
            activeDays: habit.activeDays,
            color: habit.color,
            records: handleStreakRecords(
                monthDates.map((monthDate) => {
                    if (habit.type === HabitType.STACK) {
                        const config: StackItemConfig = habit.config ? JSON.parse(habit.config) : defaultStackConfig

                        const subRecordItems = config.subHabits.map((subHabit) => {
                            const record = records.find((record: Record) => {
                                return (
                                    record.habit === subHabit.id &&
                                    new Date(record.date).toDateString() === monthDate.date.toDateString()
                                )
                            })

                            return getRecordTableItem(subHabit, record)
                        })

                        const joined = subRecordItems
                            .map((item) => ({
                                id: item.id,
                                status: (1000 / item.target) * item.status, // Normalize to 1000 for reasonable precision
                                target: 1000,
                                streak: item.streak,
                            }))
                            .reduce(
                                (previous, current) =>
                                    ({
                                        id: previous.id, // TODO: ID here doesnt make sense cause its multiple? do we even need it
                                        status: previous.status + current.status,
                                        target: previous.target + current.target,
                                        streak: previous.streak,
                                    } as RecordTableItem),
                            )

                        return joined
                    }

                    const record = records.find(
                        (record: Record) =>
                            record.habit === habit.id &&
                            new Date(record.date).toDateString() === monthDate.date.toDateString(),
                    )

                    return getRecordTableItem(habit, record)
                }),
            ),
        } as HabitTableItem
    })

    return {
        currentDay,
        monthDates,
        monthName,
        habitTableItems,
        onDateClick,
        handleRecordClick,
        handlePrevClick,
        handleNextClick,
    }
}
