import { DojoItem, DojoItemRecord, DojoPurposeType } from '../../graphql/types'
import { testDojoItems } from '../../testData/dojoItems'
import { testDojoRecords } from '../../testData/dojoRecords'

const dojoItemsKey = 'dojo-items'
const dojoRecordsKey = 'dojo-records'
const morningRoutineConfig = 'morning-routine-config'
const eveningRoutineConfig = 'evening-routine-config'

export const loadDojoItems = (): DojoItem[] => {
    const dojoItems = localStorage.getItem(dojoItemsKey)
    if (dojoItems === null) {
        localStorage.setItem(dojoItemsKey, JSON.stringify(testDojoItems))
        return testDojoItems
    }
    return JSON.parse(dojoItems)
}

export const loadDojoItemRecords = (): DojoItemRecord[] => {
    const dojoRecords = localStorage.getItem(dojoRecordsKey)
    if (dojoRecords === null) {
        localStorage.setItem(dojoRecordsKey, JSON.stringify(testDojoRecords))
        return testDojoRecords
    }
    return JSON.parse(dojoRecords)
}

export const loadMorningRoutine = (): DojoPurposeType[] => {
    let config = localStorage.getItem(morningRoutineConfig)
    if (config === null) {
        config = JSON.stringify(Object.keys(DojoPurposeType).filter((purpose) => purpose.startsWith('ROUTINE_')))
        localStorage.setItem(morningRoutineConfig, config)
    }
    return (JSON.parse(config) as string[]).map((item) => DojoPurposeType[item as keyof typeof DojoPurposeType])
}

export const loadEveningRoutine = (): DojoPurposeType[] => {
    let config = localStorage.getItem(eveningRoutineConfig)
    if (config === null) {
        config = JSON.stringify(Object.keys(DojoPurposeType).filter((purpose) => purpose.startsWith('ROUTINE_')))
        localStorage.setItem(eveningRoutineConfig, config)
    }
    return (JSON.parse(config) as string[]).map((item) => DojoPurposeType[item as keyof typeof DojoPurposeType])
}

export const saveDojoItems = (dojoItems: DojoItem[]): DojoItem[] => {
    localStorage.setItem(dojoItemsKey, JSON.stringify(dojoItems))
    return dojoItems
}

export const saveDojoRecords = (dojoRecords: DojoItemRecord[]): DojoItemRecord[] => {
    localStorage.setItem(dojoRecordsKey, JSON.stringify(dojoRecords))
    return dojoRecords
}

export const saveMorningRoutine = (config: DojoPurposeType[]) => {
    localStorage.setItem(morningRoutineConfig, JSON.stringify(config))
}

export const saveEveningRoutine = (config: DojoPurposeType[]) => {
    localStorage.setItem(eveningRoutineConfig, JSON.stringify(config))
}
