import { v4 } from 'uuid'
import { DojoItem, DojoItemRecord, DojoPurposeType } from '../../graphql/types'

// https://stackoverflow.com/questions/2380019/generate-unique-random-numbers-between-1-and-100
const selectRandomN = (items: DojoItem[], count?: number) => {
    count = Math.min(count ?? items.length, items.length)
    for (let i = 0; i < (count ?? items.length); i++) {
        const random = Math.floor(Math.random() * (items.length - i)) // [0, length-i[

        const temp = items[i]
        items[i] = items[random]
        items[random] = temp
    }

    return items.slice(0, count)
}

// This does NOT add the items to the store, the calling function has to do that
export const generateDojoItems = (items: DojoItem[], records: DojoItemRecord[], count = 1): DojoItemRecord[] => {
    const availableItems = items.filter((item) => item.purposeType.startsWith('DOJO_'))
    const selectedItems = selectRandomN(availableItems, count)

    return selectedItems.map((item) => {
        // Find last records status if continuous, otherwise just undefined.
        const status = item.continuous
            ? records
                  .filter((record) => record.item === item.id)
                  .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                  .at(-1)?.status
            : undefined
        return {
            id: v4(),
            item: item.id,
            status,
            date: new Date().getTime(),
            viewed: false,
        }
    })
}

// export enum RoutineTime {
//     MORNING,
//     EVENING,
// }

// This does NOT add the items to the store, the calling function has to do that

export const generateRoutineItems = (
    items: DojoItem[],
    records: DojoItemRecord[],
    selectedPurposes: DojoPurposeType[],

    // time?: RoutineTime, TODO: Implement morning/evening etc splits?
): DojoItemRecord[] => {
    const availableItems = items.filter((item) => item.purposeType.startsWith('ROUTINE_'))
    const groups: Map<DojoPurposeType, DojoItem[]> = new Map()

    for (const item of availableItems) {
        // If purpose is not included in our list, we just ignore the item
        if (!selectedPurposes.some((purpose) => purpose === item.purposeType)) continue

        const group = groups.get(item.purposeType) ?? []
        group.push(item)
        groups.set(item.purposeType, group)
    }

    const generatedRecords: DojoItemRecord[] = []
    for (const [_key, value] of groups) {
        // Randomly select one item out of each group
        const item = value[Math.floor(Math.random() * value.length)]

        // Find last records status if continuous, otherwise just undefined.
        const status = item.continuous
            ? records
                  .filter((record) => record.item === item.id)
                  .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())
                  .at(-1)?.status
            : undefined

        generatedRecords.push({
            id: v4(),
            item: item.id,
            status,
            date: new Date().getTime(),
            viewed: false,
        })
    }

    return generatedRecords
}
