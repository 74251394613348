export type Maybe<T> = T | null
export type InputMaybe<T> = Maybe<T>
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
}

export enum Change {
    ADD = 'ADD',
    DELETE = 'DELETE',
    UPDATE = 'UPDATE',
}

export enum DojoDisplayType {
    AUDIO = 'AUDIO',
    CHOICE = 'CHOICE',
    LINK = 'LINK',
    PERSPECTIVE = 'PERSPECTIVE',
    QUOTE = 'QUOTE',
    RATING = 'RATING',
    TEXT = 'TEXT',
    TEXT_INPUT = 'TEXT_INPUT',
    VIDEO = 'VIDEO',
}

export type DojoItem = {
    __typename?: 'DojoItem'
    config: Scalars['String']
    continuous: Scalars['Boolean']
    displayType: DojoDisplayType
    id: Scalars['ID']
    purposeType: DojoPurposeType
    title: Scalars['String']
}

export type DojoItemRecord = {
    __typename?: 'DojoItemRecord'
    date: Scalars['Int']
    id: Scalars['ID']
    item: Scalars['ID']
    status?: Maybe<Scalars['String']>
    viewed: Scalars['Boolean']
}

export type DojoItemRecordInput = {
    __typename?: 'DojoItemRecordInput'
    date: Scalars['Int']
    id: Scalars['ID']
    item: Scalars['ID']
    status?: Maybe<Scalars['String']>
    viewed: Scalars['Boolean']
}

export enum DojoPurposeType {
    DOJO_ACTIVE_LISTENING = 'DOJO_ACTIVE_LISTENING',
    LOG = 'LOG',
    ROUTINE_AFFIRMATION = 'ROUTINE_AFFIRMATION',
    ROUTINE_EXERCISE = 'ROUTINE_EXERCISE',
    ROUTINE_GRATITUDE = 'ROUTINE_GRATITUDE',
    ROUTINE_HEALTH_CHECK = 'ROUTINE_HEALTH_CHECK',
    ROUTINE_MEDITATION = 'ROUTINE_MEDITATION',
    ROUTINE_MOOD_CHECK = 'ROUTINE_MOOD_CHECK',
    ROUTINE_MOTIVATION = 'ROUTINE_MOTIVATION',
    ROUTINE_READING = 'ROUTINE_READING',
    ROUTINE_VISUALISATION = 'ROUTINE_VISUALISATION',
    ROUTINE_WRITING = 'ROUTINE_WRITING',
}

export type Habit = {
    __typename?: 'Habit'
    activeDays?: Maybe<Array<Scalars['Int']>>
    color?: Maybe<Scalars['String']>
    config?: Maybe<Scalars['String']>
    description?: Maybe<Scalars['String']>
    end?: Maybe<Scalars['Int']>
    id: Scalars['ID']
    name: Scalars['String']
    obstacles: Array<Obstacle>
    outcome?: Maybe<Scalars['String']>
    owner: Scalars['ID']
    start?: Maybe<Scalars['Int']>
    success?: Maybe<Scalars['String']>
    type: HabitType
}

export type HabitInput = {
    change: Change
    config?: InputMaybe<Scalars['String']>
    description?: InputMaybe<Scalars['String']>
    end?: InputMaybe<Scalars['Int']>
    id: Scalars['ID']
    name: Scalars['String']
    obstacles: Array<ObstacleInput>
    start?: InputMaybe<Scalars['Int']>
    subHabits: Array<HabitInput>
    success?: InputMaybe<Scalars['String']>
    type: HabitType
}

export enum HabitType {
    BOOLEAN = 'BOOLEAN',
    COUNTER = 'COUNTER',
    STACK = 'STACK',
    TIMER = 'TIMER',
}

export type IslandData = {
    __typename?: 'IslandData'
    config?: Maybe<Scalars['String']>
    height: Scalars['Float']
    id: Scalars['ID']
    img: Scalars['String']
    locations: Array<LocationData>
    name: Scalars['String']
    width: Scalars['Float']
    x: Scalars['Float']
    y: Scalars['Float']
}

export type LocationData = {
    __typename?: 'LocationData'
    config?: Maybe<Scalars['String']>
    id: Scalars['ID']
    name: Scalars['String']
    x: Scalars['Float']
    y: Scalars['Float']
}

export type Mutation = {
    __typename?: 'Mutation'
    changeHabits: Array<Habit>
    changeRecords: Array<Record>
    redeployTestData: Scalars['Boolean']
}

export type MutationChangeHabitsArgs = {
    changes: Array<HabitInput>
}

export type MutationChangeRecordsArgs = {
    changes: Array<RecordInput>
}

export type Obstacle = {
    __typename?: 'Obstacle'
    description: Scalars['String']
    icon: Scalars['String']
}

export type ObstacleInput = {
    description: Scalars['String']
    icon: Scalars['String']
}

export type Query = {
    __typename?: 'Query'
    habits: Array<Habit>
    records: Array<Record>
}

export enum RateDayEmoji {
    ANGRY = 'ANGRY',
    HAPPY = 'HAPPY',
    NEUTRAL = 'NEUTRAL',
    SAD = 'SAD',
    VERY_HAPPY = 'VERY_HAPPY',
}

export type Record = {
    __typename?: 'Record'
    date: Scalars['Int']
    habit: Scalars['ID']
    id: Scalars['ID']
    status: Scalars['String']
}

export type RecordInput = {
    date: Scalars['Int']
    habit: Scalars['ID']
    id: Scalars['ID']
    status: Scalars['String']
}

export type User = {
    __typename?: 'User'
    avatar?: Maybe<Scalars['String']>
    email: Scalars['String']
    id: Scalars['ID']
    name: Scalars['String']
}
