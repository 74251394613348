import { SceneIdentifier, ScenePosition } from '../../../store/types'
import { ConditionType, LinkType, Modifier, SceneItemType, VariableChangeType } from './types'

export const ellisDev = {
    id: SceneIdentifier.ELLIS_DEV,
    items: [
        {
            id: 'asbg',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 0,
                y: 0,
                w: 2000,
                h: 1200,
            },

            imageURL: '/img/ellis_dev/content_office2.svg',
        },
        {
            id: 'abor',
            type: SceneItemType.BOARD,
            placement: { x: 1260, y: 270, z: 2, w: 650 },
            boardId: '7xyec0r7w796',
        },
        {
            id: 'john',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 700,
                y: 330,
                z: 200,
                w: 484,
                h: 868,
            },

            imageURL: '/img/john/john.svg',
        },
        {
            id: 'johnfh',
            type: SceneItemType.GRAPHIC,
            flat: true,
            placement: {
                x: 700,
                y: 330,
                z: 200,
                w: 484,
                h: 868,
            },

            imageURL: '/img/john/john_gesture.svg',
        },
        {
            id: 'Ajohn',
            type: SceneItemType.AREA,
            placement: {
                x: 770,
                y: 350,
                w: 240,
                h: 650,
                z: 261,
            },
            link: { id: 'johndetail', type: LinkType.VIEWPOINT },
        },
        {
            id: 'Aboard',
            type: SceneItemType.AREA,
            placement: {
                x: 1250,
                y: 270,
                w: 650,
                h: 410,
                z: 4,
            },
            link: { id: '7xyec0r7w796', type: LinkType.BOARD },
        },
    ],
    backLink: {
        id: SceneIdentifier.ELLIS_STREET + '/' + ScenePosition.LEFT,
        label: 'auf die Straße',
        type: LinkType.SCENE,
    },
    viewpoints: [
        { id: 'all', hide: ['johnfh'] },
        {
            id: 'john',
            ry: 6,
            scale: 2.2,
            tx: -210,
            ty: -220,
            hide: ['johnfh'],
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            dialogPlacement: { x: 750, y: 260, w: 1000, h: 650, scale: 0.5, z: 250 },
        },
        {
            id: 'johndetail',
            ry: 0,
            scale: 2,
            tx: 280,
            ty: -220,
            hide: ['johnfh', 'Ajohn'],
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            triggerDialog: 'john_intro',
            dialogPlacement: { x: 130, y: 200, w: 800, h: 650, scale: 0.5, z: 250 },
        },
        {
            id: 'johnboard',
            ry: 6,
            scale: 1.8,
            tx: -220,
            ty: -220,
            hide: ['john', 'Ajohn'],
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            dialogPlacement: { x: 750, y: 440, w: 1000, h: 650, scale: 0.5, z: 250 },
        },
    ],
    // ------------------------------------------- Dialoge
    dialogs: [
        {
            id: 'john_intro',
            fallback: 'zbe2ltf2el4u',
            preferences: [
                {
                    dialogId: '9csfnv94r9tl',
                    mods: [
                        {
                            var: 'ellisdev_intro',
                            type: ConditionType.NOT_EQUALS,
                            value: Modifier.COMPLETED,
                            modifier: Modifier.VISIBLE,
                        },
                    ],
                },
            ],
            // ------------------------------------------- Intro
            dialogScenes: [
                {
                    id: '9csfnv94r9tl',
                    text: 'Oh, hallo! \nIch schätze, Ellis hat dich geschickt? Sie schickt Neuankömmlinge immer auf einen Besuch vorbei. Ich bin John, schön, dich kennenzulernen.',
                    choices: [
                        {
                            text: 'Hallo John',
                            link: { type: LinkType.DIALOG_SCENE, id: 'ahdvv09cqyk0' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: 'ahdvv09cqyk0',
                    text: 'Willkommen in meinem kleinen Homeoffice. Ich bin Content Researcher bei Morgenstrom. Wir sind eine große und verteilte Gruppe von Menschen, die alle Themen auf der Karte für dich recherchieren.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'hcxx2vzs4hz9' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: 'hcxx2vzs4hz9',
                    text: 'Dies ist eine der zentralen Wege, wie Morgenstrom für dich arbeitet. Wenn du etwas über ein bestimmtes Thema wissen willst, gibt es Tausende von Büchern, Videos und Online-Kursen. Was davon ist vertrauenswürdig und hilft dir wirklich? Das ist überwältigend, oder?',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: '4zi5jc9jgx3m' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: '4zi5jc9jgx3m',
                    text: 'Diese Aufgabe übernehmen wir für dich. Wir gehen durch diesen Dschungel aus Quellen, recherchieren gründlich zu dem Thema und stellen dir dann die Essenzen zusammen. Wenn du also bei uns in einen Lebensbereich eintauchst, musst du nicht suchen, du bekommst direkt Zugriff auf die Besten Inhalte aus diesem Bereich.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'johnboard/4lzdckb547ok' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: '4lzdckb547ok',
                    text: 'Das landet dann auf Content Boards wie diesem hier an der Wand. Die Tafeln können auch aktualisiert werden, wenn wir neue Informationen finden! Wir verpflichten uns zu einer strengen Prüfung der Themen, die wir präsentieren, aber auch zu Transparenz. Ok, ich erzähle schon von Details, entschuldige. Vielleicht fragst du lieber.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'johndetail/zbe2ltf2el4u' },
                            varChanges: [
                                { var: 'ellisdev_intro', action: VariableChangeType.SET, value: Modifier.COMPLETED },
                            ],
                            className: 'next',
                        },
                    ],
                },
                // ------------------------------------------- Hauptmenü
                {
                    id: 'zbe2ltf2el4u',
                    text: 'Was kann ich dir zu unseren Inhalten noch besser erklären?',
                    choices: [
                        {
                            text: 'Wie funktionieren diese Content Boards?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'johnboard/dwwd5fbn6nwm' },
                        },
                        {
                            text: 'Was meintest du mit "Prüfung" und "Transparenz"?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'ep2r9fsqs36p' },
                        },
                        {
                            text: 'Muss ich alles auf so einem Board abarbeiten?',
                            link: { type: LinkType.DIALOG_SCENE, id: '7t792dqnwop7' },
                        },
                        {
                            text: 'Was für Leute sind in deinem Research Team?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'n4zu3ujbdp5o' },
                        },
                        {
                            text: 'Du weisst also alles über das Leben?',
                            link: { type: LinkType.DIALOG_SCENE, id: '54txovakpyno' },
                        },
                    ],
                },
                // ------------------------------------------- Wie funktionieren die Boards?
                {
                    id: 'dwwd5fbn6nwm',
                    text: 'Content boards sehen so aus und sind damit leicht zu erkennen. Du findest sie an vielen Orten und sie enthalten geschriebene Artikel oder Videos, die du dir ansehen kannst.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: '6d1adbet1toi' },
                        },
                    ],
                },
                {
                    id: '6d1adbet1toi',
                    text: 'Die Inhalte sind wie auf einer Pinnwand frei angeordnet. Wir haben das extra so gemacht, damit du dich frei fühlen kannst, alles nach deinem Interesse zu durchstöbern und nicht das Gefühl einer A bis Z Aufgabenliste hast.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'w6xzrnc6dhnt' },
                        },
                    ],
                },
                {
                    id: 'w6xzrnc6dhnt',
                    text: 'Wenn du ein Board siehst, klick es einfach an. Die Sachen darauf kannst du wiederum anklicken, um sie zu lesen, anzuhören oder anzusehen. Dann markiere sie als erledigt und sie werden grau. So siehst du was du schon abgehakt hast.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'johndetail/zbe2ltf2el4u' },
                        },
                    ],
                },
                // ------------------------------------------- Was ist mit Transparenz?
                {
                    id: 'ep2r9fsqs36p',
                    text: 'Transparenz ist für uns *wirklich* wichtig. Wir möchten, dass Du allem was du hier findest, wirklich vertrauen kannst. Deshalb arbeiten wir so, dass wir dir immer zeigen können, wo die Informationen die du liest herkommen.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'p6sg7krhcg7w' },
                        },
                    ],
                },
                {
                    id: 'p6sg7krhcg7w',
                    text: 'Wir schauen uns auch die Quellen sehr genau an. Wir sind dabei von allen Quellen unabhängig und können so *sehr neutral* sein. Wir wählen nur aus, was *wirklich funktioniert*.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'zbe2ltf2el4u' },
                        },
                    ],
                },
                // ------------------------------------------- Muss ich alles abarbeiten?
                {
                    id: '7t792dqnwop7',
                    text: 'Nein. Die Inhalte auf einem Board sind wie Teile eines Puzzles, aber du brauchst nicht alle davon. Wir wollen vor allem, dass du die Möglichkeit hast das Thema zu *verstehen*. Deshalb gibt es erklärende Inhalte, aber auch Beispiele aus dem wirklichen Leben und aus verschiedenen Perspektiven.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: '4llccvm51kww' },
                        },
                    ],
                },
                {
                    id: '4llccvm51kww',
                    text: 'Wenn du Inhalte auf dem Board ansiehst und gleichzeitig weiter im Dojo übst, hast du ein eigenes Gefühl dafür, ob du gute Fortschritte machst oder mehr Input brauchst.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'peq6kvu2bkuz' },
                        },
                    ],
                },
                {
                    id: 'peq6kvu2bkuz',
                    text: 'Und wir versuchen natürlich die Inhalte vielseitig und interessant zu machen, damit es dir Spaß macht hier etwas zu lesen oder Videos anzusehen.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'zbe2ltf2el4u' },
                        },
                    ],
                },
                // ------------------------------------------- Wer ist das Team?
                {
                    id: 'n4zu3ujbdp5o',
                    text: 'Wir sind ein verteiltes Team mit verschiedenen Nationalitäten und  Hintergründen. Wir alle teilen die Faszination für das Thema persönliche Entfaltung. Wir suchen dabei nach den wesentlichen Wahrheiten und arbeiten möglichst gründlich und im Zeichen der Wissenschaft.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: '2qfkbaqgsx52' },
                        },
                    ],
                },
                {
                    id: '2qfkbaqgsx52',
                    text: 'Wir arbeiten als Team online zusammen, ziehen Informationen aus den vielen Quellen und teilen unsere Erkenntnisse strukturiert in unserem Wissensmanagementsystem. Es ist übrigens geplant, dass du dazu irgendwann vollen Zugang bekommst und ebenfalls reinschauen kannst.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'zbe2ltf2el4u' },
                        },
                    ],
                },
                // ------------------------------------------- Weisst du alles über das Leben?
                {
                    id: '54txovakpyno',
                    text: 'Hah! Das wäre so cool. Nein, für jedes Thema haben wir ein kleines Team, das in die Tiefe geht und alles recherchiert, was derzeit verfügbar ist. Wir finden die wichtigsten Erkenntnisse und fügen sie zu einem größeren Ganzen für das Thema zusammen.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'w21rtw128qhf' },
                        },
                    ],
                },
                {
                    id: 'w21rtw128qhf',
                    text: 'Auf diese Weise prüfen wir *viele Experten und Quellen* und du musst nicht für einen ganzen Lebensbereich einfach einer Person trauen. Keiner bei uns weiss alles über alles.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'n3xi99xcukz8' },
                        },
                    ],
                },
                {
                    id: 'n3xi99xcukz8',
                    text: 'Aber als Team schaffen wir es, für alle Bereiche fundierte Vorgehensweisen zu finden, wie man die Themen meistern kann. Oft ist die beste Lösung etwas, was sich erst zwischen den verschiedenen Aussagen von Experten als Ergebnis zeigt.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'zbe2ltf2el4u' },
                        },
                    ],
                },
            ],
        },
    ],
}
