import { action, Action } from 'easy-peasy'
import { dateToDay } from '../../util/functions/dayConversion'

// ======================================================== Type definition
export type TimeModel = {
    currentDay: number
    setCurrentDay: Action<TimeModel, number>
}

const timeModel: TimeModel = {
    currentDay: dateToDay(new Date()),
    setCurrentDay: action((state, payload) => {
        state.currentDay = payload
    }),
}

export default timeModel
