import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@mui/material'
import { useState } from 'react'
import { IslandData, LocationData } from '../../../../graphql/types'
import {} from '../mapData'

type IslandDrawerProps = {
    islandData: IslandData
    selectLocation?: LocationData | undefined
    onLocationSelect?: (location: LocationData | undefined) => void
}

const IslandDrawer = ({ islandData, selectLocation, onLocationSelect }: IslandDrawerProps) => {
    const [selectedLocation, setSelectedLocation] = useState<LocationData | undefined>(selectLocation)

    const handleLocationClick = (location: LocationData | undefined) => {
        if (selectedLocation === location) {
            setSelectedLocation(undefined)
            if (onLocationSelect) {
                onLocationSelect(undefined)
            }
        } else {
            setSelectedLocation(location)
            if (onLocationSelect) {
                onLocationSelect(location)
            }
        }
    }

    return (
        <>
            <Accordion key="island" sx={{ width: '100%' }} expanded={selectedLocation === undefined}>
                <AccordionSummary sx={{ dipslay: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant="h2" onClick={() => handleLocationClick(undefined)} sx={{ cursor: 'pointer' }}>
                        {islandData.name}
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography variant="h4">{islandData.config}</Typography>
                </AccordionDetails>
            </Accordion>

            {islandData.locations.map((location: LocationData) => (
                <Accordion
                    key={location.id}
                    sx={{ width: '100%' }}
                    expanded={selectedLocation === location}
                    onChange={() => handleLocationClick(location)}
                >
                    <AccordionSummary>
                        <Typography variant="h3" sx={{ cursor: 'pointer' }}>
                            {location.name}
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>{<Typography variant="h5">{location.config}</Typography>}</AccordionDetails>
                </Accordion>
            ))}
        </>
    )
}

export default IslandDrawer
