import CheckCircleIcon from '@mui/icons-material/CheckCircle'
import InfoIcon from '@mui/icons-material/Info'
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked'
import { Box, Checkbox, IconButton, Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
import { useReward } from 'react-rewards'
import { HabitComponentProps } from '../../views/trackingView/TrackingView'
import StatusProgress from '../statusProgress/StatusProgress'
export type BooleanItemProps = HabitComponentProps

export interface BooleanItemStatus {
    checked: boolean
}

export const defaultBooleanItemStatus: BooleanItemStatus = {
    checked: false,
}

export function BooleanItem(props: BooleanItemProps) {
    const { habit, record, onTrackHabit, onInfoClick, active } = props

    const status: BooleanItemStatus = record ? JSON.parse(record.status) : defaultBooleanItemStatus
    const { reward } = useReward('rewardId', 'confetti')
    const handleTaskCompletion = () => {
        const checked = !status.checked
        if (active) {
            onTrackHabit(habit, JSON.stringify({ checked } as BooleanItemStatus))
            if (checked) reward()
        }
    }
    if (!props.detailsView) {
        return (
            <Stack direction="row" alignItems={'center'}>
                <IconButton
                    onClick={(e) => {
                        e.stopPropagation()
                        if (onInfoClick) onInfoClick(habit)
                    }}
                >
                    <InfoIcon htmlColor="white" />
                </IconButton>

                <Paper
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        flexGrow: 1,
                        background: active ? '#f8f8f8' : '#eee',
                        backgroundColor: status.checked ? '#ded' : '#eee',
                        padding: '4px 4px 4px 16px',
                        userSelect: 'none',

                        '&:hover': {
                            backgroundColor: active ? 'lightgray' : '',
                            userSelect: 'none',
                            transition: 'background 250ms',
                            cursor: active ? 'pointer' : '',
                        },
                    }}
                    onClick={() => handleTaskCompletion()}
                    elevation={1}
                >
                    <IconButton disabled id="rewardId">
                        <StatusProgress target={1} value={status.checked ? 1 : 0} size={24} />
                    </IconButton>
                    <Typography color={active ? 'black' : 'gray'}>{habit.name}</Typography>
                    <Checkbox
                        sx={{
                            color: active ? 'black' : 'gray',
                            '& .MuiSvgIcon-root': {
                                color: active ? 'black' : 'gray',
                            },
                        }}
                        disabled={!active}
                        checked={status.checked}
                    />
                </Paper>
            </Stack>
        )
    } else {
        return (
            <Box sx={{ padding: '16px', borderRadius: '16px' }}>
                <Typography className={`habitName ${status.checked ? 'done' : ''}`} fontSize={24} marginBottom={1}>
                    {habit.name}
                </Typography>
                <Stack
                    paddingRight={'16px'}
                    alignItems={'center'}
                    onClick={() => {
                        if (active)
                            onTrackHabit(habit, JSON.stringify({ checked: !status.checked } as BooleanItemStatus))
                    }}
                >
                    {status.checked && (
                        <IconButton disabled={!active}>
                            <CheckCircleIcon htmlColor="#090" sx={{ fontSize: 40 }} />
                        </IconButton>
                    )}
                    {!status.checked && (
                        <IconButton disabled={!active}>
                            <RadioButtonUncheckedIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                    )}
                </Stack>
            </Box>
        )
    }
}
