import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Divider, IconButton, Paper, Stack, Typography } from '@mui/material'
import { format } from 'date-fns'
import { HabitType, Record } from '../../../../graphql/types'
import { dayToDate } from '../../../../util/functions/dayConversion'
import { useRootContainer } from '../../../../util/hooks/useRootStack'
import { Status } from '../../../../util/types'
import { BottomBar } from '../../../dashboard/components/BottomBar'
import { BooleanItem } from '../../components/booleanItem/BooleanItem'
import { CounterItem } from '../../components/counterItem/CounterItem'
import { ErrorItem } from '../../components/errorItem/ErrorItem'
import { StackItem, StackItemConfig, defaultStackConfig } from '../../components/stackItem/StackItem'
import { TimerItem } from '../../components/timerItem/TimerItem'
import { useDetailsView } from './useDetailsView'

export function DetailsView() {
    const useDetailsViewReturn = useDetailsView()
    const { habit, records, onClose, status, currentDay } = useDetailsViewReturn

    const rootContainer = useRootContainer()

    const stackItemDetails = () => {
        const subHabitRecords: Map<string, Record> = new Map()
        const config: StackItemConfig = habit.config ? JSON.parse(habit.config) : defaultStackConfig
        for (const subHabit of config.subHabits) {
            const subHabitRecord = records
                .filter(
                    (record) =>
                        record.habit === subHabit.id &&
                        new Date(record.date).getTime() === dayToDate(currentDay).getTime(),
                )
                .at(-1)
            if (subHabitRecord) subHabitRecords.set(subHabit.id, subHabitRecord)
        }
        return <StackItem subHabitRecords={subHabitRecords} {...useDetailsViewReturn} detailsView={true} />
    }

    if (status === Status.ERROR) return <ErrorItem />

    return (
        <Box className="bonsai">
            <BottomBar />
            <Box sx={[...(Array.isArray(rootContainer) ? rootContainer : [rootContainer])]}>
                <Paper elevation={1} sx={{ padding: '16px' }}>
                    <Stack display={'flex'} direction={'row'} alignItems={'center'}>
                        <IconButton onClick={() => onClose()}>
                            <ArrowBackIcon sx={{ fontSize: 40 }} color="primary" />
                        </IconButton>
                        <Typography variant="h5" color={'black'} textAlign={'center'} flex={1}>
                            {format(dayToDate(currentDay), 'dd LLL yyyy')}
                        </Typography>
                    </Stack>

                    {habit.type === HabitType.BOOLEAN && <BooleanItem {...useDetailsViewReturn} detailsView={true} />}
                    {habit.type === HabitType.COUNTER && <CounterItem {...useDetailsViewReturn} detailsView={true} />}
                    {habit.type === HabitType.TIMER && <TimerItem {...useDetailsViewReturn} detailsView={true} />}
                    {habit.type === HabitType.STACK && stackItemDetails()}

                    <Stack
                        direction="column"
                        justifyContent={'space-between'}
                        alignItems={'center'}
                        divider={<Divider orientation="horizontal" flexItem />}
                    >
                        {habit.success && (
                            <Stack
                                direction="row"
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                style={{ padding: '16px' }}
                                width={'90%'}
                            >
                                <Typography variant="h6">Success Info</Typography>
                                <Typography variant="body2">{habit.success}</Typography>
                            </Stack>
                        )}
                        {habit.obstacles.length !== 0 && (
                            <Stack direction="column" justifyContent={'space-between'} style={{ padding: '16px' }}>
                                {habit.obstacles &&
                                    habit.obstacles.map((obstacle) => (
                                        <Box key={habit.obstacles.indexOf(obstacle)}>
                                            <Typography variant="h6">
                                                Obstacle {habit.obstacles && habit.obstacles.indexOf(obstacle) + 1}:{' '}
                                            </Typography>
                                            <Typography variant="body2">{obstacle.description}</Typography>
                                        </Box>
                                    ))}
                            </Stack>
                        )}
                        {habit.outcome && (
                            <Stack
                                direction="column"
                                justifyContent={'space-between'}
                                alignItems={'center'}
                                sx={{
                                    width: '90%',
                                }}
                                style={{ padding: '16px' }}
                            >
                                <Typography variant="h6">Outcome</Typography>
                                <Typography variant="body2">{habit.outcome}</Typography>
                            </Stack>
                        )}
                    </Stack>
                </Paper>
            </Box>
        </Box>
    )
}
