import InfoIcon from '@mui/icons-material/Info'
import { Paper, Typography } from '@mui/material'
import { Stack } from '@mui/system'
type DojoCardProps = {
    msg: string
}

export function DojoCard(props: DojoCardProps) {
    const { msg } = props

    return (
        <Paper
            elevation={3}
            sx={{
                padding: 2,
            }}
        >
            <Stack display={'flex'} direction={'row'} gap={1}>
                <InfoIcon />
                <Typography>{msg}</Typography>
            </Stack>
        </Paper>
    )
}
