import { v4 } from 'uuid'
import { StackItemConfig } from '../features/habitTracking/components/stackItem/StackItem'
import { Habit, HabitType } from '../graphql/types'
import { getOffsetDay } from '../util/functions/testDate'

export const testHabitSupplements: Habit = {
    id: v4(),
    name: 'Take supplements',
    type: HabitType.BOOLEAN,
    description:
        'Our nutrition does not always contain all the nutrients your body needs. Through well researched and actively chosen supplements you can still do well',
    obstacles: [
        {
            icon: '',
            description:
                'This is an easy task to just forget. Put it into a habit stack of your choice to improve your chances.',
        },
        {
            icon: '',
            description: 'Too much to information. What is it that your body really needs? ',
        },
    ],
    outcome:
        'Your nutrition is exactly that - nutritious. You are a person that eats not just for taste but because you have chosen to supply yourself with the things that a healthy body needs.',
    owner: '',
}

export const testHabitWater: Habit = {
    id: v4(),
    name: 'Drink enough',
    type: HabitType.COUNTER,
    config: '{"target": 4}',
    owner: '',
    description: 'Drink 4 glasses of water',
    success: 'You did it!',
    outcome: 'You will feel better',
    obstacles: [],
    start: getOffsetDay(7),
    end: getOffsetDay(0),
}

export const testHabitMeditate: Habit = {
    id: v4(),
    name: 'Meditate',
    type: HabitType.TIMER,
    config: '{"target": 300}',
    owner: '',
    description: 'Medidate for 5 min',
    success: 'You are one with zen',
    outcome: 'You will feel better',
    obstacles: [],
}

export const subHabits: Habit[] = [testHabitSupplements, testHabitWater, testHabitMeditate]

export const testHabitMorning: Habit = {
    id: v4(),
    name: 'Morning routine',
    type: HabitType.STACK,
    obstacles: [],
    owner: '',
    config: JSON.stringify({ subHabits } as StackItemConfig),
}

export const testHabits: Habit[] = [testHabitMorning, testHabitSupplements, testHabitWater, testHabitMeditate]
