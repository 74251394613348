import { Button, Card, FormControl, OutlinedInput, Stack, TextField, Typography } from '@mui/material'

import { DojoItemComponentProps, DojoItemState } from '../../../../features/habitTracking/views/dojoView/DojoView'

import { palette } from '../../../../theme/palette'

export type JournalItemProps = DojoItemComponentProps

export interface JournalItemConfig {
    prompt: string
}

export const defaultJournalItemConfig: JournalItemConfig = {
    prompt: '',
}

export interface JournalItemStatus {
    text: string
}

export const defaultJournaltemStatus: JournalItemStatus = {
    text: '',
}

const JournalItem = (props: JournalItemProps): JSX.Element => {
    const { item, record, activeItemID, onTrackDojoItem, onNext } = props
    const config: JournalItemConfig = item.config ? JSON.parse(item.config) : defaultJournalItemConfig
    const status: JournalItemStatus = record.status ? JSON.parse(record.status) : defaultJournaltemStatus
    const { prompt } = config
    const { text } = status
    const state: DojoItemState =
        activeItemID === item.id ? DojoItemState.ACTIVE : record.viewed ? DojoItemState.DONE : DojoItemState.NEW

    return (
        <Card
            sx={{
                position: 'relative',
                margin: '20px',
                width: '100%',
            }}
        >
            <Stack direction="column" justifyContent="space-between" paddingY={'16px'}>
                {state !== DojoItemState.ACTIVE && (
                    <Typography
                        variant="h1"
                        color={state === DojoItemState.DONE ? palette.text?.disabled : palette.text?.primary}
                        paddingX={'24px'}
                    >
                        {item.title}
                    </Typography>
                )}
                {state === DojoItemState.DONE && text !== '' && (
                    <TextField
                        sx={{ paddingY: '16px', paddingX: '24px' }}
                        disabled={true}
                        value={text}
                        multiline
                        rows={4}
                        id="outlined-adornment-amount"
                    />
                )}
            </Stack>

            {state === DojoItemState.ACTIVE && (
                <>
                    <Stack direction="column" justifyContent="space-between" paddingY={'16px'}>
                        <Typography variant="h2" color={palette.text?.primary} paddingX={'24px'}>
                            Prompt: {prompt}
                        </Typography>
                        <FormControl sx={{ p: 4 }}>
                            <OutlinedInput
                                value={text}
                                onChange={(e) => {
                                    onTrackDojoItem(
                                        item,
                                        false,
                                        JSON.stringify({
                                            text: e.target.value,
                                        } as JournalItemStatus),
                                    )
                                }}
                                multiline
                                rows={4}
                                id="outlined-adornment-amount"
                            />
                        </FormControl>
                    </Stack>

                    {item.id === activeItemID && (
                        <Stack
                            display={'flex'}
                            direction={'row'}
                            justifyContent={'flex-end'}
                            spacing={2}
                            alignItems={'center'}
                            marginTop={2}
                            marginBottom={2}
                            marginRight={2}
                        >
                            <Button
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onNext()
                                }}
                                variant={'contained'}
                            >
                                <Typography variant="h5" color={'white'}>
                                    Next
                                </Typography>
                            </Button>
                            <Button
                                variant={'contained'}
                                onClick={(e) => {
                                    e.stopPropagation()
                                    onTrackDojoItem(
                                        item,
                                        true,
                                        JSON.stringify({
                                            text: text,
                                        } as JournalItemStatus),
                                    )
                                    onNext()
                                }}
                            >
                                <Typography variant="h5" color={'white'}>
                                    Done
                                </Typography>
                            </Button>
                        </Stack>
                    )}
                </>
            )}
        </Card>
    )
}

export default JournalItem
