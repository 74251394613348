import { SceneIdentifier } from '../../../store/types'
import { ConditionType, LinkType, Modifier, SceneItemType, VariableChangeType } from './types'

export const ellisOffice = {
    id: SceneIdentifier.ELLIS_OFFICE,
    items: [
        {
            id: 'bg',
            type: SceneItemType.GRAPHIC,
            placement: {
                x: 0,
                y: 0,
                w: 1920,
                h: 1200,
            },

            imageURL: '/img/ellis_office/ellis_office_bg.svg',
        },
        {
            id: 'ell',
            type: SceneItemType.GRAPHIC,
            flat: true,
            placement: {
                x: 450,
                y: 300,
                z: 100,
                w: 550,
            },
            imageURL: '/img/ellis/ellis.svg',
        },
        {
            id: 'efh',
            type: SceneItemType.GRAPHIC,
            flat: true,
            placement: { x: 450, y: 300, z: 100, w: 550 },
            imageURL: '/img/ellis/ellis_flathand.svg',
        },
        {
            id: 'fg',
            type: SceneItemType.GRAPHIC,
            placement: { x: 0, y: 0, z: 200, w: 1920 },
            imageURL: '/img/ellis_office/ellis_office_fg.svg',
        },

        {
            id: 'log',
            type: SceneItemType.LABEL,
            placement: { x: 690, y: 830, z: 201 },
            title: 'MORGENSTROM',
            className: 'xlarge whiteText',
        },

        {
            id: 'Ael',
            type: SceneItemType.AREA,
            placement: {
                x: 650,
                y: 330,
                w: 280,
                h: 330,
                z: 201,
            },
            link: { id: 'ellis', type: LinkType.VIEWPOINT },
        },
        // { imageURL: '/img/lifemap.jpg', x: 0, y: 300, w: 500 },
        // { imageURL: '/img/ellis_office/ellis_office_fg.svg', x: 0, y: 0, w: 1920 },
        // {
        //   imageURL: '/img/ellis/mug.png',
        //   x: 570,
        //   y: 590,
        //   w: 70,
        //   mods: [
        //     {
        //       var: 'ellis_intro',
        //       type: ConditionType.NOT_EXISTS,
        //       modifier: Modifier.HIDDEN,
        //     },
        //   ],
        // },
    ],
    backLink: { id: SceneIdentifier.ELLIS_STREET, label: 'auf die Straße', type: LinkType.SCENE },
    viewpoints: [
        { id: 'all', hide: ['efh'] },
        {
            id: 'ellis',
            ry: 4,
            scale: 1.8,
            tx: -90,
            ty: -180,
            hide: ['efh', 'Ael'],
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            triggerDialog: 'ellis_intro',
            dialogPlacement: { x: 700, y: 120, w: 800, h: 650, scale: 0.5, z: 250 },
        },
        {
            id: 'map',
            ry: -10,
            scale: 1.8,
            tx: 350,
            ty: -240,
            hide: ['ell', 'Ael'],
            backLink: { id: 'all', label: 'zurück', type: LinkType.VIEWPOINT },
            dialogPlacement: { x: -220, y: 430, w: 1700, scale: 0.4, z: 350 },
        },
    ],
    dialogs: [
        {
            id: 'ellis_intro',
            fallback: 'qud3xs9r0tfd',
            preferences: [
                {
                    dialogId: 'f6aoerl7xhwt',
                    mods: [
                        {
                            var: 'ellis_intro',
                            type: ConditionType.NOT_EQUALS,
                            value: Modifier.COMPLETED,
                            modifier: Modifier.VISIBLE,
                        },
                    ],
                },
            ],
            dialogScenes: [
                {
                    id: 'f6aoerl7xhwt',
                    text: 'Hallo, schön dich kennen zu lernen! Ich bin Ellis.\n\n Du bist hier bei etwas ungewöhnlichem und neuem gelandet.\n\n  Ein Zugang zu dem wichtigsten Wissen dazu, wie das Leben funktioniert. Hier kannst du Überblick bekommen und dann gezielt trainieren, was dir auffällt, was dein Leben besser machen kann.',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'map/y70jqx0dze1a' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: 'y70jqx0dze1a',
                    text: 'Hier siehtst du die Karte.',
                    viewpointId: 'map',
                    choices: [
                        {
                            text: 'Weiter',
                            link: { type: LinkType.DIALOG_SCENE, id: 'ellis/f4ngb263jw64' },
                            className: 'next',
                        },
                    ],
                },
                {
                    id: 'f4ngb263jw64',
                    text: 'Was ist daran so ungewöhnlich? Nun, hast du schon mal ein Spiel gespielt, was Spaß macht, wo du die Hauptfigur bist und nicht irgendein Charakter in dem Spiel immer besser wird, sondern Du selbst?',
                    choices: [
                        {
                            text: 'Ok, danke dir',
                            link: { type: LinkType.DIALOG_SCENE, id: 'qud3xs9r0tfd' },
                            varChanges: [
                                { var: 'ellis_intro', action: VariableChangeType.SET, value: Modifier.COMPLETED },
                            ],
                        },
                    ],
                },
                {
                    id: 'qud3xs9r0tfd',
                    text: 'Womit kann ich dir helfen?',
                    choices: [
                        {
                            text: 'Was ist Morgenstrom überhaupt?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'ngss27x23nvo' },
                        },
                        {
                            text: 'Was soll mir das bringen?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'ngss27x23nvo' },
                        },
                        {
                            text: 'Was ist die Karte da?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'map/ngss27x23nvo' },
                        },
                        {
                            text: 'Wie bedient man Morgenstrom?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'mv5lnli4bkjk' },
                        },
                        {
                            text: 'Ich bin schon etwas rumgekommen?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'mv5lnli4bkjk' },
                            dmod: Modifier.HIDDEN,
                            mods: [
                                {
                                    var: 'travels',
                                    type: ConditionType.GREATER_THAN,
                                    value: 335,
                                    modifier: Modifier.VISIBLE,
                                },
                            ],
                        },
                    ],
                },
                {
                    id: 'ngss27x23nvo',
                    text: 'Stell dir vor, dass du dich aus deinem Alltag ausklinken und eine Reihe von Inseln bereisen könntest. \n\nAuf jeder Insel beschäftigen sich die Menschen mit einem bestimmten Aspekt des Lebens und du kannst das wichtigste zu diesem Thema von ihnen lernen. Zusammengenommen werden alle Bereiche des Lebens abgedeckt, in denen du bisher wenig unterrichtet wurdest, die aber die wichtigsten Aspekte sind, um dein Leben so zufrieden und erfüllt zu machen wie möglich. \n\nDu bestimmst deine Reiseroute, also welche Themen für dein Leben am meisten positiven Effekt haben könnten.',
                    choices: [
                        {
                            text: 'Klingt gut, aber wo soll das sein?',
                            link: { type: LinkType.DIALOG_SCENE, id: 'ellis/ekgm60khrrdh' },
                        },
                    ],
                },
                {
                    id: 'ekgm60khrrdh',
                    text: 'Du hast ja recht. Auf der Erde gibt es einen solchen Ort leider nicht. Schade, aber genau deswegen gibt es jetzt eben Morgenstrom! \n\n Hier gibt es diesen Ort - zwar nur virtuell, aber was du hier lernst ist real und hilft dir in deinem echten Leben. \n\n Und eigentlich ist virtuell sogar ganz praktisch, denn wer von uns kann schon längere Zeit aus ihrem oder seinem Alltag heraus und auf Inseln herumreisen?',
                    choices: [
                        {
                            text: 'Ich nicht, das stimmt.',
                            link: { type: LinkType.DIALOG_SCENE, id: 'qud3xs9r0tfd' },
                            varChanges: [
                                { var: 'ellis_what', action: VariableChangeType.SET, value: Modifier.COMPLETED },
                            ],
                        },
                    ],
                },
                {
                    id: 'mv5lnli4bkjk',
                    text: 'Gute Frage!! Also das geht so...',
                    choices: [
                        {
                            text: 'AHA!! ICH VERSTEHE ALLES!',
                            link: { type: LinkType.DIALOG_SCENE, id: 'qud3xs9r0tfd' },
                            varChanges: [
                                { var: 'testSet', action: VariableChangeType.SET, value: 12 },
                                { var: 'testInc', action: VariableChangeType.INCREASE },
                                { var: 'testDec', action: VariableChangeType.DECREASE },
                                { var: 'testAdd', action: VariableChangeType.ADD, value: 3 },
                                { var: 'testSub', action: VariableChangeType.SUBTRACT, value: 3 },
                                { var: 'testReset', action: VariableChangeType.RESET },
                                { var: 'testDelete', action: VariableChangeType.DELETE },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
}
