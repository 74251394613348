const Routes = {
    tracking: {
        DASHBOARD: '/dashboard',
        TRACKING: '/tracking/:currentDay?',
        DOJOLOG: '/dojolog/:currentDay?',
        DETAILS: '/details/:habitID/:currentDay?',
        HABITS_OVERVIEW: '/habits-overview',
        CURRENT_HABITS_LIST: '/current-habits-list',
        EDIT_HABIT: '/edit-habit/:habitID',
        ADD_HABIT: '/edit-habit/add',
    },

    game: {
        STARTSCREEN: '/',
        SETTINGS: '/settings',
        MAP: '/map',
        CONFIGURE_ROUTINES: '/routine-config',
        SCENE: '/scene/:id',
        SCENE_WITH_VP: '/scene/:id/:vpid',
        CONTENT_BOARD: '/board/:id/:scid',
        CONTENT_TEXT: '/read/:cbid/:cid',
        CONTENT_VIDEO: '/watch/:cbid/:cid',
    },

    dev: {
        DEV: '/devtools',
        TEST: '/test',
    },
}

export default Routes
