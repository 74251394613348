import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import { Box, Card, Checkbox, FormControlLabel, IconButton, Stack, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { DojoPurposeType } from '../graphql/types'
import { useStoreActions, useStoreState } from '../store/hooks'

export function RoutineConfig() {
    const morningRoutine = useStoreState((state) => state.dojoLogModel.morningRoutine)
    const eveningRoutine = useStoreState((state) => state.dojoLogModel.eveningRoutine)
    const setMorningRoutine = useStoreActions((state) => state.dojoLogModel.setMorningRoutine)
    const setEveningRoutine = useStoreActions((state) => state.dojoLogModel.setEveningRoutine)

    const navigate = useNavigate()

    const createClickHandler = (
        routine: DojoPurposeType[],
        item: string,
        callback: (routine: DojoPurposeType[]) => void,
    ) => {
        return (_: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
            if (!checked) callback(routine.filter((purpose) => purpose.toString() !== item))
            else callback([...routine, item as DojoPurposeType])
        }
    }

    return (
        <Box
            className="bonsai"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
            }}
        >
            <Card
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'start',
                    justifyContent: 'center',
                    padding: '24px 32px',
                    margin: '16px',
                    gap: '16px',
                }}
            >
                <Box display={'flex'} flexDirection={'row'}>
                    <IconButton onClick={() => navigate(-1)}>
                        <ArrowBackIcon sx={{ fontSize: 40 }} color="primary" />
                    </IconButton>
                    <Typography variant="h1" marginLeft={'8px'}>
                        Routines Config
                    </Typography>
                </Box>

                <Stack direction="column" gap={1} marginLeft={'64px'}>
                    <Typography>Morning/Evening respectively</Typography>

                    {Object.keys(DojoPurposeType)
                        .filter((purpose) => purpose.startsWith('ROUTINE_'))
                        .map((item) => (
                            <FormControlLabel
                                key={item}
                                control={
                                    <>
                                        <Checkbox
                                            checked={morningRoutine.some((purpose) => purpose === item)}
                                            onChange={createClickHandler(morningRoutine, item, setMorningRoutine)}
                                            name={item}
                                        />
                                        <Checkbox
                                            checked={eveningRoutine.some((purpose) => purpose === item)}
                                            onChange={createClickHandler(eveningRoutine, item, setEveningRoutine)}
                                            name={item}
                                        />
                                    </>
                                }
                                label={item.slice(8).replaceAll('_', ' ').toLocaleLowerCase()}
                            />
                        ))}
                </Stack>
            </Card>
        </Box>
    )
}
