import { Box, CardMedia, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { NodeProps, useStore } from 'react-flow-renderer'
import { IslandData, LocationData } from '../../../../graphql/types'
import { mapData } from '../mapData'

const renderLocations = (zoom: number, locations: LocationData[], onLocationSelected: (id: string) => void) => {
    const svgSizes = [
        { minZoom: 0, width: 0, height: 0 },
        { minZoom: 1.1, width: 32, height: 52 },
        { minZoom: 2.5, width: 32, height: 52 },
        { minZoom: 5, width: 22, height: 42 },
    ]
    const { width, height } = svgSizes.find(({ minZoom }) => minZoom > zoom) || svgSizes[svgSizes.length - 1]
    const zoomLevels = [
        { min: 1, className: 'xl' },
        { min: 1.1, className: 'l' },
        { min: 2.5, className: 'm' },
        { min: 5, className: 's' },
    ]
    const labelStyles = 'label ' + zoomLevels.find((zl) => zl.min > zoom)?.className
    if (!locations) return null
    return locations.map((l) => {
        const hidden = zoom < 0.5
        return (
            <Box
                className={'location'}
                style={{
                    left: l.x,
                    top: l.y,
                    position: 'absolute',
                    opacity: hidden ? 0 : 1,
                    width: 2000,
                }}
                key={l.id}
                onClick={() => onLocationSelected(l.id)}
            >
                <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    x="0px"
                    y="0px"
                    width={`${width}px`}
                    height={`${height}px`}
                    viewBox="10 4 62 76"
                    style={{ position: 'absolute', top: '-72px', left: '-26px' }}
                >
                    <g transform="scale(0.15)">
                        <path
                            d="M263.877,0C169.782,0,93.512,76.271,93.512,170.213c0,93.941,159.197,349.834,159.197,349.834
			c6.196,10.175,16.217,10.175,22.338,0c0,0,159.119-255.816,159.119-349.834C434.166,76.194,357.973,0,263.877,0z M263.877,264.537
			c-61.583,0-111.384-49.878-111.384-111.384c0-61.506,49.801-111.461,111.384-111.461c61.582,0,111.384,49.878,111.384,111.384
			S325.459,264.537,263.877,264.537z"
                        />
                        <ellipse cx="263.877" cy="153.153" rx="69.844" ry="69.844" className={'placeCircle'} />
                    </g>
                </svg>
                <Typography className={labelStyles}>{l.name}</Typography>
            </Box>
        )
    })
}

const Island = (props: NodeProps) => {
    const zoomLevels = [
        { min: 0.3, className: 'xl' },
        { min: 0.5, className: 'l' },
        { min: 0.9, className: 'm' },
        { min: 1.4, className: 's' },
    ]
    const zoom = useStore((state) => state.transform[2]) // https://reactflow.dev/docs/examples/interaction/contextual-zoom/

    const [nameHandling, setNameHandling] = useState('')

    useEffect(() => {
        if (zoom > 0.4) {
            setNameHandling('above')
        } else if (zoom < 0.4) {
            setNameHandling('inside')
        }
    }, [zoom])

    useEffect(() => {
        const Box = document.querySelector(`div[data-id="${props.id}"]`)
        Box?.classList.remove('nopan')
    })

    const data = props.data
    const locations = renderLocations(zoom, data.locations, props.data.onLocationSelected)
    const labelStyles = 'islandLabel ' + zoomLevels.find((zl) => zl.min > zoom)?.className

    return (
        <Box style={{ position: 'relative' }} className="island">
            <Typography
                className={labelStyles}
                sx={{
                    fontWeight: 'bold',
                    color: 'white',
                    position: 'absolute',
                    top: nameHandling === 'above' ? '-5%' : '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    transition: 'top 1s ease-out',
                    opacity: nameHandling === 'hide' ? 0 : 1,
                    visibility: nameHandling === 'hide' ? 'hidden' : 'visible',
                }}
            >
                {data.name}
            </Typography>
            {mapData.map((island: IslandData) => {
                if (island.id === props.id) {
                    return <CardMedia component="img" image={island.img} alt={island.name} key={island.id} />
                }
            })}
            {locations}
        </Box>
    )
}

export default Island
