import { action, Action, Thunk, thunk } from 'easy-peasy'
import { v4 } from 'uuid'
import { Habit, Record } from '../../graphql/types'
import { testHabits } from '../../testData/habits'
import { testRecords } from '../../testData/records'
import { loadHabits, loadRecords, saveHabits, saveRecords } from '../storage/tracking'

// ======================================================== Type definition
export type TrackingModel = {
    // Data
    habits: Habit[]
    records: Record[]

    // Initialization and resetting
    loadData: Thunk<TrackingModel>
    resetTestData: Action<TrackingModel>
    setHabits: Action<TrackingModel, Habit[]>
    setRecords: Action<TrackingModel, Record[]>

    // Habit actions
    addHabit: Action<TrackingModel, Habit>
    updateHabit: Action<TrackingModel, Habit>
    deleteHabit: Action<TrackingModel, Habit>

    // Record actions
    trackHabit: Action<TrackingModel, { habit: string; date: number; status: string }>
    deleteRecord: Action<TrackingModel, Record>
}

const trackingModel: TrackingModel = {
    // Data
    habits: [],
    records: [],

    // Initialization and resetting
    loadData: thunk(async (actions) => {
        actions.setHabits(loadHabits())
        actions.setRecords(loadRecords())
    }),
    resetTestData: action((state) => {
        state.habits = saveHabits(testHabits)
        state.records = saveRecords(testRecords)
    }),
    setHabits: action((state, payload) => {
        state.habits = payload
    }),
    setRecords: action((state, payload) => {
        state.records = payload
    }),

    // Habit actions
    addHabit: action((state, payload) => {
        state.habits.push(payload)
        saveHabits(state.habits)
    }),
    updateHabit: action((state, payload) => {
        const index = state.habits.findIndex((item) => item.id === payload.id)
        if (index !== -1) {
            const updatedHabits = [...state.habits]
            const existingHabit = updatedHabits[index]
            const updatedHabit = {
                ...existingHabit,
                ...payload,
            }
            updatedHabits.splice(index, 1, updatedHabit)
            saveHabits(updatedHabits)
            state.habits = updatedHabits
        }
    }),

    deleteHabit: action((state, payload) => {
        const index = state.habits.findIndex((item) => item.id === payload.id)
        if (index !== -1) {
            state.habits.splice(index, 1)
            saveHabits(state.habits)
        }
    }),

    // Record actions

    // Handles adding/updating records
    trackHabit: action((state, payload) => {
        const index = state.records.findIndex(
            (r) =>
                r.habit === payload.habit && new Date(r.date).toDateString() === new Date(payload.date).toDateString(),
        )
        // Create new id if new item
        const record: Record = {
            ...payload,
            id: index !== -1 ? state.records[index].id : v4(),
        }

        if (index !== -1) state.records.splice(index, 1, record)
        else state.records.push(record)
        saveRecords(state.records)
    }),
    deleteRecord: action((state, payload) => {
        const index = state.records.findIndex((item) => item.id === payload.id)
        if (index !== -1) {
            state.records.splice(index, 1)
            saveRecords(state.records)
        }
    }),
}

export default trackingModel
