export interface StatusProgressProps {
    target: number
    value: number
    size: number
}

export const StatusProgress = (props: StatusProgressProps) => {
    const { target: targetValue, value: tasksCompleted, size: circleSize } = props
    const percentage = (tasksCompleted / targetValue) * 100
    const circumference = circleSize * Math.PI
    const dasharray = `${circumference} ${circumference}`
    const dashoffset = circumference - (circumference * percentage) / 100
    const isComplete = percentage === 100

    if (tasksCompleted > targetValue) {
        return (
            <svg
                width={circleSize}
                height={circleSize}
                viewBox={`-2 0 ${circleSize + 4} ${circleSize + 2}`}
                className="circular-progress"
            >
                <circle
                    cx={circleSize / 2}
                    cy={circleSize / 2}
                    r={(circleSize - 2) / 2}
                    stroke="#e0e0e0"
                    strokeWidth="2"
                    fill={'green'}
                />
                <circle
                    cx={circleSize / 2}
                    cy={circleSize / 2}
                    r={(circleSize - 2) / 2}
                    stroke="green"
                    strokeWidth="4"
                    fill="none"
                    strokeDasharray={dasharray}
                    strokeDashoffset={0}
                    transform={`rotate(-90 ${circleSize / 2} ${circleSize / 2})`}
                />
                <path
                    d={`M${circleSize * 0.2},${circleSize * 0.45} L${circleSize * 0.4},${circleSize * 0.7} L${
                        circleSize * 0.8
                    },${circleSize * 0.3}`}
                    stroke="white"
                    strokeWidth="2"
                    fill="none"
                />
            </svg>
        )
    }

    return (
        <svg
            width={circleSize}
            height={circleSize}
            viewBox={`-2 0 ${circleSize + 4} ${circleSize + 2}`}
            className="circular-progress"
        >
            <circle
                cx={circleSize / 2}
                cy={circleSize / 2}
                r={(circleSize - 2) / 2}
                stroke="rgba(0, 0, 0, 0.26)"
                strokeWidth="2"
                fill={percentage === 100 ? 'green' : 'none'}
                style={{
                    transition: 'all 2s ease-in-out',
                    transitionDelay: '0.2s',
                }}
            />
            <circle
                cx={circleSize / 2}
                cy={circleSize / 2}
                r={(circleSize - 2) / 2}
                stroke="green"
                strokeWidth="4"
                fill="none"
                strokeDasharray={dasharray}
                strokeDashoffset={dashoffset}
                transform={`rotate(-90 ${circleSize / 2} ${circleSize / 2})`}
                style={{
                    transition: 'stroke-dashoffset 0.35s ease-in-out',
                    transitionDelay: '0.2s',
                }}
            />

            {isComplete && (
                <path
                    d={`M${circleSize * 0.2},${circleSize * 0.45} L${circleSize * 0.4},${circleSize * 0.7} L${
                        circleSize * 0.8
                    },${circleSize * 0.3}`}
                    stroke="white"
                    strokeWidth="2"
                    fill="none"
                    style={{
                        transition: 'all 2s ease-in-out',
                        transitionDelay: '0.2s',
                    }}
                />
            )}
        </svg>
    )
}
export default StatusProgress
