import CloseIcon from '@mui/icons-material/Close'
import { Box, IconButton } from '@mui/material'
import { useState } from 'react'
import useGameStateVarRepo from '../../../util/hooks/useVariableRepository'
import { BoardItem, Content, ContentBoard, ContentType, Modifier } from '../data/types'
import TextContentOverlay from '../textContentPage/TextContentOverlay'
import VideoContentOverlay from '../videoContentPage/VideoContentOverlay'
import './ContentBoardPanel.css'
export interface ContentBoardPanelProps {
    board: ContentBoard
    contents: Content[]
    displayMode: boolean
    backClicked?: () => void
}

interface ActiveOverlay {
    type: ContentType
    contentID: string
    boardID: string
}

const ContentBoardPanel = (props: ContentBoardPanelProps): JSX.Element => {
    const varRepo = useGameStateVarRepo()
    const { board, contents, displayMode, backClicked } = props
    const [activeOverlay, setActiveOverlay] = useState<ActiveOverlay | undefined>(undefined)

    return (
        <Box className={'scene board'}>
            {board.items &&
                board.items.map((item: BoardItem) => {
                    if (item.mods && varRepo.getBoardItemModifier(item) === Modifier.HIDDEN) return null

                    const content = contents.find((c: Content) => {
                        return c.id === item.contentId
                    })
                    if (content) {
                        const isFinished = varRepo.isContentFinished(content)
                        return (
                            <Box
                                className={`boardItem core${isFinished ? ' finished' : ''}`}
                                key={content.id}
                                style={{ left: item.x, top: item.y }}
                                onClick={() =>
                                    setActiveOverlay({
                                        type: content.type,
                                        contentID: content.id,
                                        boardID: board.id,
                                    })
                                }
                            >
                                {content.type === ContentType.VIDEO && (
                                    <img src={content.videoContent?.thumbnailUrl ?? ''} alt={'thumbnail'} />
                                )}
                                <Box className="boarditem content">
                                    <Box className="title">{content.title}</Box>
                                    {item.description && (
                                        <Box className="boarditem description">{item.description}</Box>
                                    )}
                                </Box>
                            </Box>
                        )
                    } else {
                        return null
                    }
                })}
            {activeOverlay !== undefined && (
                <Box className="overlay">
                    <header>
                        <IconButton color="inherit" onClick={() => setActiveOverlay(undefined)}>
                            <CloseIcon htmlColor={'#fff'} fontSize="large" />
                        </IconButton>
                    </header>
                    {activeOverlay.type === ContentType.VIDEO && (
                        <VideoContentOverlay
                            contentID={activeOverlay.contentID}
                            contentBoardID={activeOverlay.boardID}
                        />
                    )}
                    {activeOverlay.type === ContentType.TEXT && (
                        <TextContentOverlay
                            contentID={activeOverlay.contentID}
                            contentBoardID={activeOverlay.boardID}
                        />
                    )}
                </Box>
            )}
            {!displayMode && activeOverlay === undefined && (
                <Box
                    className="backlink"
                    onClick={() => {
                        if (backClicked) backClicked()
                    }}
                >
                    {'< back'}
                </Box>
            )}
        </Box>
    )
}

export default ContentBoardPanel
