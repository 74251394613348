import { useNavigate, useParams } from 'react-router-dom'
import { compareHabitActiveDays } from '../../../../functions/habitFunctions'
import { Habit, HabitType, Record } from '../../../../graphql/types'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import { dateToDay, dayToDate } from '../../../../util/functions/dayConversion'
import { default as Routes } from '../../../../util/routes'
import { Status } from '../../../../util/types'
import { HabitComponentProps } from '../trackingView/TrackingView'

type DetailsViewParams = {
    habitID: string | undefined
    currentDay: string | undefined
}

export interface UseDetailsViewReturn extends HabitComponentProps {
    onClose: () => void
    records: Record[]
    status: Status
    currentDay: number
}

export const useDetailsView = (): UseDetailsViewReturn => {
    const { habitID, currentDay: currentParamDay } = useParams<DetailsViewParams>()

    const navigate = useNavigate()

    // Store state
    const records = useStoreState((state) => state.trackingModel.records)
    const habit = useStoreState((state) => state.trackingModel.habits).find((h) => h.id === habitID)
    const currentStoreDay = useStoreState((state) => state.timeModel.currentDay)
    // Store actions
    const trackHabit = useStoreActions((state) => state.trackingModel.trackHabit)

    const today = dateToDay(new Date())
    const currentDay = parseInt(currentParamDay ?? '') || currentStoreDay

    if (!habit)
        return {
            habit: {
                id: '',
                name: '',
                description: '',
                type: HabitType.BOOLEAN,
                owner: '',
                obstacles: [],
            },
            record: undefined,
            records,
            active: false,
            onTrackHabit: () => null,
            onClose: () => null,
            status: Status.ERROR,
            currentDay,
        }

    const active = today - currentDay < 3 && compareHabitActiveDays(currentDay, habit as Habit)

    if (parseInt(currentParamDay ?? '') !== currentDay)
        window.history.replaceState(
            '',
            '',
            Routes.tracking.DETAILS.replace(':habitID', habit.id).replace(':currentDay?', currentDay.toString()),
        )

    const record = records
        .filter(
            (record) =>
                record.habit === habit?.id &&
                new Date(record.date).toDateString() === dayToDate(currentDay).toDateString(),
        )
        .at(-1)
    const onClose = () => {
        navigate(-1)
    }
    const onTrackHabit = (habit: Habit, status: string) => {
        trackHabit({ habit: habit.id, date: dayToDate(currentDay).getTime(), status })
    }

    return {
        habit,
        record,
        records,
        onClose,
        active,
        onTrackHabit,
        status: Status.OK,
        currentDay,
    }
}
