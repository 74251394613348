import { useNavigate, useParams } from 'react-router-dom'
import { compareHabitActiveDays } from '../../../../functions/habitFunctions'
import { Habit, HabitInput, Record } from '../../../../graphql/types'
import { useStoreActions, useStoreState } from '../../../../store/hooks'
import { dateToDay, dayToDate } from '../../../../util/functions/dayConversion'
import Routes from '../../../../util/routes'
import { StackItemConfig, defaultStackConfig } from '../../components/stackItem/StackItem'
import { HabitComponentProps } from './TrackingView'

export interface useDetailsViewReturn {
    habits: Habit[]
    currentDay: number
    setCurrentDay: (currentDay: number) => void
    records: Record[]
    active: boolean
    today: number
    onTrackHabit: (habit: Habit, status: string) => void
    onInfoClick: (habit: Habit) => void
    onNavigate: (currentDay: number) => void
}

type TrackingViewParams = {
    currentDay: string | undefined
}

export const useTrackingView = (): useDetailsViewReturn => {
    const { currentDay: currentParamDay } = useParams<TrackingViewParams>()
    const navigate = useNavigate()

    // Store state
    const habits = useStoreState((state) => state.trackingModel.habits)
    const records = useStoreState((state) => state.trackingModel.records)
    const currentStoreDay = useStoreState((state) => state.timeModel.currentDay)
    // Store actions
    const setCurrentDay = useStoreActions((state) => state.timeModel.setCurrentDay)
    const trackHabit = useStoreActions((state) => state.trackingModel.trackHabit)

    const currentDay = parseInt(currentParamDay ?? '') || currentStoreDay
    const today = dateToDay(new Date())
    const active = today - currentDay < 3

    if (parseInt(currentParamDay ?? '') !== currentDay)
        window.history.replaceState('', '', Routes.tracking.TRACKING.replace(':currentDay?', currentDay.toString()))

    const onTrackHabit = (habit: Habit, status: string) =>
        trackHabit({ habit: habit.id, date: dayToDate(currentDay).getTime(), status })

    const onNavigate = (currentDay: number) => {
        setCurrentDay(currentDay)
        navigate(Routes.tracking.TRACKING.replace(':currentDay', currentDay.toString()))
    }

    const onInfoClick = (habit: Habit) => {
        navigate(`/details/${habit.id + '/' + currentDay}`)
    }

    return {
        habits,
        currentDay,
        setCurrentDay,
        records,
        active,
        today,
        onTrackHabit,
        onInfoClick,
        onNavigate,
    }
}
