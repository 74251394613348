import { Box, SxProps, Theme } from '@mui/material'
import { dateToDay } from '../../../../util/functions/dayConversion'
import { RecordTableItem } from './useHabitsOverview'

type OverviewItemProps = {
    record: RecordTableItem
    color?: string
    active: boolean
    onClick: () => void
    currentDay: number
    itemDate: Date
}

export const OverviewItem = (props: OverviewItemProps) => {
    const { record, currentDay, itemDate, onClick, color, active } = props
    const { status, target, streak } = record
    const { actualStreak, leftStreak, rightStreak } = streak

    const percentage = Math.min((status / target) * 100, 100)

    // https://stackoverflow.com/questions/69500357/how-to-implement-conditional-styles-in-mui-v5-sx-prop
    const styles: SxProps<Theme> = {
        width: actualStreak ? '20px' : '25px',
        height: actualStreak ? '20px' : '25px',
        borderRadius: '50%',
        border: '1px solid darkgray',
        backgroundColor: percentage >= 50 ? color || 'darkseagreen' : 'white',
        background: `conic-gradient(${color || 'darkseagreen'} ${percentage}%, white 0%)`,
        position: 'relative',

        ...(leftStreak && {
            '&::before': {
                content: '""',
                position: 'absolute',
                top: '50%',
                left: '-15px',
                width: '30px',
                height: '10px',
                backgroundColor: color || 'darkseagreen',
                transform: 'translateY(-50%)',
            },
        }),
        ...(rightStreak && {
            '&::after': {
                content: '""',
                position: 'absolute',
                top: '50%',
                right: '-15px',
                width: '30px',
                height: '10px',
                backgroundColor: color || 'darkseagreen',
                transform: 'translateY(-50%)',
            },
        }),
    }

    return (
        <Box
            sx={{
                display: 'flex',
                border: '1px solid lightgray',
                boxSizing: 'border-box',
                width: 40,
                height: 40,
                justifyContent: 'center',
                alignItems: 'center',
                flexShrink: 0,
                backgroundColor:
                    dateToDay(itemDate) === currentDay
                        ? 'khaki'
                        : itemDate.getDay() === 0 || itemDate.getDay() === 6
                        ? '#f2f2f2'
                        : 'white',
            }}
            onClick={active ? onClick : undefined}
        >
            {active && <Box sx={styles}></Box>}
        </Box>
    )
}
